import QRCode from "react-qr-code";
import "../MobileApp.scss";
import { useEffect, useState } from "react";

export const MobileQrcode = () => {
  const [navigatorType, setNavigatorType] = useState("none");

  const emulator = { height: "100vh" };
  const phone = { height: "-webkit-fill-available" };

  useEffect(() => {
    setNavigatorType(window?.navigator?.platform);
  }, [])
  return (
    <>
      <div className="wallet-background" style={navigatorType === "MacIntel" ? emulator : phone}>
        <div className="wallet-title-city" style={{margin: "50px", fontWeight: "bold"}}>
          Scan this QR code to access your offer
        </div>
      
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
            marginBottom: "30vh",
          }}
        >
          <QRCode value={"https://www.bearingpoint.com/en/"} size={200} />
        </div>
      </div>
    </>
  );
};

export default MobileQrcode;
