// unused 5use in mobile app°

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import TextField from "@mui/material/TextField";

import "./Claim.css";
import { useTranslation } from "react-i18next";
import { backend, GlobalStateContext } from "../../App";

interface ClaimProps {
  popSnack: any;
  vaultId: string;
}

export const Claim = ({ popSnack, vaultId }: ClaimProps) => {
  const uri = window.location.href;
  const collection = uri.split("/")[4];
  const nft = uri.split("/")[5];
  const [img, setImg] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [name, setName] = useState("");
  const [acc, setAcc] = useState("");
  const [isForm, setForm] = useState(false);
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation("claim");
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;

  const getNft = () => {
    axios.get(`${backend}/${collection}/${nft}/images`).then((res) => {
      console.log(res.data.image);
      setName(res.data.name);
      const imageUrl = axios
        .get(
          `${res.data.image}?pinataGatewayToken=aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              //'x-pinata-gateway-token' : 'aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191'
            },
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          //console.log(res)
          const binaryString = Array.from(new Uint8Array(res.data), (v) =>
            String.fromCharCode(v)
          ).join("");
          const theImage = btoa(binaryString);
          // console.log(theImage)
          setImg(theImage);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoaded(true));
    });
  };

  const connectToMetamask = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);
    setAcc(accounts[0]);
  };

  const sendNft = () => {
    if (fName == "" || lName == "" || email == "") {
      popSnack(t("fillInfos"), "warning");
    } else {
      axios
        .post(
          `${backend}/client/send`,
          {
            wallet: acc,
            name: fName + " " + lName,
            email: email,
            contractAddr: collection,
            tokenId: nft,
            vaultId: vaultId,
          },
          { headers: { vault: globalVar } }
        )
        .then((resp) => {
          console.log(resp);
          popSnack(t("nftSent"), "success");
        })
        .catch((err) => {
          popSnack(t("errorMsg"), "error");
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (!loaded) {
      getNft();
    }
  });

  return (
    <div className="claim-background">
      <h1 style={{ color: "white", marginTop: "5vh" }}>Claim your NFT</h1>
      {!isForm ? (
        <div className="claim-nft">
          {loaded ? (
            <img
              src={`data:image/png;base64,${img}`}
              style={{ maxWidth: "450px", width: "72vw", marginTop: "5vw" }}
            />
          ) : (
            <></>
          )}
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              minWidth: "72vw",
              maxWidth: "450px",
              marginTop: "10px",
            }}
          >
            {name}
          </span>
        </div>
      ) : (
        <div className="mobile-send-form-background">
          <TextField
            className="mobile-send-txtfield"
            autoComplete="off"
            variant="outlined"
            label="First name"
            style={{ minWidth: "80vw", marginTop: "3vh" }}
            value={fName}
            onChange={(e) => setfName(e.target.value)}
          />
          <TextField
            className="mobile-send-txtfield"
            autoComplete="off"
            variant="outlined"
            label="Last name"
            style={{ minWidth: "80vw", marginTop: "3vh" }}
            value={lName}
            onChange={(e) => setlName(e.target.value)}
          />
          <TextField
            className="mobile-send-txtfield"
            autoComplete="off"
            variant="outlined"
            label="Email address"
            style={{ minWidth: "80vw", marginTop: "3vh" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      )}
      {acc == "" ? (
        <button
          style={{
            color: "white",
            backgroundColor: "#EF19D1",
            width: "80vw",
            maxWidth: "500px",
            borderRadius: "20px",
            border: "none",
            minHeight: "40px",
            fontWeight: "bold",
            marginBottom: "15vh",
          }}
          onClick={() => {
            connectToMetamask();
          }}
        >
          {t("connect")}
        </button>
      ) : isForm ? (
        <button
          style={{
            color: "white",
            backgroundColor: "#EF19D1",
            width: "80vw",
            maxWidth: "500px",
            borderRadius: "20px",
            border: "none",
            minHeight: "40px",
            fontWeight: "bold",
            marginBottom: "15vh",
          }}
          onClick={() => {
            sendNft();
          }}
        >
          {t("getNFT")}
        </button>
      ) : (
        <button
          style={{
            color: "white",
            backgroundColor: "#EF19D1",
            width: "80vw",
            maxWidth: "500px",
            borderRadius: "20px",
            border: "none",
            minHeight: "40px",
            fontWeight: "bold",
            marginBottom: "15vh",
          }}
          onClick={() => {
            setForm(true);
          }}
        >
          {t("getNFT")}
        </button>
      )}
    </div>
  );
};

const FormSend = () => {
  return (
    <div className="mobile-send-form-background">
      <TextField
        className="mobile-send-txtfield"
        autoComplete="off"
        variant="outlined"
        label="First name"
        style={{ minWidth: "80vw", marginTop: "3vh" }}
      />
      <TextField
        className="mobile-send-txtfield"
        autoComplete="off"
        variant="outlined"
        label="Last name"
        style={{ minWidth: "80vw", marginTop: "3vh" }}
      />
      <TextField
        className="mobile-send-txtfield"
        autoComplete="off"
        variant="outlined"
        label="Email address"
        style={{ minWidth: "80vw", marginTop: "3vh" }}
      />
      <TextField
        className="mobile-send-txtfield"
        autoComplete="off"
        variant="outlined"
        label="Wallet address"
        style={{ minWidth: "80vw", marginTop: "3vh" }}
      />
    </div>
  );
};

export default Claim;
