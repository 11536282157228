import { useContext, useState } from "react";
import Card from "../CollectionCard/Card";
import axios from 'axios'
import { useNavigate } from "react-router-dom"
import { backend, GlobalStateContext } from "../../App";
import useFullPageLoader from "../../hooks/UseLoader";
import { useTranslation } from "react-i18next";

interface SliderProps {
    popSnack: any
    setPage: any
    nfts: any[]
    poas: any[]
    selectedOffer: any
    jwt: string
}

export const Slider = ({ popSnack, setPage, nfts, poas, selectedOffer, jwt }: SliderProps) => {
    const [isLoading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const {t} = useTranslation("offer");
    const nav = useNavigate()
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error("MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider");
    }
    const { globalVar, setGlobalVar } = context;

    const numberOfPages = Math.ceil((nfts.length + poas.length) / 3)
    let circles = []

    const addCollection = async (code: string, addr: string) => {
        if (typeof showLoader === 'function') {
            showLoader()
        }
        axios.post(`${backend}/promo/addrtopromo`, { code: code, addr: addr }, {headers: {"x-api-key": process.env.REACT_APP_API, "authorization": jwt, "vault": globalVar}}).then((resp) => {
            if (typeof hideLoader === 'function') {
                hideLoader()
            }
            setPage(1)
            nav("/community")
            popSnack(t('offerAdded'), 'success')
        }).catch((err) => {
            console.log(err)
            popSnack(t('errorAdding'), 'error')
            if (typeof hideLoader === 'function') {
                hideLoader()
            }
        })
    }

    if ((nfts.length + poas.length) > 0 && isLoading) {
        setLoading(false)
    }

    for (let index = 0; index < numberOfPages; index++) {
        if (index + 1 === currentPage) {
            circles.push(<Circle fill={false} />)
        }
        else {
            circles.push(<Circle fill />)
        }
    }

    let collections: [boolean, any][] = [];
    for (let index = 0; index < nfts.length; index++) {
        collections.push([false, nfts[index]]);
    }
    for (let index = 0; index < poas.length; index++) {
        collections.push([true, poas[index]]);
    }

    return (
        <>
            <div className="slider-background">
                {isLoading ? <>{t('loading')}</> : <>
                    <div className="slider-head">
                        <button className="slider-buttons" style={{ marginRight: '1vw' }} onClick={() => { if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1) }}>
                            {'<'}
                        </button>
                        <button className="slider-buttons" onClick={() => { if (currentPage + 1 <= numberOfPages) setCurrentPage(currentPage + 1) }}>
                            {'>'}
                        </button>
                    </div>
                    <div className="slider-middle">
                        {collections.map((elm, idx) => {
                            if (Math.ceil((idx + 1) / 3) === currentPage) return <button style={{ background: "none", border: "none" }} onClick={() => {
                                addCollection(selectedOffer.code, elm[1].address)
                            }}>
                                {!elm[0] ? <Card key={idx} collection={elm[1]} idx={idx} isPoa={false} />
                                    : <Card key={idx} collection={elm[1]} idx={idx} isPoa={true} />}
                            </button>
                        })}
                    </div>
                    <div className="slider-foot">
                        {circles}
                    </div>
                </>}
            </div>
            {loader}
        </>
    )
}

interface CircleProps {
    fill: boolean
}

const Circle = ({ fill }: CircleProps) => {
    const {t} = useTranslation("offer");
    return <div className={fill ? t('filled') : t('notFilled')} />
}

export default Slider