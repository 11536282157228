import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import './CollectionDetails.css'
import { useTranslation } from "react-i18next"

interface DetailsProps {
  nft: any;
  setDetails: any;
  idx: number;
}

export const Card = ({ nft, setDetails, idx }: DetailsProps) => {
  const addr = window.location.href.split("/")[4];
  const num = nft.name.split("#")[1];
  return (
    <div
      className="details-card"
      style={{ marginRight: "5%" }}
      onClick={() => {
        setDetails(idx);
      }}
    >
      <ImgNft image={nft.image} />
    </div>
  );
};

interface ImageProps {
  image: string;
}

export const ImgNft = ({image}: ImageProps) => {
    const [img, setImg] = useState("")
    const [update, setUpdate] = useState(false)
    const {t} = useTranslation("collectionDetails");
    
    useEffect(() => {
        if (img === "") {
            const imageUrl = axios.get(`${image}?pinataGatewayToken=aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191`,{
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            //'x-pinata-gateway-token' : 'aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191'
            }, responseType: "arraybuffer"
            }).then((res) => {
                //console.log(res)
                const binaryString = Array.from(new Uint8Array(res.data), v => String.fromCharCode(v)).join("");
                const theImage = btoa(binaryString);
            // console.log(theImage)
                setImg(theImage)
            }).catch((err) => {
                //console.log(err)
            }).finally(() => setUpdate(!update))            
        }
    })

    return <>
        { img === "" ? <>{t('loading')}</> : <img src={`data:image/png;base64,${img}`} style={{maxWidth: '8vw', minWidth: '8vw',maxHeight: '8vw', minHeight: '8vw', borderRadius: '10px'}}/>}
    </>
};

export default Card;
