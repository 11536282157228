import { useTranslation } from "react-i18next";
import eth from '../../assets/ethImage.png'

import './Dashboard.css'

interface DashProps {
    nfts: any
    poap: any
    promos: any
    supplyVP: any
    supplyPoaps: any
    nbOwners: any
}

export const Dashboard = ({nfts, poap, promos, supplyPoaps, supplyVP, nbOwners}: DashProps) => {
    const nbCollec = nfts.length + poap.length
    const nbPromos = promos.length
    const distributed = supplyVP + supplyPoaps
    const {t} = useTranslation("dashboard");
    
    return <div className="home-background">
        <div className='stats'>

            <Stat num={nbCollec} txt={t('deployed')} />
            <div style={{ minHeight: '10vh', borderLeft: "0.8px solid #e2e2e2", marginLeft: "3vw", marginRight: "3vw" }}></div>
            <Stat num={distributed} txt={t('distributed')} />
            <div style={{ minHeight: '10vh', borderLeft: "0.8px solid #e2e2e2", marginLeft: "3vw", marginRight: "3vw" }}></div>
            <Stat num={nbPromos} txt={t('offers')} />
            <div style={{ minHeight: '10vh', borderLeft: "0.8px solid #e2e2e2", marginLeft: "3vw", marginRight: "3vw" }}></div>
            <Stat num={nbOwners} txt={t('owners')} />
            <div style={{ marginRight: "3vw" }} />

        </div>
        <div className="home-middle">
            <div className="home-middle-left">
                <img src={eth} className='img-eth' />
            </div>
            <div className="home-middle-right">
                <h1 className="title-home">
                    {t('createNFT')}
                </h1>
                <span style={{ color: '#E2E2E2', maxWidth: "30vw" }}>
                    Lorem ipsum dolor sit amet. Qui excepturi quia qui maxime totam aut pariatur delectus non molestiae exercitationem qui laboriosam iusto eos quibusdam quisquam et maxime laudantium.
                </span>
            </div>
        </div>
        <div className="home-footer">
            <Info title={t('createCollection')} description="Lorem ipsum dolor sit amet. Qui excepturi quia qui maxime " />
            <Info title={t('createCollection')} description="Lorem ipsum dolor sit amet. Qui excepturi quia qui maxime " />
        </div>
    </div>
}

interface InfoProps {
    title: string,
    description: string
}

export const Info = ({ title, description }: InfoProps) => {
    return <div className="info-div">
        <h2 style={{ color: '#078BF9' }}>
            {title}
        </h2>
        <span style={{ color: '#E2E2E2' }}>
            {description}
        </span>
    </div>
}

interface StatsProps {
    num: number
    txt: string
}

export const Stat = ({ num, txt }: StatsProps) => {
    return <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column' }}>
        <span style={{ fontSize: '4vw', color: '#EF19D1' }}>
            {num}
        </span>
        <span style={{ fontSize: '1.5vw', color: 'white', textAlign: 'center' }}>
            {txt}
        </span>
    </div>
}

export default Dashboard