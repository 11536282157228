import React, { useContext, useEffect, useState } from "react";

import "./Role.css";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@mui/material";
import axios from "axios";
import { backend, GlobalStateContext } from "src/App";
import { useTranslation } from "react-i18next";

interface PermissionsProps {
  jwt: string;
}

export const Permissions = ({ jwt }: PermissionsProps) => {
  const [isPopup, setPopup] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [permissionList, setPermList] = useState<
    { code: string; description: string }[]
  >([]);
  const [isEdit, setEdit] = useState(false);
  const context = useContext(GlobalStateContext);
  const { t } = useTranslation("administration");

  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;

  const addPermission = () => {
    axios
      .post(
        isEdit
          ? `${backend}/admin/permission/update`
          : `${backend}/admin/permission`,
        { code: name, description: description },
        { headers: { authorization: jwt, vault: globalVar } }
      )
      .then((resp) => {
        setPermList([]);
        setPopup(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPermissions = () => {
    axios
      .get(`${backend}/admin/permissions`, {
        headers: { authorization: jwt, vault: globalVar },
      })
      .then((resp) => {
        setPermList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (permissionList.length == 0) {
      getPermissions();
    }
  });

  return (
    <div className="role-background">
      {isPopup ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: "80vw",
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "white",
              opacity: "0.3",
              zIndex: 1,
            }}
          ></div>
          <div style={{ zIndex: 2 }}>
            <PopupRole
              edit={isEdit}
              setEdit={setEdit}
              addRole={addPermission}
              permissionList={permissionList}
              setPopup={setPopup}
              name={name}
              setName={setName}
              description={description}
              setDescription={setDescription}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="role-table">
        <div className="role-table-header" style={{ marginBottom: "3vh" }}>
          <span
            style={{
              minWidth: "17vw",
              textAlign: "start",
              marginLeft: "2.5vw",
            }}
          >
            {t("Permissions")}
          </span>
          <span style={{ minWidth: "22vw", textAlign: "start" }}>
            {t("Description")}
          </span>
          <div
            onClick={() => {
              setPopup(true);
            }}
            style={{
              cursor: "pointer",
              marginLeft: "35%",
              width: "1.5vw",
              height: "1.5vw",
              textAlign: "end",
              borderRadius: "50%",
              border: "1px solid #EF19D1",
              color: "#EF19D1",
              display: "flex",
              justifyContent: "center",
            }}
          >
            +
          </div>
        </div>
        {permissionList && typeof permissionList.map == "function" ? (
          permissionList.map((elm, idx) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  color: "white",
                }}
              >
                <div className="role-table-line">
                  <span
                    style={{
                      minWidth: "17vw",
                      maxWidth: "17vw",
                      textAlign: "start",
                      overflow: "hidden",
                    }}
                  >
                    {elm.code}
                  </span>
                  <span
                    style={{
                      minWidth: "22vw",
                      maxWidth: "22vw",
                      textAlign: "start",
                      overflow: "hidden",
                    }}
                  >
                    {elm.description}
                  </span>
                </div>
                <button
                  style={{
                    cursor: "pointer",
                    background: "none",
                    border: "none",
                    color: "white",
                  }}
                  onClick={() => {
                    setName(elm.code);
                    setDescription(elm.description);
                    setPopup(true);
                    setEdit(true);
                  }}
                >
                  <EditIcon />
                </button>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

interface PopupProps {
  setPopup: any;
  description: string;
  setDescription: any;
  name: string;
  setName: any;
  permissionList: any[];
  addRole: any;
  setEdit: any;
  edit: boolean;
}

const PopupRole = ({
  edit,
  setEdit,
  addRole,
  setPopup,
  description,
  name,
  setDescription,
  setName,
  permissionList,
}: PopupProps) => {
  const { t } = useTranslation("administration");
  return (
    <div className="add-permission-popup">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          color: "white",
          height: "10vh",
        }}
      >
        <div style={{ flex: 1 }} />
        <div
          style={{
            flex: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
            {" "}
            {edit ? t("Edit") : t("CreatePerm")}{" "}
          </span>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CloseIcon
            fontSize="large"
            cursor="pointer"
            onClick={() => {
              setPopup(false);
              setEdit(false);
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          marginTop: "1vh",
          flexDirection: "column",
        }}
      >
        <span
          style={{ color: "white", fontWeight: "bold", marginBottom: "1.5vh" }}
        >
          {t("Code")}
        </span>
        <TextField
          disabled={edit}
          autoComplete="off"
          variant="outlined"
          label=""
          style={{
            minWidth: "25vw",
            border: "green",
            backgroundColor: "#1A223D",
          }}
          className="txtfield"
          value={name}
          onChange={(e: any) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          marginTop: "2vh",
          flexDirection: "column",
        }}
      >
        <span
          style={{ color: "white", fontWeight: "bold", marginBottom: "1.5vh" }}
        >
          {t("Description")}
        </span>
        <TextField
          autoComplete="off"
          variant="outlined"
          inputProps={{ style: { color: "white" } }}
          multiline
          rows={3}
          label=""
          style={{ minWidth: "90%", backgroundColor: "#1A223D" }}
          className="txtfield"
          value={description}
          onChange={(e: any) => {
            setDescription(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          marginTop: "2vh",
          flexDirection: "column",
        }}
      >
        <button
          onClick={() => {
            addRole();
          }}
          style={{
            cursor: "pointer",
            width: "100%",
            height: "5vh",
            backgroundColor: "#EF19D1",
            border: "none",
            borderRadius: "50px",
            marginTop: "4vh",
            color: "white",
            fontSize: "1em",
            fontWeight: "bold",
          }}
        >
          {t("Validate")}
        </button>
      </div>
    </div>
  );
};

export default Permissions;
