// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.broadcast-button {
  min-width: 40vw;
  font-size: 1.5vw;
  min-height: 6vh;
  border-radius: 7px;
  border: none;
  background: #EF19D1;
  cursor: pointer;
  color: #ffffff;
  font-weight: bold;
  border-radius: 50px;
}

.broadcast-button:hover {
  background: #a31991;
}`, "",{"version":3,"sources":["webpack://./src/components/Broadcast/Broadcast.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".broadcast-button {\n    min-width: 40vw;\n    font-size: 1.5vw;\n    min-height: 6vh;\n    border-radius: 7px;\n    border: none;\n    background: #EF19D1;\n    cursor: pointer;\n    color: #ffffff;\n    font-weight: bold;\n    border-radius: 50px;\n}\n\n.broadcast-button:hover {\n    background: #a31991;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
