import axios from "axios";
import React, { useEffect, useState } from "react";
import Im from "../../assets/ethImage.png";
import { backend } from "../../App";
import "./Card.css";

interface CardDisplayerProps {
  collection: any;
  idx: number;
  isPoa: boolean;
}

const CardDisplayer = ({ collection, idx, isPoa }: CardDisplayerProps) => {
  const [isLoaded, setLoaded] = useState(true);
  const [imgDisplay, setImg] = useState("");

  useEffect(() => {
    const imageUrl = axios
      .get(
        `${collection.imgs[0]}?pinataGatewayToken=aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            //'x-pinata-gateway-token' : 'aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191'
          },
          responseType: "arraybuffer",
        }
      )
      .then((res) => {
        //console.log(res)
        const binaryString = Array.from(new Uint8Array(res.data), (v) =>
          String.fromCharCode(v)
        ).join("");
        const theImage = btoa(binaryString);
        // console.log(theImage)
        setImg(theImage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoaded(true));
  });
  if (!isPoa) {
    return (
      <>
        {isLoaded ? (
          <Card
            nbElms={Number(collection.nbElms)}
            description={collection.desc}
            name={collection.name}
            image={imgDisplay}
            address={collection.address}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
  return (
    <>
      {isLoaded ? (
        <CardPoap
          eventName={collection.eventName}
          date={collection.date}
          description={collection.description}
          image={imgDisplay}
        />
      ) : (
        <> </>
      )}
    </>
  );
};

const mintNfts = (
  mintAmount: number,
  contractAddress: string,
  receiverAddress: string
) => {
  const uri = `${backend}/nft/mint`;
  axios
    .post(uri, {
      contractAddress: contractAddress,
      receiverAddress: receiverAddress,
      mintAmount: mintAmount,
    })
    .then((resp) => {
      console.log(resp);
    })
    .catch((err) => {
      console.log(err);
    });
};

interface CardProps {
  name: string;
  image: string;
  description: string;
  address: string;
  nbElms: number;
}

const Card = ({ name, description, image, address, nbElms }: CardProps) => {
  return (
    <div className="card-background">
      <img src={`data:image/png;base64,${image}`} className="card-image" />
      <div className="card-texts">
        <span style={{ fontWeight: "bold", color: "white" }}>{name}</span>

        <span style={{ marginTop: "0.5vh" }}>{description}</span>
      </div>
    </div>
  );
};

interface CardPoapProps {
  eventName: string;
  date: string;
  description: string;
  image: string;
}

const CardPoap = ({ eventName, date, description, image }: CardPoapProps) => {
  return (
    <div className="card-background">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1vh",
        }}
      >
        <img src={`data:image/png;base64,${image}`} className="card-image" />
      </div>
      <div className="card-texts">
        <span style={{ fontWeight: "bold", color: "white" }}>{eventName}</span>

        <span style={{ marginTop: "0.5vh" }}>{description}</span>
      </div>
    </div>
  );
};

export default CardDisplayer;
