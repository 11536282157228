import React, { useContext, useEffect, useState } from "react";

import "./Role.css";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import UploadIcon from "../../assets/UploadIcon.png";
import { Checkbox } from "@mui/material";
import axios from "axios";
import { backend, GlobalStateContext } from "src/App";
import useFullPageLoader from "src/hooks/UseLoader";
import { useTranslation } from "react-i18next";

interface WalletProps {
  jwt: string;
  allWallets: any;
  popSnack: any;
}

export const Wallet = ({ jwt, allWallets, popSnack }: WalletProps) => {
  const [isPopup, setPopup] = useState(false);
  const [name, setName] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [permissionList, setPermList] = useState<
    { name: string; address: string }[]
  >([]);
  const [isEdit, setEdit] = useState(false);
  const context = useContext(GlobalStateContext);
  const { t } = useTranslation("administration");
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;

  const [imgUploaded, setImgUploaded] = useState(false);

  const addWalletImg = async (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let data = new FormData();
      data.append("file", file, file.name);
      axios
        .post(`${backend}/files/uploadAdmImg/${allWallets.length + 1}`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data`,
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
          },
        })
        .then((resp) => {
          setImgUploaded(true);
          popSnack("Image successfully uploaded", "success");
        })
        .catch((err) => {
          console.log(err);
          popSnack("Error occured while uploading the image", "error");
        });
    }
  };

  const addWallet = () => {
    if (name == "") {
      popSnack("Please add a name to the wallet", "warning");
    } else if (!imgUploaded) {
      popSnack("Please add a png image first", "warning");
    } else {
      if (typeof showLoader === "function") {
        showLoader();
      }
      setPopup(false);
      axios
        .post(
          `${backend}/admin/wallet/create`,
          { name: name, id: "4", img: `${allWallets.length + 1}.png` },
          { headers: { authorization: jwt, vault: globalVar } }
        )
        .then((resp) => {
          setPermList([]);
          popSnack("Wallet successfully created", "success");
          if (typeof hideLoader === "function") {
            hideLoader();
          }
        })
        .catch((err) => {
          console.log(err);
          popSnack("Error occured while creating the wallet", "error");
          if (typeof hideLoader === "function") {
            hideLoader();
          }
        });
    }
  };

  const getPermissions = () => {
    axios
      .get(`${backend}/admin/wallets`, {
        headers: { authorization: jwt, vault: globalVar },
      })
      .then((resp) => {
        setPermList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (permissionList.length == 0) {
      getPermissions();
    }
  });

  return (
    <>
      <div className="role-background">
        {isPopup ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              width: "80vw",
              height: "100vh",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "white",
                opacity: "0.3",
                zIndex: 1,
              }}
            ></div>
            <div style={{ zIndex: 2 }}>
              <PopupRole
                addWallet={addWallet}
                setPopup={setPopup}
                name={name}
                setName={setName}
                imgUploaded={imgUploaded}
                addWalletImg={addWalletImg}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="role-table">
          <div className="role-table-header" style={{ marginBottom: "3vh" }}>
            <span
              style={{
                minWidth: "17vw",
                textAlign: "start",
                marginLeft: "2.5vw",
              }}
            >
              {t("Name")}
            </span>
            <span style={{ minWidth: "22vw", textAlign: "start" }}>
              {t("Wallet")}
            </span>
            <div
              onClick={() => {
                setPopup(true);
              }}
              style={{
                cursor: "pointer",
                marginLeft: "35%",
                width: "1.5vw",
                height: "1.5vw",
                textAlign: "end",
                borderRadius: "50%",
                border: "1px solid #EF19D1",
                color: "#EF19D1",
                display: "flex",
                justifyContent: "center",
              }}
            >
              +
            </div>
          </div>
          {permissionList && typeof permissionList.map == "function" ? (
            permissionList.map((elm, idx) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    color: "white",
                  }}
                >
                  <div className="role-table-line">
                    <span
                      style={{
                        minWidth: "17vw",
                        maxWidth: "17vw",
                        textAlign: "start",
                        overflow: "hidden",
                      }}
                    >
                      {elm.name}
                    </span>
                    <span
                      style={{
                        minWidth: "22vw",
                        maxWidth: "22vw",
                        textAlign: "start",
                        overflow: "hidden",
                      }}
                    >
                      {elm.address}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
      {loader}
    </>
  );
};

interface PopupProps {
  setPopup: any;
  name: string;
  setName: any;
  addWallet: any;
  imgUploaded: any;
  addWalletImg: any;
}

const PopupRole = ({
  setPopup,
  name,
  setName,
  addWallet,
  imgUploaded,
  addWalletImg,
}: PopupProps) => {
  const hiddenFileInput = React.useRef(null);
  const { t } = useTranslation("administration");

  const handleClick = () => {
    if (hiddenFileInput.current !== null)
      // @ts-ignore
      hiddenFileInput.current.click();
  };

  return (
    <>
      <input
        ref={hiddenFileInput}
        type="file"
        name="file"
        style={{ display: "none" }}
        onChange={(e) => addWalletImg(e)}
      />
      <div className="add-wallet-popup">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "90%",
            color: "white",
            height: "10vh",
          }}
        >
          <div />
          <span
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
            }}
          >
            {t("Create")}
          </span>
          <CloseIcon
            fontSize="large"
            cursor="pointer"
            onClick={() => {
              setPopup(false);
            }}
          />
        </div>
        <div
          style={{
            width: "90%",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            marginTop: "1vh",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              marginBottom: "1.5vh",
            }}
          >
            {t("Name")}
          </span>
          <TextField
            autoComplete="off"
            variant="outlined"
            label={t("Name")}
            style={{
              minWidth: "25vw",
              border: "green",
              backgroundColor: "#1A223D",
            }}
            className="txtfield"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
          />
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              marginBlock: "1.5vh",
            }}
          >
            {t("Image")}
          </span>
          <button className="up-wallet-img" onClick={handleClick}>
            {!imgUploaded ? (
              <img
                style={{
                  maxWidth: "80%",
                  maxHeight: "80%",
                  objectFit: "cover",
                  display: "block",
                }}
                src={UploadIcon}
                alt="UploadIcon"
              />
            ) : (
              <EditIcon />
            )}
          </button>
        </div>
        <div
          style={{
            width: "90%",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            marginTop: "1vh",
            flexDirection: "column",
          }}
        >
          <button
            onClick={() => {
              addWallet();
            }}
            style={{
              cursor: "pointer",
              width: "100%",
              height: "5vh",
              backgroundColor: "#EF19D1",
              border: "none",
              borderRadius: "50px",
              marginTop: "4vh",
              color: "white",
              fontSize: "1em",
              fontWeight: "bold",
            }}
          >
            {t("Create")}
          </button>
        </div>
      </div>
    </>
  );
};

export default Wallet;
