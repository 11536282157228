import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Popup from "../Popup/Popup";

import Slider from "../Slider/Slider";
import CollectionHeader from "../StepTitle/StepTitle";

import "./Collections.css";

interface CollectionProps {
  nfts: any[];
  poas: any[];
  jwt: string;
  currWallet: any;
}

export const Collections = ({
  nfts,
  poas,
  jwt,
  currWallet,
}: CollectionProps) => {
  const [openPopup, setPopup] = useState(false);
  const [isOpened, setOpened] = useState(false);
  const { t } = useTranslation("collections");

  const launchPopup = () => {
    setPopup(true);
  };

  useEffect(() => {
    if (!isOpened && currWallet.name == "") {
      launchPopup();
      setOpened(true);
    }
  });

  return (
    <div className="collections-background">
      <Popup
        open={openPopup}
        setOpen={setPopup}
        title={"Please select a wallet"}
        content={"Please select a wallet in your side bar to continue"}
      />
      {currWallet.name !== "" ? (
        <>
          {" "}
          <div style={{ minWidth: "70vw", marginTop: "10vh" }}>
            <CollectionHeader
              page={1}
              total={2}
              title={t("access")}
              description={t("select")}
            />
          </div>
          <Slider nfts={nfts} poas={poas} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Collections;
