// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choose-background {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80vw;
    min-width: 80vw;
    min-height: 100vh;
    max-height: 100vh;
    background-color: #04152D;
}

.slider-head-2 {
    display: flex;
    justify-content: space-between;
    align-items: end;
    min-width: 70vw;
    max-width: 70vw;
    min-height: 10vh;
    max-height: 10vh;
    margin-top: 10vh;
    flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/components/ChooseCollection/ChooseCollection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".choose-background {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    max-width: 80vw;\n    min-width: 80vw;\n    min-height: 100vh;\n    max-height: 100vh;\n    background-color: #04152D;\n}\n\n.slider-head-2 {\n    display: flex;\n    justify-content: space-between;\n    align-items: end;\n    min-width: 70vw;\n    max-width: 70vw;\n    min-height: 10vh;\n    max-height: 10vh;\n    margin-top: 10vh;\n    flex-direction: row;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
