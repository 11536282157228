import { TextField } from "@mui/material";
import React, { useState } from "react";
import useFullPageLoader from "src/hooks/UseLoader";
import axios from "axios";
import { backend } from "src/App";
import { useTranslation } from "react-i18next";

interface CreateUserProps {
  popSnack: any;
  wallet: string;
  actionConnect: any;
}

export const CreateUser = ({
  popSnack,
  wallet,
  actionConnect,
}: CreateUserProps) => {
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { t } = useTranslation("mobileConnection");

  const createUser = () => {
    if (fName == "" || lName == "" || email == "") {
      popSnack(t("fillInfos"), "warning");
    } else {
      if (typeof showLoader === "function") {
        showLoader();
      }
      axios
        .post(
          `${backend}/client/add`,
          { name: fName + " " + lName, email: email, wallet: wallet },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API,
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((resp) => {
          console.log("create user", resp.data);
          localStorage.setItem("privatekeyXmtp", resp.data.privatekey);
          if (resp.data.privatekeyXmtp != "") {
            popSnack(t("accountCreated"), "success");
            actionConnect();
          } else {
            popSnack("an error occured", "error");
          }
          if (typeof hideLoader === "function") {
            hideLoader();
          }
        })
        .catch((err) => {
          // TODO : can display those errors handler cause of multi request problem
          // if (typeof hideLoader === 'function') {
          //     hideLoader()
          // }
          // popSnack("An error occurred while checking creating your account", "error")
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="wallet-user">
        <div className="wallet-user-txt">{t("registerInfos")}</div>
        <div className="wallet-user-form">
          <div>
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("firstName")}
              style={{
                minWidth: "20vw",
                maxWidth: "40vw",
                marginTop: "3vh",
                marginRight: "2vw",
              }}
              value={fName}
              onChange={(e) => setfName(e.target.value)}
            />
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("lastName")}
              style={{
                minWidth: "20vw",
                maxWidth: "40vw",
                marginTop: "3vh",
                marginLeft: "2vw",
              }}
              value={lName}
              onChange={(e) => setlName(e.target.value)}
            />
          </div>
          <TextField
            className="send-txtfield"
            autoComplete="off"
            variant="outlined"
            label={t("emailAddress")}
            style={{ minWidth: "22vw", marginTop: "3vh", marginInline: "3vw" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className="wallet-user-btn"
            onClick={() => {
              createUser();
            }}
          >
            {t("createAccount")}
          </button>
        </div>
      </div>
      {loader}
    </>
  );
};

export default CreateUser;
