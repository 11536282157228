import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MLZ from "../../assets/MLZ4142.png";
import showroom from "../../assets/showroom.jpg";
import showroom2 from "../../assets/showroom2.jpg";
import showroomC from "../../assets/showroom_cartier.jpg";
import showroom3 from "../../assets/showroom4.jpg";
import arrow from "../../assets/arrow.png";
import Snack from "../Snackbar/Snackbar";
import axios from "axios";
import CollectionHeader from "../StepTitle/StepTitle";
import Next from "../CreateCollection/NextButton";
import { backend, GlobalStateContext } from "../../App";
import "./Expose.css";
import ShowroomRecap from "./ShowroomRecap";
import positionsImg from "../../assets/positions.png";
import { useTranslation } from "react-i18next";

interface ExpoProps {
  nftsArray: any;
  selectedShowroom: number;
  popSnack: any;
}

const showrooms = [showroom, showroom2, showroomC, showroom3];

const fetchCollectionImagesOwned = async (
  collection: any,
  setImages: any,
  setFetch: any,
  vault: string
) => {
  console.log(collection);

  const walletaddr =
    process.env.REACT_APP_WALLETADDR ||
    "0x014F58558f8da84E3E3476c738B5809B7C4a42ea";

  //axios.get(`${backend}/${collection.address}/${walletaddr}`).then((resp) => {
  axios
    .post(
      `${backend}/nft/collection/wallet`,
      { coladdr: collection.address, wallet: walletaddr },
      { headers: { "x-api-key": process.env.REACT_APP_API, vault: vault } }
    )
    .then((resp) => {
      setImages(resp.data);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setFetch(true);
    });
};

interface ChooseExpoProps {
  nftsArray: any;
  popSnack: any;
}

export const ChooseShowroom = ({ popSnack, nftsArray }: ChooseExpoProps) => {
  const [selectedRoom, setRoom] = useState(-1);
  const { t } = useTranslation("exposeShowroom");

  return (
    <>
      {selectedRoom == -1 ? (
        <div className="choose-showroom">
          <div style={{ minWidth: "70vw" }}>
            <CollectionHeader
              page={2}
              title={t("expose")}
              description={t("select")}
              total={4}
            />
          </div>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={3}
            alignItems="center"
            justifyContent="center"
          >
            {showrooms.map((elm, idx) => {
              return (
                <Grid item>
                  <img
                    src={elm}
                    key={`Showroom #${idx}`}
                    onClick={() => {
                      setRoom(idx);
                    }}
                    style={{
                      maxWidth: "27vw",
                      minWidth: "27vw",
                      maxHeight: "25vh",
                      minHeight: "25vh",
                      cursor: "pointer",
                      borderRadius: "10px",
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        <Exposition
          popSnack={popSnack}
          selectedShowroom={selectedRoom}
          nftsArray={nftsArray}
        />
      )}
    </>
  );
};

export const Exposition = ({
  popSnack,
  nftsArray,
  selectedShowroom,
}: ExpoProps) => {
  const [nftSelected, setNft] = useState(-1);
  const [positions, setPositions] = useState([
    { pos: 1, nft: -1, name: "", model: "" },
    { pos: 2, nft: -1, name: "", model: "" },
    { pos: 3, nft: -1, name: "", model: "" },
    { pos: 4, nft: -1, name: "", model: "" },
    { pos: 5, nft: -1, name: "", model: "" },
    { pos: 6, nft: -1, name: "", model: "" },
  ]);
  const [openSnack, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [nfts, setNfts] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [fetch, setFetch] = useState(false);
  const [called, setCalled] = useState(false);
  const { t } = useTranslation("exposeShowroom");
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;

  const getNftByNumber = (idNft: number) => {
    let resp = {
      ar: "",
      description: "",
      name: "",
      image: "",
      model: "",
      promotion: [],
    };
    nfts.map((elm, idx) => {
      const id = Number(elm.name.split("#")[1]);
      if (id == idNft) {
        resp = elm;
      }
    });
    return resp;
  };

  useEffect(() => {
    if (!called) {
      setCalled(true);
      fetchCollectionImagesOwned(nftsArray, setNfts, setFetch, globalVar);
    }
  });
  console.log(nfts);
  const onSelect = (pos: number) => {
    if (nftSelected == -1) {
      setOpen(true);
    }
    positions.map((elm, idx) => {
      if (elm.nft == nftSelected) {
        let cpy = positions;
        cpy[idx].nft = -1;
        const index = positions.findIndex((obj) => obj.pos === pos);
        cpy[index].nft = nftSelected;
        setPositions(cpy);
        setUpdate(!update);
        return;
      }
    });
    const index = positions.findIndex((obj) => obj.pos === pos);
    let cpy = positions;
    cpy[index].nft = nftSelected;
    const nftObj = getNftByNumber(nftSelected);
    if (nftObj !== undefined) {
      cpy[index].name = nftObj.name;
      cpy[index].model = nftObj.model;
    }
    setPositions(cpy);
    setUpdate(!update);
  };

  // const nfts = [{address: '', name: 'Test1', blockchain: "ethereum", desc: 'description', symbole: 'TST', nbElm: 3, price: 0, imgs: [MLZ], cover: ''},
  //      {address: '', name: 'Test1', blockchain: "ethereum", desc: 'description', symbole: 'TST', nbElm: 3, price: 0, imgs: [MLZ], cover: ''},
  //      {address: '', name: 'Test1', blockchain: "ethereum", desc: 'description', symbole: 'TST', nbElm: 3, price: 0, imgs: [MLZ], cover: ''},
  //      {address: '', name: 'Test1', blockchain: "ethereum", desc: 'description', symbole: 'TST', nbElm: 3, price: 0, imgs: [MLZ], cover: ''}]

  return (
    <div className="expo-background">
      <Snack
        open={openSnack}
        setOpen={setOpen}
        message={t("needSelectNft")}
        status="warning"
      />
      <div style={{ minWidth: "70vw" }}>
        <CollectionHeader
          page={page + 3}
          title={t("expose")}
          description={page == 0 ? t("position") : t("confirm")}
          total={4}
        />
      </div>
      {page == 0 ? (
        <div className="expo-mid">
          <div className="expo-nfts">
            <div className="expo-nfts-grid">
              {fetch !== undefined ? (
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={3}
                  alignItems="end"
                >
                  {nfts.map((elm, idx) => {
                    const id = Number(elm.name.split("#")[1]);

                    return (
                      <Grid item key={id}>
                        <button
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (id == nftSelected) setNft(-1);
                            else setNft(id);
                          }}
                        >
                          {positions.map((elm) => {
                            if (elm.nft == id) {
                              return <div className="position">{elm.pos}</div>;
                            }
                          })}
                          <ImgNft
                            image={elm.image}
                            idx={id}
                            nftSelected={nftSelected}
                          />
                        </button>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="expo-showroom">
            <img
              src={
                selectedShowroom == 0
                  ? positionsImg
                  : showrooms[selectedShowroom]
              }
              style={{ maxWidth: "40vw", minWidth: "40vw" }}
            />
            <div className="place-nft">
              <button className="choose-number" onClick={() => onSelect(1)}>
                1
              </button>
              <button className="choose-number" onClick={() => onSelect(2)}>
                2
              </button>
              <button className="choose-number" onClick={() => onSelect(3)}>
                3
              </button>
              <button className="choose-number" onClick={() => onSelect(4)}>
                4
              </button>
              <button className="choose-number" onClick={() => onSelect(5)}>
                5
              </button>
              <button className="choose-number" onClick={() => onSelect(6)}>
                6
              </button>
            </div>
          </div>
          {/* {            <div className="expo-arrow">
                <img src={arrow} style={{maxWidth: '30px', marginTop: '17vh', marginLeft: '3vw', cursor: 'pointer'}}/>
            </div>} */}
        </div>
      ) : (
        <ShowroomRecap
          popSnack={popSnack}
          positions={positions}
          nfts={nfts}
          selectedShowroom={selectedShowroom}
        />
      )}
      {page == 0 ? (
        <div className="expo-foot">
          <Next page={page} setPage={setPage} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

interface ImageProps {
  image: string;
  nftSelected: number;
  idx: number;
}

const ImgNft = ({ image, nftSelected, idx }: ImageProps) => {
  console.log(image);
  const [img, setImg] = useState("");
  const [update, setUpdate] = useState(false);
  const { t } = useTranslation("exposeShowroom");

  useEffect(() => {
    if (img === "") {
      const imageUrl = axios
        .get(
          `${image}?pinataGatewayToken=aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              //'x-pinata-gateway-token' : 'aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191'
            },
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          //console.log(res)
          const binaryString = Array.from(new Uint8Array(res.data), (v) =>
            String.fromCharCode(v)
          ).join("");
          const theImage = btoa(binaryString);
          // console.log(theImage)
          setImg(theImage);
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => setUpdate(!update));
    }
  });

  return (
    <>
      {img === "" ? (
        <>{t("loading")}</>
      ) : (
        <img
          src={`data:image/png;base64,${img}`}
          style={{
            maxWidth: "10vw",
            minWidth: "10vw",
            maxHeight: "15vh",
            minHeight: "15vh",
            borderRadius: "10px",
            border:
              nftSelected === idx ? "3px solid #EF19D1" : "3px solid #04152D",
          }}
        />
      )}
    </>
  );
};

export default ChooseShowroom;
