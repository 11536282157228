import { useContext, useState } from "react"
import { CircularProgress, TextField } from "@mui/material"
import axios from 'axios'
import { backend, GlobalStateContext } from "../../App"
import useFullPageLoader from "../../hooks/UseLoader"
import { useTranslation } from "react-i18next"

interface CreateProps {
    setCreate: any
    page: number
    setPage: any
    setSelectedOffer: any
    popSnack: any
    setUpdatCollec: any
    jwt: string
}

const Create = ({ popSnack, setSelectedOffer, page, setPage, setCreate, setUpdatCollec, jwt }: CreateProps) => {
    const [name, setName] = useState("")
    const [symb, setSymb] = useState("")
    const [desc, setDesc] = useState("")
    const [url, setUrl] = useState("")
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const {t} = useTranslation("offer");
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error("MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider");
    }
    const { globalVar, setGlobalVar } = context;

    const postPromotion = async (code: string) => {
        if (typeof showLoader === 'function') {
            showLoader()
        }
        axios.post(`${backend}/promo/addpromo`, { code: code, name: name, description: desc, url: url }, {headers: {"x-api-key": process.env.REACT_APP_API, "authorization": jwt, "vault": globalVar}}).then((resp) => {
            console.log(resp)
            setCreate(false)
            setSelectedOffer({ code: code })
            popSnack(t('offerCreated'), 'success')
            setPage(page + 1)
            if (typeof hideLoader === 'function') {
                hideLoader()
            }
        }).catch((err) => {
            console.log(err)
            if (typeof hideLoader === 'function') {
                hideLoader()
            }
            popSnack(t('errorCreating'), 'error')
        }).finally(() => {
            setUpdatCollec(false)
        })
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "start", flexDirection: "column" }}>
                <div className="div-details-offers">
                    <TextField autoComplete='off' variant="outlined" label={t('privilegeName')} style={{ minWidth: "25vw", border: "green" }} className="txtfield-offer" value={name} onChange={(e: any) => {
                        setName(e.target.value)
                    }} />
                    <TextField autoComplete='off' variant="outlined" label={t('code')} style={{ marginLeft: "5vw" }} className="txtfield-offer" value={symb} onChange={(e: any) => {
                        setSymb(e.target.value)
                    }} />
                </div>
                <TextField autoComplete='off' variant="outlined" label={t('privilegeDescription')} style={{ minWidth: "40vw", maxWidth: "40vw", marginTop: "5vh" }} className="txtfield-offer" value={desc} onChange={(e: any) => {
                    setDesc(e.target.value)
                }} />
                <TextField autoComplete='off' variant="outlined" label={t('privilegeUrl')} style={{ minWidth: "40vw", maxWidth: "40vw", marginTop: "5vh" }} value={url} className="txtfield-offer" onChange={(e: any) => {
                    setUrl(e.target.value)
                }} />
                <button className="create-offer-button" onClick={() => {
                    if (symb == "") return
                    postPromotion(symb)
                }}>
                    {t('createOffer')}
                </button>
            </div>
            {loader}
        </>
    )
}

export default Create