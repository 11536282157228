import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snack from "../Snackbar/Snackbar";
import { backend, GlobalStateContext } from "../../App";
import "./CreateCollection.css";
import CircularProgress from "@mui/material/CircularProgress";
import useFullPageLoader from "../../hooks/UseLoader";
import { useTranslation } from "react-i18next";

interface RecapProps {
    collection: any
    setCollections: any
    popSnack: any
    setUpdatCollec: any
    numSeries: string[]
    nfts: any[]
    jwt: string
    isCsv: boolean
}

export const RecapV2 = ({ collection }: RecapProps) => {
  const { t } = useTranslation("createCollection");

  return (
    <>
      <div className="recap">
        <RecapText title={t("name")} txt={collection.name} />
        <RecapText title={t("symbol")} txt={collection.symb} />
      </div>
      <RecapText title={t("description")} txt={collection.desc} />
      <div className="recap">
        <RecapText title={t("total")} txt={`${collection.nbElms} NFTs`} />
        <RecapText title={t("price")} txt={`${collection.price} eth`} />
      </div>
    </>
  );
};

const mintNfts = (
  mintAmount: number,
  contractAddress: string,
  receiverAddress: string
) => {
  const uri = `${backend}/nft/mint`;
  axios
    .post(
      uri,
      {
        contractAddress: contractAddress,
        receiverAddress: receiverAddress,
        mintAmount: mintAmount,
      },
      { headers: { "x-api-key": process.env.REACT_APP_API } }
    )
    .then((resp) => {
      console.log(resp);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const Recap = ({
  popSnack,
  collection,
  setCollections,
  setUpdatCollec,
  numSeries,
  nfts,
  isCsv,
  jwt,
}: RecapProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openErr, setOpenErr] = useState(false);
  const [hashs, setHashs] = useState({ img: "", mdl: "", metas: "" });
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { t } = useTranslation("createCollection");
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;

  const deployContract = async () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    const tmp = hashs;
    axios
      .post(
        `${backend}/files/pin/all`,
        {
          name: collection.name,
          description: collection.desc,
          nbElm: Number(collection.nbElms),
          numSeries: numSeries,
          nfts: nfts,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        tmp.img = resp.data.images.IpfsHash;
        tmp.metas = resp.data.metas.IpfsHash;
        tmp.mdl = `${backend}/files/download/${collection.models}`;
        //setHashs(tmp)
      })
      .catch((err) => {
        console.log(err);
        if (typeof hideLoader === "function") {
          hideLoader();
        }
      })
      .finally(() => {
        axios
          .post(
            `${backend}/nft/deploy/mint`,
            {
              name: collection.name,
              description: collection.desc,
              symbole: collection.symb,
              price: Number(collection.price),
              nbElm: Number(collection.nbElms),
              baseUri: `https://lavender-payable-zebra-843.mypinata.cloud/ipfs/${tmp.metas}/`,
            },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                authorization: jwt,
                vault: globalVar,
              },
            }
          )
          .then((resp) => {
            console.log(resp);
            setOpen(true);
            if (typeof hideLoader === "function") {
              hideLoader();
            }
            setCollections([]);
            setUpdatCollec(false);
            popSnack(t("created"), "success");
            navigate("/collections");
          })
          .catch((err) => {
            console.log(err);
            setOpenErr(true);
            if (typeof hideLoader === "function") {
              hideLoader();
            }
            popSnack(t("errorCollectionCreation"), "error");
          });
      });
  };


    const deployContractCsv = async () => {
        if (typeof showLoader === 'function') {
            showLoader()
        } 
        axios.post(`${backend}/deploy/mint/csv`, { name: collection.name, description: collection.desc, symbole: collection.symb, price: Number(collection.price), nbElm: Number(collection.nbElms) }, {headers: {"x-api-key": process.env.REACT_APP_API, "authorization": jwt, "vault": globalVar}}).then((resp) => {
                console.log(resp)
                setOpen(true)
                if (typeof hideLoader === 'function') {
                    hideLoader()
                } setCollections([])
                setUpdatCollec(false)
                popSnack(t('created'), 'success')
                navigate('/collections')
            }).catch((err) => {
                console.log(err)
                setOpenErr(true)
                if (typeof hideLoader === 'function') {
                    hideLoader()
                }
                popSnack(t('errorCollectionCreation'), 'error')
            })
    }

    return <>
        <Snack open={open} setOpen={setOpen} status="success" message={t('collectionDeployed', {name: collection.name})} />
        <Snack open={openErr} setOpen={setOpenErr} status="error" message={t('errorDeployment')} />

        <div className='recap' style={{ marginTop: "10vh" }}>
            <div className='recap-line'>
                <span style={{ fontSize: "2.5vw", fontWeight: "bold" }}>
                    {collection.name}
                </span>
                <span style={{ fontSize: "1.5vw", fontStyle: "italic", textAlign: 'start' }}>
                    {collection.desc}
                </span>
            </div>
            <RecapText title={t('symbol')} txt={collection.symb} />
        </div>
        <div className='recap' style={{ marginTop: "5vh" }}>
            <RecapText title={t('nftDeployed')} txt={`${collection.nbElms}`} />
            <RecapText title={t('price')} txt={`${collection.price} eth`} />
        </div>
        <button className="recap-button" onClick={() => { !isCsv ? deployContract() : deployContractCsv()}}>
            {t('createCollection')}
        </button>
        {loader}
    </>
};

interface RecapTextProps {
  title: string;
  txt: string;
}

const RecapText = ({ title, txt }: RecapTextProps) => {
  //  const margin = title === 'Collection name' ? '5vh' : title === 'Symbol'? '5vh' : '10vh'

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: title === "NFT deployed" ? "start" : "end",
        flexDirection: "column",
      }}
    >
      <span className="recap-title">{title}</span>
      <span className="recap-txt">{txt}</span>
    </div>
  );
};

export default Recap;
