// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-background {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80vw;
    max-width: 80vw;
    min-height: 100vh;
    max-height: 100vh;
}

.chat-list {
    width: 20vw;
    height: 70vh;
    background-color: #1A223D;
    border-radius: 10px;
    margin-right: 5%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    overflow: scroll;
}

.chat-list::-webkit-scrollbar {
    display: none;
}

.chat-messages {
    width: 40vw;
    height: 70vh;
    background-color: #1A223D;
    border-radius: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
}

.chat-content::-webkit-scrollbar {
    display: none;
}

.chat-input {
    height: 3vh;
    
}

.chat-input:focus {
    border: none;
    outline:none
}`, "",{"version":3,"sources":["webpack://./src/components/Chat/Chat.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;;AAEf;;AAEA;IACI,YAAY;IACZ;AACJ","sourcesContent":[".chat-background {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-width: 80vw;\n    max-width: 80vw;\n    min-height: 100vh;\n    max-height: 100vh;\n}\n\n.chat-list {\n    width: 20vw;\n    height: 70vh;\n    background-color: #1A223D;\n    border-radius: 10px;\n    margin-right: 5%;\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    flex-direction: column;\n    overflow: scroll;\n}\n\n.chat-list::-webkit-scrollbar {\n    display: none;\n}\n\n.chat-messages {\n    width: 40vw;\n    height: 70vh;\n    background-color: #1A223D;\n    border-radius: 10px;\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    flex-direction: column;\n}\n\n.chat-content::-webkit-scrollbar {\n    display: none;\n}\n\n.chat-input {\n    height: 3vh;\n    \n}\n\n.chat-input:focus {\n    border: none;\n    outline:none\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
