// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fpl-container {
    top: 0;
    left: 20vw;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #787777ad;
}

.fpl-container .fpl-loader {
    top: 48%;
    left: 37%;
    z-index: 1000;
    position: absolute;
}

@media (max-width: 480px) {
    .fpl-container {
        top: 0;
        left: 0;
        position: fixed;
        width: 100%;
        height: 100%;
        background: #787777ad;
    }
    
    .fpl-container .fpl-loader {
        top: 48%;
        left: 46%;
        z-index: 1000;
        position: absolute;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/FullPageLoader/FullPageLoader.css"],"names":[],"mappings":"AAAA;IACI,MAAM;IACN,UAAU;IACV,eAAe;IACf,WAAW;IACX,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,QAAQ;IACR,SAAS;IACT,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI;QACI,MAAM;QACN,OAAO;QACP,eAAe;QACf,WAAW;QACX,YAAY;QACZ,qBAAqB;IACzB;;IAEA;QACI,QAAQ;QACR,SAAS;QACT,aAAa;QACb,kBAAkB;IACtB;EACF","sourcesContent":[".fpl-container {\n    top: 0;\n    left: 20vw;\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    background: #787777ad;\n}\n\n.fpl-container .fpl-loader {\n    top: 48%;\n    left: 37%;\n    z-index: 1000;\n    position: absolute;\n}\n\n@media (max-width: 480px) {\n    .fpl-container {\n        top: 0;\n        left: 0;\n        position: fixed;\n        width: 100%;\n        height: 100%;\n        background: #787777ad;\n    }\n    \n    .fpl-container .fpl-loader {\n        top: 48%;\n        left: 46%;\n        z-index: 1000;\n        position: absolute;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
