import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PopupChoice } from "src/components/Popup/Popup";

interface SignaturePopupProps {
  onSign: (signature: string) => void;
}

const SignaturePopup: React.FC<SignaturePopupProps> = ({ onSign }) => {
  const { t } = useTranslation("mobileConnection");

  const titletx = "Authentification";
  const description = t("sign");
  const [open, setopen] = useState(true);
  const message = "Sign this message to authenticate yourself.";
  console.log("signature");

  const handleSign = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      if (accounts.length > 0) {
        const account = accounts[0];
        const signature = await window.ethereum.request({
          method: "personal_sign",
          params: [message, account],
        });

        onSign(signature);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <PopupChoice
        open={open}
        setOpen={setopen}
        titletext={titletx}
        descriptiontext={description}
        Action={handleSign}
      />
    </div>
  );
};

export default SignaturePopup;
