import axios from "axios";
import React, { useEffect, useState } from "react";
import Im from '../../assets/ethImage.png'

import './Card.css'

interface CardDisplayerProps {
    collection : any
    idx: number
    isPoa: boolean
}

const CardDisplayer = ({collection, idx, isPoa}: CardDisplayerProps) => {
    const [isLoaded, setLoaded] = useState(false)
    const [imgDisplay, setImg] = useState("")
    useEffect(() => {
        if (collection.imgs.length >= 1) {
            const imageUrl = axios.get(`${collection.imgs[0]}?pinataGatewayToken=aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191`,{
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                 }, responseType: "arraybuffer"
                }).then((res) => {
                    //console.log(res)
                    const binaryString = Array.from(new Uint8Array(res.data), v => String.fromCharCode(v)).join("");
                    const theImage = btoa(binaryString);
                    //console.log(theImage)
                    setImg(theImage)
                }).catch((err) => {
                    console.log(err)
                }).finally(() => setLoaded(true)) 
        }

    })

    if (!isPoa) {
        return <>{isLoaded ? <Card description={collection.desc} name={collection.name} image={imgDisplay} address={collection.address}/> : <></>}</>
    }
    return  <>{isLoaded ?<CardPoap eventName={collection.eventName} date={collection.date} description={collection.description} image={imgDisplay} /> :<> </>}</>
}

interface CardProps {
    name: string,
    image: string,
    description: string,
    address: string,
}

export const Card = ({name, description, image, address}: CardProps) => {

    return <div className="card-background" > 
        <img src={`data:image/png;base64,${image}`} className='card-image'/>
        <div className="card-texts">
            <span style={{fontWeight: 'bold', color: 'white'}}>
                {name}
            </span>
            <span style={{marginTop: '0.5vh'}}>
                {description}
            </span>            
        </div>

    </div>
}

interface CardPoapProps {
    eventName: string,
    date: string,
    description: string,
    image: string
}

const CardPoap = ({eventName, date, description, image}: CardPoapProps) => {
    return <div className="card-background">
        <img src={`data:image/png;base64,${image}`} className='card-image'/>
        <div className="card-texts">
            <span style={{fontWeight: 'bold', color: 'white'}}>
                {eventName}
            </span>
            <span style={{marginTop: '0.5vh'}}>
                {description}
            </span>            
        </div>
    </div>
}

export default CardDisplayer