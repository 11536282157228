import React, { useState } from "react";

import axios from "axios";
import { backend } from "../../App";
import "./Card.css";
import { TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

interface NftCardProps {
  name: string;
  image: string;
  nfts: any[];
  setNfts: any;
  idx: number;
  numSerie: string;
  setSerie: any;
  series: string[];
  jwt: string;
}

export const NftCard = ({
  name,
  image,
  nfts,
  setNfts,
  idx,
  numSerie,
  setSerie,
  series,
  jwt,
}: NftCardProps) => {
  const [isCertif, setCertif] = useState(false);
  const [is3D, set3D] = useState(false);

  const hiddenFileInput = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);

  const { t } = useTranslation("collectionCard");

  const handleClick = () => {
    if (hiddenFileInput.current !== null)
      // @ts-ignore
      hiddenFileInput.current.click();
  };

  const handleClick2 = () => {
    if (hiddenFileInput2.current !== null)
      // @ts-ignore
      hiddenFileInput2.current.click();
  };

  const add3D = (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let tmp = nfts;
      let data = new FormData();
      data.append("file", file, file.name);
      axios
        .post(`${backend}/files/upload/models/${idx + 1}`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data`,
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
          },
        })
        .then((resp) => {
          console.log(resp);
          tmp[idx].model = `${backend}/files/download/${resp.data.folder}`; //URL.createObjectURL(e.target.files[0])
          setNfts(tmp);
          set3D(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addCertif = (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let tmp = nfts;
      let data = new FormData();
      data.append("file", file, file.name);
      axios
        .post(`${backend}/files/upload/certif/${idx + 1}`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data`,
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
          },
        })
        .then((resp) => {
          tmp[
            idx
          ].certif = `${backend}/files/download/certif/${resp.data.folder}`; //URL.createObjectURL(e.target.files[0])
          setNfts(tmp);
          setCertif(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="nft-background">
      <input
        ref={hiddenFileInput}
        type="file"
        name="file"
        style={{ display: "none" }}
        onChange={(e) => add3D(e)}
      />
      <input
        ref={hiddenFileInput2}
        type="file"
        name="file"
        style={{ display: "none" }}
        onChange={(e) => addCertif(e)}
      />
      <img src={image} className="nft-image" />
      <span className="nft-name">{name}</span>
      <TextField
        value={numSerie}
        onChange={(e) => {
          let tmp = series;
          tmp[idx] = e.target.value;
          setSerie(tmp);
        }}
        label="Serial/lot number"
        variant="outlined"
        className="txtfield-ref"
        size="small"
        style={{ marginTop: "1.5vh", marginBottom: "1vh" }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          minWidth: "13vw",
        }}
      >
        <button className="nft-3d" onClick={handleClick2}>
          {!isCertif ? t("addCertificate") : <EditIcon />}
        </button>
        <button className="nft-3d" onClick={handleClick}>
          {!is3D ? t("addModel") : <EditIcon />}
        </button>
      </div>
    </div>
  );
};

export default NftCard;
