import React from "react"

import './StepTitle.css'

interface CollectionHeaderProps {
    page: number
    total: number
    title: string
    description: string
}

export const CollectionHeader = ({total, page, title, description}: CollectionHeaderProps) => {

    return <div className="create-head-2">
        <h2 style={{color: '#078BF9'}}>
            {title}
        </h2>
        <span style={{color: '#e2e2e2'}}>
            {`${page}/${total} - ${description}`}
        </span>
    </div>
}

export default CollectionHeader