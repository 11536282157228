import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from 'react'

interface PopupPropsChoice {
  open: boolean;
  setOpen: any;
  titletext: string;
  descriptiontext: string;
  Action: any;
}

export const PopupChoice = ({
  open,
  setOpen,
  titletext,
  descriptiontext,
  Action,
}: PopupPropsChoice) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor: "#04152D",
            color: "white",
            fontWeight: "bold",
            fontSize: "1.5em",
          }}
        >
          {titletext}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#04152D" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              backgroundColor: "#04152D",
              color: "white",
              fontWeight: "600",
              fontSize: "1.1em",
            }}
          >
            {descriptiontext}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "#04152D",
            color: "white",
            fontWeight: "bold",
            fontSize: "1.4em",
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              backgroundColor: "#04152D",
              color: "white",
              fontWeight: "bold",
              fontSize: "0.8em",
              "&:hover": { backgroundColor: "#051e41" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              Action();
            }}
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: "0.8em",
              "&:hover": { backgroundColor: "#051e41" },
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};


interface PopupProps {
    title: string
    content: string
    open: boolean
    setOpen: any
}

export const Popup = ({title, content, open, setOpen}: PopupProps) => {
    return  <Dialog
    open={open}
    onClose={() => {
      setOpen(false);
    }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth={true}
    maxWidth={"sm"}
  >
    <DialogTitle
      id="alert-dialog-title"
      sx={{
        backgroundColor: "#04152D",
        color: "white",
        fontWeight: "bold",
        fontSize: "1.5em",
      }}
    >
        {title}
      {/* {!nft.lost ? <span>Lost Product</span> : <span>Found Product</span>} */}
    </DialogTitle>
    <DialogContent sx={{ backgroundColor: "#04152D" }}>
      <DialogContentText
        id="alert-dialog-description"
        sx={{
          backgroundColor: "#04152D",
          color: "white",
          fontWeight: "600",
          fontSize: "1.1em",
        }}
      >
        {content}
        {/* {!nft.lost ? (
          <span>Did you lost your product?</span>
        ) : (
          <span>Did you found this product?</span>
        )} */}
      </DialogContentText>
    </DialogContent>
    <DialogActions
      sx={{
        backgroundColor: "#04152D",
        color: "white",
        fontWeight: "bold",
        fontSize: "1.4em",
      }}
    >
      <Button
        onClick={() => {
            setOpen(false)
        }}
        sx={{
          color: "white",
          fontWeight: "bold",
          fontSize: "0.8em",
          "&:hover": { backgroundColor: "#051e41" },
        }}
        autoFocus
      >
        Ok
      </Button>
    </DialogActions>
  </Dialog>
}

export default Popup
