import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import lock from "src/assets/passport/lockw.png";
import payment from "src/assets/passport/payment.png";

interface PaymentProps {
  money: number;
  actionConnect: any;
}

export const Payment = ({ money, actionConnect }: PaymentProps) => {
  const { t } = useTranslation("mobileConnection");

  const Pay = () => {
    actionConnect();
  };

  return (
    <div className="wallet-user">
      <div className="wallet-user-txt">{t("payment")}</div>
      <div className="wallet-user-form" style={{ marginTop: "10px" }}>
        <div className="wallet-payment">
          <img src={payment} alt="" style={{ width: "80%" }} />
        </div>
        <TextField
          className="send-txtfield"
          autoComplete="cc-name"
          variant="outlined"
          label={t("OwnerName")}
          style={{ minWidth: "22vw", marginTop: "3vh", marginInline: "3vw" }}
        />
        <TextField
          className="send-txtfield"
          autoComplete="cc-number"
          variant="outlined"
          label={t("CreditCard")}
          style={{ minWidth: "22vw", marginTop: "3vh", marginInline: "3vw" }}
        />
        <div>
          <TextField
            className="send-txtfield"
            autoComplete="cc-exp"
            variant="outlined"
            label="MM / YY"
            style={{
              minWidth: "20vw",
              maxWidth: "40vw",
              marginTop: "3vh",
              marginRight: "2vw",
            }}
          />
          <TextField
            className="send-txtfield"
            autoComplete="cc-csc"
            variant="outlined"
            label="CVV"
            style={{
              minWidth: "20vw",
              maxWidth: "40vw",
              marginTop: "3vh",
              marginLeft: "2vw",
            }}
          />
        </div>
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          <button
            className="wallet-user-btn-payment"
            onClick={() => {
              Pay();
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "40%" }}>
                <img
                  src={lock}
                  alt=""
                  style={{ width: "25%", marginLeft: "25px" }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {t("pay")} {money}€
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
