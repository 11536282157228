import React from "react";

import './CreateCollection.css'
import { useTranslation } from "react-i18next";

interface NextProps {
    page: number,
    setPage: any,
    isRecapReady?: boolean,
    handleMetas?: any
}

export const Next = ({page, setPage, isRecapReady, handleMetas}: NextProps) => {
    const {t} = useTranslation("createCollection");
    return <div className="div-next">
        <button style={{backgroundColor: page == 1 ? '#5c87aa' : '', cursor: page == 1 ? t('notAllowed') : ''}} className="button-next-left" onClick={() => {
            if (page !== 1) setPage(page - 1)
        }}>
            {"<"}
        </button>
        <button style={{backgroundColor: page == 1 ? '#5c87aa' : '', cursor: page == 1 ? t('notAllowed') : ''}} className="button-next-right" onClick={() => {

                if (page !== 1) setPage(page + 1)
        }}>
            {">"}
        </button>
    </div>
}

export default Next