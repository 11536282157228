import axios from "axios";
import "../../MobileApp.scss";
import { backend } from "src/App";
import { useEffect, useState } from "react";
import rouage from "src/assets/passport/rouageBlack.png";
import mint from "src/assets/passport/mintBlack.png";
import transfert from "src/assets/passport/transfert_black.png";

interface HistoryNFTProps {
  nft: any;
  coladdr: string;
  allClients: any;
  jwt: string;
}

export const HistoryNFT = ({
  nft,
  coladdr,
  allClients,
  jwt,
}: HistoryNFTProps) => {
  const [histo, setHisto] = useState<{
    owner: string;
    data: string[];
    id: string;
  }>({ owner: "", data: [], id: "-1" });
  const id = nft.name.split("#")[1];

  const fetchHistory = async () => {
    console.log(process.env.REACT_APP_API);
    axios
      .post(
        `${backend}/nft/history/id`,
        { coladdr: coladdr, id: Number(id) },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: jwt,
          },
        }
      )
      .then((resp) => {
        //console.log(resp);
        axios
          .post(
            `${backend}/client/getclientfromaddr`,
            { addr: resp.data.owner },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                Authorization: jwt,
              },
            }
          )
          .then((res) => {
            setHisto({
              owner: res.data,
              data: resp.data.data,
              id: resp.data.id,
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (histo.owner == "") {
      fetchHistory();
    }
  }, []);

  return (
    <div className="history">
      {histo.data ? (
        histo?.data.reverse().map((elm, idx) => {
          console.log(elm);
          return (
            <>
              <RowHistory
                allClients={allClients}
                date={elm.split(";")[1]}
                comment={elm.split(";")[2]}
                event={elm.split(";")[0]}
                idx={idx}
                size={histo.data.length - 1}
              />
            </>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

interface RowProps {
  date: string;
  comment: string;
  event: string;
  allClients: any;
  idx: number;
  size: number;
}

const RowHistory = ({ date, comment, event, allClients, idx, size }: RowProps) => {
  console.log("date", date);
  let dateConv = new Date(Date.parse(date));
  let dateFormat = dateConv.toDateString();

  let iconLigne = rouage;

  const timestampToDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);

    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = days[date.getUTCDay()];
    const month = months[date.getUTCMonth()];
    const dayOfMonth = date.getUTCDate();
    const year = date.getUTCFullYear();

    return `${month} ${dayOfMonth}`;
  };

  if (dateFormat == "Invalid Date") {
    dateFormat = timestampToDate(Number(date));
  } else {
    dateFormat = dateFormat.split(" ")[1] + " " + dateFormat.split(" ")[2];
  }

  if (event == "Mint") event = "Production";

  const matchAddr = (addr: string) => {
    let resAddr = "";
    allClients?.map((elm: any) => {
      if (elm.wallets[0]?.toLowerCase() == addr?.toLowerCase()) {
        resAddr = elm.name;
      }
    });
    if (resAddr == "") return addr;
    return resAddr;
  };

  let from = "";
  let to = "";
  if (event == ("Production" || "Mint")) iconLigne = mint;
  else if (event == "Transfer") {
    iconLigne = transfert;
    from = matchAddr(comment.split(" Transfer to ")[0]);
    if (from.includes("0x")) {
      from = `${from.substring(0, 8)}...${from.substring(from.length - 8)}`;
    }
    to = matchAddr(comment.split(" Transfer to ")[1]);
    if (to.includes("0x")) {
      to = `${to.substring(0, 8)}...${to.substring(to.length - 8)}`;
    }
    comment = from + " Transfer to " + to;
  }

  return (
    <div className="history-content">
      <span className="history-date">{date !== "" ? dateFormat : ""}</span>
      <div
        style={{
          width: "12%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {
          idx !== 0 ? <div style={{ minHeight: "4.5vh", borderLeft: "1px solid black"}}/> : <>
          <div style={{marginTop: "4.5vh"}}></div></>
        }
        <div className="history-icon">
          <img src={iconLigne} style={{ maxWidth: "6vw" }} />
        </div>
        {
          idx !== size ? <div style={{ minHeight: "4.5vh", borderLeft: "1px solid black"}}/> : <>
          <div style={{marginBottom: "4.5vh"}}></div></>
        }
      </div>
      <div className="history-right-part">
        <span className="history-event">{event}</span>
      </div>
    </div>
  );
};

export default HistoryNFT;
