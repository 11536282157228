import lock from "src/assets/passport/lockw.png";
import axios from "axios";
import { backend } from "src/App";
import { useTranslation } from "react-i18next";
import useFullPageLoader from "src/hooks/UseLoader";
import { Checkbox } from "@mui/material";
import { useState } from "react";

interface LostProps {
  popSnack: any;
  nft: any;
  coladdr: any;
}

export const Lost = ({ nft, popSnack, coladdr }: LostProps) => {
  const { t } = useTranslation("mobileWallet");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [isLost, setIsLost] = useState(false);
  const [isTheft, setIsTheft] = useState(false);

  const productLost = (loststatus: boolean) => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(`${backend}/nft/lost`, {
        coladdr: coladdr,
        id: nft.id + 1,
        lost: loststatus,
      })
      .then((resp) => {
        nft.lost = loststatus;
        console.log(resp);
        popSnack(t("notified"), "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
      })
      .catch((err) => {
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack(t("errorNotified"), "error");
        console.log(err);
      });
  };

  return (
    <>
      <div className="mobileW-lost">
        <p style={{ fontWeight: "bold" }}>
          Please confirm that your product has been lost or stolen
        </p>
        <div className="mobileW-lost-tick">
          <Checkbox
            sx={{
              color: "black",
            }}
            checked={isLost}
            onChange={() => {
              setIsLost(!isLost);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
          <span style={{marginTop: "1.2vh"}}>Lost</span>
        </div>
        <div className="mobileW-lost-tick">
          <Checkbox
            sx={{
              color: "black",
            }}
            checked={isTheft}
            onChange={() => {
              setIsTheft(!isTheft);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
          <span style={{marginTop: "1.2vh"}}>Stolen</span>
        </div>

        <div
          className="mobileW-lost-btn"
          onClick={() => {
            productLost(!nft.lost);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "25%",
            }}
          >
            <img
              src={lock}
              alt=""
              style={{ width: "25%", marginLeft: "25px" }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {nft.lost ? "Unlock my product" : "Lock my product"}
          </div>
        </div>
        <p style={{ fontWeight: "bold" }}>
          If you find your product, you will be able to reactivate it in your
          wardrobe
        </p>
        <p style={{ fontWeight: "bold" }}>
          In the meantime, Maison Angleys will block any transactions, and will
          identify if any suspicious movements occur
        </p>
      </div>
      {loader}
    </>
  );
};

export default Lost;
