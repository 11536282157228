import React from "react";
import { useTranslation } from "react-i18next";

import { Languages } from "../Language/Language";
import { ethers } from "ethers";
import logo from "../../assets/logoRed.png"
import './Login.css'
interface LoginProps {
    acc: string
    setAcc: any
    login: boolean
    setLogin: any
    setSigner: any
}

export const Loginn = ({acc, setAcc, login, setLogin, setSigner}: LoginProps) => {
    const {t} = useTranslation("login");
    //console.log('account : ', acc)
    const connectToMetamask = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const accounts = await provider.send("eth_requestAccounts", []);
        setAcc(accounts[0])
        const newSigner = await provider.getSigner();
        setSigner(newSigner)
      }

    return <div style={{position: "absolute", minWidth: "100vw", minHeight: "100vh", backgroundColor: "#04152D", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'row'}}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center",minWidth: "40vw", minHeight: "100vh", backgroundColor: "#FAFAFA", flexDirection: "column"}}>
                <h1  style={{fontSize: '3em'}}>{t('welcome')}</h1>
                <img src={logo} style={{maxWidth: '20vw'}}/>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center",minWidth: "60vw", minHeight: "100vh"}}>
                <button onClick={() => {
                    connectToMetamask()
                }} style={{marginTop: "5vh", minWidth: "30vw", minHeight: "5vh", borderRadius: "50px", border: "none", fontWeight: "bold", color: "white", backgroundColor: "#EF19D1", cursor: "pointer", fontSize: '1.2em'}}>
                    {t('connect')}
                </button>
                <Languages/>
            </div>
    </div>
}

export default Loginn