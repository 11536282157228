import "../MobileApp.scss";
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  LoginSocialGoogle,
  IResolveParams,
  LoginSocialFacebook,
  LoginSocialApple,
} from "reactjs-social-login";
import useFullPageLoader from "src/hooks/UseLoader";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { useCallback, useState } from "react";
import axios from "axios";
import { backend } from "src/App";
import { TextField } from "@mui/material";
import AppleLogo from "src/assets/appleBlack.png";
import Facebook from "src/assets/Facebook.png";
import Google from "src/assets/google.png";

interface AccountConnectProps {
  popSnack: any;
  setAcc: any;
  setPage: any;
  urlRedirect: string;
  setsign: any;
  collec: string;
  idNFT: string;
  setAccConnected: any;
}

export const AccountConnect = ({
  popSnack,
  setAcc,
  setAccConnected,
  setPage,
  urlRedirect,
  setsign,
  collec,
  idNFT,
}: AccountConnectProps) => {
  const { t } = useTranslation("mobileConnection");
  const [queryParameters] = useSearchParams();
  const [provider, setProvider] = useState<any>();
  const [profile, setProfile] = useState<any>();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const urlProd = process.env.REACT_APP_PROD_URL || "http://localhost";
  const portAbstract = process.env.REACT_APP_PORT_ABSTRACT || "3005";
  const urlwallet = urlProd + "/address";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  if (profile) {
    console.log("ID: " + profile.client_id);
  }

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  const connectToMetamask = async () => {
    if (navigator.userAgent.includes("MetaMask")) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await provider.send("eth_requestAccounts", []);
      setAcc(accounts[0]);
      popSnack("Metamask connected", "success");
      setsign(true);
    } else {
      const collec = queryParameters.get("collec") || "none";
      const idNFT = queryParameters.get("idNFT") || "-1";
      let urlRedirect = "/wallet";

      if (collec != "none") {
        urlRedirect += "?collec=" + collec;
        if (idNFT != "-1") {
          urlRedirect += "&idNFT=" + idNFT;
        }
      }
      window.location.assign(
        "https://metamask.app.link/dapp/nftexperience.bearingpoint.com" +
          urlRedirect
      );
    }
  };

  const connectEmail = () => {
    setPage(3);
  };

  const connectHermes = () => {
    if (collec != "none") {
      localStorage.setItem("collec", collec);
    }
    if (idNFT != "-1") {
      localStorage.setItem("idNft", idNFT);
    }
    window.location.replace(process.env.REACT_APP_BACKEND || "_blank");
  };

  const createAccount = async (profile: any, isFb: boolean) => {
    // access new acc
    if (!profile) {
      popSnack(t("fillInfos"), "warning");
    } else {
      if (typeof showLoader === "function") {
        showLoader();
      }
      let acc = "";
      let msg = "";
      if (isFb) {
        msg = profile.userID;
      } else {
        msg = profile.email + profile.family_name + profile.name;
      }
      console.log(msg, isFb);
      await axios
        .post(
          backend + "/nft/encoder",
          { message: msg },
          { headers: { "x-api-key": process.env.REACT_APP_API } }
        )
        .then(async (resp) => {
          acc = resp.data.address;
          setAcc(resp.data.address);
          localStorage.setItem("token", resp.data.token);
          console.log("created address : ", resp.data.address, acc);
          let email, fname;
          if (isFb) {
            email = "test@" + msg;
            fname = msg;
          } else {
            email = profile.email;
            fname = profile.family_name + " " + profile.given_name;
          }
          await axios
            .post(
              `${backend}/client/add`,
              { email: email, name: fname, wallet: acc },
              {
                headers: {
                  "x-api-key": process.env.REACT_APP_API,
                  Authorization: localStorage.getItem("token"),
                },
              }
            )
            .then((resp) => {
              localStorage.setItem("privatekeyXmtp", resp.data.privatekey);
              if (resp.data.wallet === "none") {
                popSnack(t("alreadyExisting"), "success");
              } else {
                popSnack(t("accountCreated"), "success");
              }
              setAccConnected(true);
              if (typeof hideLoader === "function") {
                hideLoader();
              }
            })
            .catch(() => {
              if (typeof hideLoader === "function") {
                hideLoader();
              }
              popSnack(t("errorCreating"), "error");
            });
        })
        .catch((err) => {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          console.log(err);
        });
    }
  };
  const onLoginStart = useCallback(() => {}, []);

  const ConnectWallet = async () => {
    if (email == "" || password == "") {
      popSnack(t("fillInfos"), "warning");
    } else {
      if (typeof showLoader === "function") {
        showLoader();
      }
      let acc = "";
      await axios
        .post(
          urlwallet,
          { email: email, password: password },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (resp) => {
          acc = resp.data.address;
          localStorage.setItem("token", resp.data.token);
          setAcc(resp.data.address);
          await axios
            .get(`${backend}/client/${acc}`, {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                authorization: localStorage.getItem("token"),
              },
            })
            .then((resp) => {
              console.log("client exist");
              if (resp.data != "") {
                localStorage.setItem("privatekeyXmtp", resp.data);
                setAccConnected(true);
                popSnack(t("connected"), "success");
              } else {
                popSnack(t("notExist"), "warning");
              }
              if (typeof hideLoader === "function") {
                hideLoader();
              }
            })
            .catch(() => {
              if (typeof hideLoader === "function") {
                hideLoader();
              }
              popSnack(t("errorConnect"), "error");
            });
        })
        .catch((err) => {
          console.log(err);
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack(t("errorConnect"), "error");
        });
    }
  };

  const createAA = () => {
    setPage(4);
  };

  return (
    <>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className="wallet-connect-title">{t("connection")}</div>
      <div className="wallet-connect-btn">
        <TextField
          variant="outlined"
          style={{
            width: "80vw",
            background: "#f2f2f2",
            borderRadius: "10px",
            fontSize: "30px"
          }}
          sx={{ input: { color: 'black' } }}
          className="wallet-txtfield"
          label={t("email")}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          type="password"
          style={{
            width: "80vw",
            marginTop: "1vh",
            background: "#f2f2f2",
            borderRadius: "10px",
            color: "black",
          }}
          sx={{ input: { color: "black" } }}
          className="wallet-txtfield"
          label={t("password")}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          <button
            className="wallet-user-btn"
            onClick={() => {
              ConnectWallet();
            }}
          >
            {t("connect")}
          </button>
        </div>
        <button
          className="wallet-user-btn-crea"
          onClick={() => {
            createAA();
          }}
        >
          <span style={{color:"black"}}>
           {t("noAccount")}
            &nbsp;&nbsp;
          </span>
           {t("createWallet")}
        </button>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "3vh",
            marginBlock: "2vh"
          }}>
          <div
            style={{
              width: "38%",
              border: "0.5px solid black",
              marginRight: "5px"
            }}
          />
          OR
          <div
            style={{
              width: "38%",
              border: "0.5px solid black",
              marginLeft: "5px"
            }}
          />
        </div>
        <LoginSocialGoogle
          client_id={`${process.env.REACT_APP_GOOGLE}`}
          onLoginStart={onLoginStart}
          scope="openid profile email"
          onResolve={async ({ provider, data }: IResolveParams) => {
            await setProvider(provider);
            await setProfile(data);
            createAccount(data, false);
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <div
          style={{
              width: "73vw",
              marginTop: "0.8vh",
              fontWeight: "bold",
              height: "6vh",
              borderRadius: "10px",
              background: "#f2f2f2",
              color: "black",
              textAlign: "center",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              paddingLeft: 30,
              fontSize: "15px",
              fontFamily: "SF Pro Display Regular",
              border: "1px solid black"
            }}
            >
            <img src={Google} style={{
              width: "30px",
              height: "30px",
              objectFit: "contain",
              overflow: "hidden"
            }} />
            <div style={{marginLeft: "5px"}}>
              {t("google")}
            </div>
          </div>
        </LoginSocialGoogle>
        <LoginSocialFacebook
          appId="335343869454862"
          isOnlyGetToken
          onLoginStart={onLoginStart}
          onResolve={async ({ provider, data }: IResolveParams) => {
            await setProvider(provider);
            await setProfile(data);
            createAccount(data, true);
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <div
          style={{
              width: "73vw",
              marginTop: "0.8vh",
              fontWeight: "bold",
              height: "6vh",
              borderRadius: "10px",
              background: "#f2f2f2",
              color: "black",
              textAlign: "center",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              paddingLeft: 30,
              fontSize: "15px",
              fontFamily: "SF Pro Display Regular",
              border: "1px solid black"
            }}
            >
            <img src={Facebook} style={{
              width: "30px",
              height: "30px",
              objectFit: "contain",
              overflow: "hidden"
            }} />
            <div style={{marginLeft: "5px"}}>
              {t("facebook")}
            </div>
          </div>
        </LoginSocialFacebook>
        <LoginSocialApple
          client_id="335343869454862"
          onResolve={async ({ provider, data }: IResolveParams) => {
            await setProvider(provider);
            await setProfile(data);
            createAccount(data, true);
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <div
          style={{
              width: "73vw",
              marginTop: "0.8vh",
              fontWeight: "bold",
              height: "6vh",
              borderRadius: "10px",
              background: "#f2f2f2",
              color: "black",
              textAlign: "center",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              paddingLeft: 30,
              fontSize: "15px",
              fontFamily: "SF Pro Display Regular",
              border: "1px solid black"
            }}
            >
            <img src={AppleLogo} style={{
              width: "30px",
              height: "30px",
              objectFit: "contain",
              overflow: "hidden"
            }} />
            <div style={{marginLeft: "5px"}}>
              {t("apple")}
            </div>
          </div>
        </LoginSocialApple>
      </div>
    </div>
        {loader}
    </>
  );
};

export default AccountConnect;
