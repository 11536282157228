import React from 'react'

import './Offer.css'
import { useTranslation } from 'react-i18next'

export const CreateCard = () => {
const {t} = useTranslation("offer");

    return <div className='offer-card-create-background'>
        <div className='offer-card-plus'>
            +
        </div>
        <span style={{marginTop: '2vh', color: 'white', fontWeight: 'bold'}}>
            {t('createOffer')}
        </span>
    </div>
}

export default CreateCard