import { useTranslation } from "react-i18next";
import "./Poap.scss";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";

interface RecapPoapProps {
  addrPoap: string;
}

export const RecapPoap = ({ addrPoap }: RecapPoapProps) => {
  const { t } = useTranslation("poap");
  const frontend = process.env.REACT_APP_FRONT || "http://172.20.10.4:3000";
  const urlRedirect = frontend + "/wallet?collec";
  console.log("new poap address :", addrPoap);

  return (
    <>
      <div className="qr-poap-mid">
        <div style={{ minWidth: "20vw", minHeight: "30vh" }}>
          <QRCode
            size={256}
            style={{ width: "90%" }}
            value={`${urlRedirect}=${addrPoap}&type=poap`}
            viewBox={`0 0 256 256`}
          />
        </div>

        <div className="create-poap-footer">
          <Link to="/collections" className="qr-dw-button">
            {t("viewCollection")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default RecapPoap;
