import React, { useEffect, useState } from "react"
import CollectionHeader from "../StepTitle/StepTitle"
import Slider from "./OfferSlider"
import './Offer.css'
import { Detail } from "./OfferDetails"
import SliderNft from "./OfferNftSlider"
import Create from "./OfferCreate"
import useFullPageLoader from "../../hooks/UseLoader"
import axios from "axios"
import { backend } from "../../App"
import { useTranslation } from "react-i18next"
import Popup from "../Popup/Popup"

interface OffersProps {
    nfts: any[]
    poas: any[]
    offers: any[]
    popSnack: any
    setUpdatCollec: any
    jwt: string
    currWallet: any
}

export const Offers = ({ popSnack, nfts, poas, offers, setUpdatCollec, jwt, currWallet}: OffersProps) => {
    const [selectedOffer, setSelected] = useState()
    const [isCreate, setCreate] = useState(false)
    const [page, setPage] = useState(1)
    const [openPopup, setPopup] = useState(false)
    const [isOpened, setOpened] = useState(false)
    const {t} = useTranslation("offer");

  
    const launchPopup = () => {
      setPopup(true)
    } 
    console.log(currWallet)
    useEffect(() => {
        if (!isOpened && currWallet.name == '') {
            launchPopup()
            setOpened(true)
        }
    })

    return (
        <> <Popup open={openPopup} setOpen={setPopup} title={"Please select a wallet"} content={"Please select a wallet in your side bar to continue"}/>
        {currWallet.name !== "" ? <div className="offer-background">
            <div style={{ minWidth: "70vw" }}>
                <CollectionHeader title={t('offerAdmin')} description={page == 1 ? t('select') : page == 2 && isCreate ? t('createOffer') : page == 2 && !isCreate ? t('administrateOffer') : t('selectCollection')} total={3} page={page} />
            </div>
            {page == 1 ? <Slider setPage={setPage} page={page} setCreate={setCreate} offers={offers} setSelected={setSelected} />
                : page == 2 && isCreate ? <Create jwt={jwt} popSnack={popSnack} setSelectedOffer={setSelected} setPage={setPage} page={page} setCreate={setCreate} setUpdatCollec={setUpdatCollec} />
                    : page == 2 && !isCreate ? <Detail page={page} setPage={setPage} offer={selectedOffer} />
                        : <SliderNft jwt={jwt} popSnack={popSnack} setPage={setPage} selectedOffer={selectedOffer} nfts={nfts} poas={poas} />}
        </div> : <></>}</>
    )
}

export default Offers