import { useTranslation } from "react-i18next";
import axios from "axios";
import { backend } from "src/App";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useFullPageLoader from "src/hooks/UseLoader";


interface TransferRequestProps {
  jwt: string;
  currentWallet: any;
  popSnack: any;
}

export const TransferRequest = ({
  jwt,
  currentWallet,
  popSnack
}: TransferRequestProps) => {
  const { t } = useTranslation("administration");
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  interface Request {
    id: number;
    collAddr: string;
    idToken: string;
    walletFrom: string;
    nameFrom: string;
    mailFrom: string;
    walletTo: string;
    nameTo: string;
    mailTo: string;
  }

  const [requestList, setrequestList] = useState<Request[]>([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    axios
      .get(`${backend}/transferauth/list`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API,
          authorization: jwt,
        },
      })
      .then((resp) => {
        setrequestList(resp.data);
        console.log("list", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const acceptRequest = (request: any) => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/nft/sendfrom`,
        {
          contractAddress: request.collAddr,
          wallet: request.walletTo,
          from: request.walletFrom,
          tokenId: request.idToken,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: currentWallet.vault,
          },
        }
      )
      .then((resp) => {
        axios
          .get(
            `${backend}/transferauth/delete/${request.id}`,
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                authorization: jwt,
                vault: currentWallet.vault,
              },
            }
          )
          .then((resp) => {
            if (typeof hideLoader === "function") {
              hideLoader();
            }
            for (let i = 0; i < requestList.length; i++) {
              if (requestList[i].id === request.id) {
                requestList[i] = requestList[requestList.length - 1];
                requestList.pop();
                break;
              }
            }
            setRefresh(!refresh)
            console.log(resp);
          })
          popSnack("the transfer was correctly approved", "success")
      })
      .catch((err) => {
        console.log(err);
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack("an error occured while approving the transfer", "error");
      });
  };

  const popRequest = (() => {

  })

  return (
    <>
      <div className="role-background">
        <div className="role-table">
          <div className="role-table-header" style={{ marginBottom: "3vh" }}>
            <span
              style={{
                minWidth: "17vw",
                textAlign: "start",
                marginLeft: "2.5vw",
              }}
            >
              {t("TokenId")}
            </span>
            <span style={{ minWidth: "22vw", textAlign: "start" }}>
              {t("From")}
            </span>
            <span style={{ minWidth: "22vw", textAlign: "start" }}>
              {t("To")}
            </span>
          </div>
          {requestList && typeof requestList.map == "function" ? (
            requestList.map((elm: any, idx) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    color: "white",
                  }}
                >
                  <div className="role-table-line">
                    <span
                      style={{
                        minWidth: "17vw",
                        maxWidth: "17vw",
                        textAlign: "start",
                        overflow: "hidden",
                      }}
                    >
                      {elm.idToken}
                    </span>
                    <span
                      style={{
                        minWidth: "22vw",
                        maxWidth: "22vw",
                        textAlign: "start",
                        overflow: "hidden",
                      }}
                    >
                      {elm.nameFrom} <br /> {elm.emailFrom}
                    </span>
                    <span
                      style={{
                        minWidth: "22vw",
                        maxWidth: "22vw",
                        textAlign: "start",
                        overflow: "hidden",
                      }}
                    >
                      {elm.nameTo} <br /> {elm.emailTo}
                    </span>
                    <button
                      style={{
                        cursor: "pointer",
                        background: "none",
                        border: "none",
                        color: "white",
                      }}
                      onClick={() => {
                        acceptRequest(elm);
                      }}
                    >
                      <CheckCircleIcon fontSize="large" cursor="pointer" />
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
        
      </div>
      {loader}
    </>
  );
};

export default TransferRequest;
