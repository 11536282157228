import React from "react";
import * as THREE from "three"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import positionsImg from '../../assets/bagsPlaced.png'
// Assets
//@ts-ignore
import venue from "../../assets/gallery.glb"
//@ts-ignore
import newShowroom from "../../assets/models/converted/showroom.glb"
//@ts-ignore
import showroomV2 from "../../assets/models/showroomV4.glb"

//@ts-ignore
import purple from "../../assets/models/purple.glb"
//@ts-ignore
import green from "../../assets/models/green.glb"
//@ts-ignore
import blue from "../../assets/models/blue.glb"
//@ts-ignore
import pink from "../../assets/models/pink.glb"
//@ts-ignore
import diamond from "../../assets/models/diamond.glb"
//@ts-ignore
import blackP from "../../assets/models/blackP.glb"
//@ts-ignore
import pinkP from "../../assets/models/pinkP.glb"
//@ts-ignore
import lv from "../../assets/models/lv2.glb"
//@ts-ignore
import bl from "../../assets/models/channBlack.glb"
//@ts-ignore
import v1 from "../../assets/models/vortex/vortex1.glb"
//@ts-ignore
import v2 from "../../assets/models/vortex/vortex2.glb"
//@ts-ignore
import v3 from "../../assets/models/vortex/vortex4.glb"
//@ts-ignore
import v4 from "../../assets/models/vortex/vortex3.glb"
//@ts-ignore
import crown from "../../assets/models/equipe/fff.glb"
//@ts-ignore
import helmet from "../../assets/models/equipe/zizou.glb"
//@ts-ignore
import sword from "../../assets/models/equipe/teddy.glb"
const width = 1000
const height = 550

interface ShowroomProps {
    nfts: any[]
    positions: any[]
    selectedShowroom: number
}

export const ShowroomRecap = ({positions, nfts, selectedShowroom}: ShowroomProps) => {
  const getNftByNumber = (idNft: number) => {
    let resp = {ar: "", description: "", name: "", image: "", model: "",  promotion: []}
    nfts.map((elm, idx) => {
        const id = Number(elm.name.split('#')[1])
        if (id == idNft) {
            resp = elm
        }
    })
    return resp
}

    var scene3d = document.getElementById("showroom");
    // Scene
    let scene = new THREE.Scene();
    scene.background = new THREE.Color(0xdddddd);

    // Camera
    let camera = new THREE.PerspectiveCamera(65, width / height, .1, 1000);

    // RENDERER
    let renderer = new THREE.WebGLRenderer();
    let controls = {};
    let player = {
        height: -1,
        turnSpeed: .1 ,
        speed: selectedShowroom == 0 ? .4 : 1,
        jumpHeight: .2,
        gravity: .01,
        velocity: 0,
        playerJumps: false
    };
    var manager = new THREE.LoadingManager();
    var loader = new FBXLoader( );
    // loader.load( purple, function( object ) {
    
    // //13.5, -0.5, 6.5
    // object.position.x = 13.5
    // object.position.y = -0.5
    // object.position.z = 6.5
    
    // object.traverse( function ( child ) {
    //     //@ts-ignore
    //     if ( child.isMesh ) {
    //             child.castShadow = true;
    //             child.receiveShadow = true;
    //     }
    // } );

    // object.scale.multiplyScalar(0.1)
    // scene.add( object );
    // render();
    // });


    renderer = new THREE.WebGLRenderer();
    renderer.setClearColor(0x000, 1.0);
    renderer.setSize(width, height);
    window.addEventListener('resize', () => {
        let w = width,
            h = height;       
        renderer.setSize(w, h);
        camera.aspect = w / h;
        camera.updateProjectionMatrix();
    });

    const loadObject = (obj: any, x: any, y: any, z: any, scale: any, rotation: number) => {

        var gltfLoader = new GLTFLoader();
        let example = new THREE.Object3D();
        let returnObj : THREE.Object3D<THREE.Event> = new THREE.Object3D<THREE.Event>
      
        gltfLoader.load(obj, (mesh) => {
      
          example = mesh.scene
          // @ts-ignore
          example.traverse(n => { if ( n.isMesh ) {
            n.castShadow = true; 
            n.receiveShadow = true;
            // @ts-ignore
            if(n.material.map) n.material.map.anisotropy = 16; 
          }});
          example.position.z = z
          example.position.x = x
          example.position.y = y
          example.scale.set(scale, scale, scale)
          if (rotation == 1) {
            example.rotateY(Math.PI * 0.85 )
          }
          else if (rotation == 2) {
            example.rotateY(Math.PI  * 0.25)
          }
          else if (rotation == 3) {
            example.rotateY(Math.PI * 0.5)
          }
          else if (rotation == 4) {
            example.rotateY(Math.PI * 0.7)
          }
          else if (rotation == 5) {
            example.rotateY(Math.PI * 0.4)
          }
          else if (rotation == 6) {
            example.rotateY(Math.PI * 0.3)
          }


          scene.add(example) 
          returnObj = example

        }, undefined, function ( e ) {
          console.log( e );
        } )
        return returnObj
    }
    loadObject(selectedShowroom == 0 ? venue : showroomV2, 0,selectedShowroom == 0 ? 4 : -25, 0,selectedShowroom == 0 ? 1 : 0.5, 0)
    // loadObject(diamond,-43.5, -10.5, 35.5, 10, 0)
    // loadObject(blackP,-96.5, -10.5, -45.5, 0.5, 0)
    // loadObject(pinkP,-68.5, -10.5, 25.5, 0.5, 0)
    //loadObject(lv,-68.5, -10.5, 25.5, 15, 0)


    //  let hemiLight = new THREE.HemisphereLight(0xffeeb1, 0x080820, 4);
    //  scene.add(hemiLight);
     let light = new THREE.SpotLight(0xbedfff,1);
     light.position.set(-50,50,170);
     light.castShadow = true;
     light.shadow.camera.near = 500;
     light.shadow.camera.far = 4000;
     light.shadow.camera.fov = 30;
     scene.add( light );
    //  const geometry = new THREE.BoxGeometry( 10, 10, 10 );
    //   const material = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
    //   const cube = new THREE.Mesh( geometry, material );
    //   cube.position.x = 80
    //   cube.position.y = 50
    //   cube.position.z = 30

    //   scene.add( cube );

     let light2 = new THREE.SpotLight(0xbedfff,1);
     light2.position.set(-105, 50, -100);
     light2.castShadow = true;
     light2.shadow.camera.near = 500;
     light2.shadow.camera.far = 4000;
     light2.shadow.camera.fov = 30;
     scene.add( light2 );

     let light3 = new THREE.SpotLight(0xbedfff,1);
     light3.position.set(105, 50, -100);
     light3.castShadow = true;
     light3.shadow.camera.near = 500;
     light3.shadow.camera.far = 4000;
     light3.shadow.camera.fov = 30;
     scene.add( light3 );

     let light4 = new THREE.SpotLight(0xbedfff,1);
     light4.position.set(80, 50, 30);
     light4.castShadow = true;
     light4.shadow.camera.near = 500;
     light4.shadow.camera.far = 4000;
     light4.shadow.camera.fov = 30;
     scene.add( light4 );

     let light5 = new THREE.SpotLight(0xbedfff,0.5);
     light5.position.set(0, 0, 0);
     light5.castShadow = true;
     light5.shadow.camera.near = 500;
     light5.shadow.camera.far = 4000;
     light5.shadow.camera.fov = 30;
     scene.add( light5 );


    camera.position.set(0, player.height, -5);
    camera.lookAt(new THREE.Vector3(0, player.height, 0));
    const control = () => {
        // Controls:Engine 
        // @ts-ignore
        if(controls[87]){ // w
          camera.position.x -= Math.sin(camera.rotation.y) * player.speed;
          camera.position.z -= -Math.cos(camera.rotation.y) * player.speed;
        }
        // @ts-ignore
        if(controls[83]){ // s
          camera.position.x += Math.sin(camera.rotation.y) * player.speed;
          camera.position.z += -Math.cos(camera.rotation.y) * player.speed;
        }
        // @ts-ignore
        if(controls[65]){ // a
          camera.position.x += Math.sin(camera.rotation.y + Math.PI / 2) * player.speed;
          camera.position.z += -Math.cos(camera.rotation.y + Math.PI / 2) * player.speed;
        }
        // @ts-ignore
        if(controls[68]){ // d
          camera.position.x += Math.sin(camera.rotation.y - Math.PI / 2) * player.speed;
          camera.position.z += -Math.cos(camera.rotation.y - Math.PI / 2) * player.speed;
        }
        // @ts-ignore
        if(controls[37]){ // la
          camera.rotation.y -= player.turnSpeed;
        }        
        // @ts-ignore
        if(controls[39]){ // ra
          camera.rotation.y += player.turnSpeed;
        }
        // @ts-ignore
        if(controls[32]) { // space
        // @ts-ignore
          if(player.jumps) return false;
        // @ts-ignore
          player.jumps = true;
          player.velocity = -player.jumpHeight;
        }
      }
      const ixMovementUpdate = () => {
        player.velocity += player.gravity;
        camera.position.y -= player.velocity;
        
        if(camera.position.y < player.height) {
          camera.position.y = player.height;
                  // @ts-ignore
          player.jumps = false;
        }
      }
      
      const update = () => {
        control();
        ixMovementUpdate();
      }
      
      const render = () => {
        renderer.render(scene, camera);

      }
      const loop = () => {
        requestAnimationFrame(loop);
        update();
        render();
      }
     // loadObject(`${nfts[0].model}?pinataGatewayToken=aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191`,13.5, -0.5, 6.5, 0.5 )
    //loadObject(channel,13.5, -0.5, 6.5, 0.5 )
    positions.map((elm, idx) => {
        if (elm.nft == -1) return
        let modName = ""
        let scale = 1.5
        let modl = pink

        if (nfts[0].name.split(' ')[1] == 'bags') {
          modName = elm.nft == 1 ? "pink" : elm.nft == 2 ? "blue" : elm.nft == 3 ? "purple" : elm.nft == 4 ? "green" : elm.nft == 5 ? "lv" : "channBlack"
          scale = modName == "lv" ? 15 : 1.5
          modl = modName == "pink" ? pink : modName == "blue" ? blue : modName == "purple" ? purple : modName == "green" ? green : modName == "lv" ? lv : bl
        }
        else if (nfts[0].name.split(' ')[0] == 'Vortex') {
          modName = elm.nft == 1 ? "1" : elm.nft == 2 ? "2" : elm.nft == 3 ? "3" : "4" 
          //scale = modName == "lv" ? 15 : 1.5
          modl = modName == "1" ? v1 : modName == "2" ? v2 : modName == "3" ? v3 : v4
        }
        else {
          modName = elm.nft == 1 ? "1" : elm.nft == 2 ? "2" : elm.nft == 3 ? "3" : "4" 
          scale = 3
          modl = modName == "1" ? crown : modName == "2" ? helmet : sword
        }

        console.log(elm.nft, modName)
        if (getNftByNumber(elm.nft).model == "") {

        }
        // Coté ; Hauteur ; Profondeur
        else {
           if (elm.pos == 1) {
                if (selectedShowroom == 0) {
                  loadObject(modl, 11.5, -0.5, 6.5, 0.5, 0 )
                }
                else {
                  loadObject(modl, -21.5, -10.5, 35.5, scale, 1 )
                }
                //loadObject(modl, 11.5, -0.5, 6.5, 0.5 )
           }
           else if (elm.pos == 2){
              if (selectedShowroom == 0) {
                loadObject(modl, 16.5, -0.5, 6.5, 0.5, 0 )
              }
              else {
                loadObject(modl, -43.5, -10.5, 35.5, scale, 2 )
              }
           }
           else if (elm.pos == 3){
              if (selectedShowroom == 0) {
                loadObject(modl, -11.5, -0.5, 6.5, 0.5, 0 )
              }
              else {
                loadObject(modl, -68.5, -10.5, 25.5, scale, 3 )
              }
            }
            else if (elm.pos == 4){
              if (selectedShowroom == 0) {
                loadObject(modl, -16.5, -0.5, 6.5, 0.5, 0 )
              }
              else {
                loadObject(modl, -96.5, -10.5, -45.5, scale, 4 )
              }
            }
            else if (elm.pos == 5){
              if (selectedShowroom == 0) {
                loadObject(modl, -16.5, -0.5, 6.5, 0.5, 0 )
              }
              else {
                loadObject(modl, -112.5, -10.5, -67.5, scale, 5 )
              }
            }
            else if (elm.pos == 6){
              if (selectedShowroom == 0) {
                loadObject(modl, -16.5, -0.5, 6.5, 0.5, 0 )
              }
              else {
                loadObject(modl, -105.5, -10.5, -83.5, scale, 6 )
              }
            }
        }
    })
    // const axesHelper = new THREE.AxesHelper( 5 );
    // scene.add( axesHelper );

    // FINISH SCENE SETUP
                        // @ts-ignore
    document.addEventListener('keydown', ({ keyCode }) => { controls[keyCode] = true });
                            // @ts-ignore
    document.addEventListener('keyup', ({ keyCode }) => { controls[keyCode] = false });
  
    loop()
    // document.body.appendChild(scene3d.domElement);
    scene3d?.appendChild(renderer.domElement);

    return <div /*id='showroom'*/ >
      <img src={positionsImg} style={{maxWidth: '100%'}}/>
    </div>
}

export default ShowroomRecap