import React from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

interface SnackProps {
    open: boolean,
    setOpen: any,
    status: 'error' | 'warning' | 'info' | 'success',
    message: string
}

export const Snack = ({open, setOpen, status, message}: SnackProps ) => {

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity={status} sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
}

export default Snack