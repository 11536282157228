import { useTranslation } from "react-i18next";
import "../../MobileApp.scss";
import CardWallet from "../CardWallet";
import points from "src/assets/passport/points.png";

interface OfferProps {
  offers: any;
  collec: any;
}

export const OfferWallet = ({ offers, collec }: OfferProps) => {
  console.log("offres", offers);
  const { t } = useTranslation("mobileWallet");

  const openlink = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <>
      <div className="wallet-offers">
        {offers.map((elm: any, idx: number) => {
          return (
            <div
              className="wallet-offers-btn"
              onClick={() => openlink(elm.url)}
            >
              <div className="wallet-offers-txt">
                <div className="wallet-offers-txt-title">{elm.name}</div>
                <div className="wallet-offers-txt-desc">{elm.description}</div>
              </div>
              <div style={{display: "flex", justifyContent: "right"}}>
                <img src={points} alt="" style={{width: "10%", marginRight: "12px", marginBottom: "5px"}}/>
              </div>
            </div>
          );
        })}
        {offers.length == 0 ? <p>{t("noPrivilege")}</p> : <></>}
      </div>
    </>
  );
};

export default OfferWallet;
