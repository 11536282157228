import axios from "axios";
import { use } from "i18next";
import { useState } from "react";
import { backend } from "src/App";
import useEffectOnce from "src/hooks/UseEffectOnce";
import CollectionHeader from "../StepTitle/StepTitle";
import TextField from "@mui/material/TextField";
import useFullPageLoader from "src/hooks/UseLoader";
import "./Broadcast.scss";
import { useTranslation } from "react-i18next";

interface BroadcastProps {
  collection: any;
  popSnack: any;
  setCurrent: any;
  jwt: string;
  vaultId: string;
  currWallet: any;
  clientXmtp: any;
}

export const Broadcast = ({
  popSnack,
  collection,
  jwt,
  vaultId,
  clientXmtp,
}: BroadcastProps) => {
  const [owners, setOwners] = useState<any>([]);
  const [msg, setMsg] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { t } = useTranslation("chat");

  useEffectOnce(() => {
    axios
      .post(
        `${backend}/alldatas/broadcast`,
        {
          collec: collection.address,
          type: "product",
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: vaultId,
          },
        }
      )
      .then((resp) => {
        setOwners(resp.data);
      })
      .catch((err) => {
        console.log(err);
        popSnack(
          "An error happened while fecthing the nft owners of the collection",
          "error"
        );
      });
  });

  const sendBroadcast = async () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    try {
      for (let i = 0; i < owners.length; i++) {
        const conversation = await clientXmtp.conversations.newConversation(
          owners[i]
        );
        await conversation.send(msg);
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (typeof hideLoader === "function") {
        hideLoader();
      }
      popSnack(t("BroadcastSuccess"), "success");
    }
  };

  return (
    <>
      <div className="slider-background">
        <div className="slider-head-2">
          <CollectionHeader
            page={2}
            total={2}
            title={t("TitleMsg")}
            description={t("DescMsg")}
          />
        </div>
        <div className="slider-middle">
          <TextField
            className="send-txtfield"
            autoComplete="off"
            variant="outlined"
            label={"Message"}
            style={{
              minWidth: "50vw",
              marginTop: "3vh",
              marginInline: "3vw",
            }}
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
          />
        </div>
        <button
          className="broadcast-button"
          onClick={() => {
            sendBroadcast();
          }}
        >
          {t("SendMsg")}
        </button>
      </div>
      {loader}
    </>
  );
};

export default Broadcast;
