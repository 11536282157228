interface InsuranceProps {}

export const Insurance = ({}: InsuranceProps) => {
  return (
    <>
      <div className="mobileW-account">
        <div className="mobileW-account">
          <p style={{ fontWeight: "bold" }}>
            Because we understand the importance you place on your items, we
            offer you the opportunity to subscribe to insurance through our
            partner. This insurance provides protection for your product against
            any unforeseen circumstances.
          </p>
          <div className="mobileW-insurance">
            <div className="mobileW-insurance-col">
                <div style={{ fontWeight: "bold" }}>Option 1</div>
                <div style={{ fontWeight: "bold", fontSize: "large" }}>BASIC</div>
                <div style={{ fontWeight: "bold", fontSize:"0.6rem" }}>Accidental damage & theft</div>
                <div style={{ fontWeight: "bold", color: "grey", fontSize:"0.6rem" }}>29.99€ per month</div>
            </div>
            <div className="mobileW-insurance-col">
                <div style={{ fontWeight: "bold" }}>Option 2</div>
                <div style={{ fontWeight: "bold", fontSize: "large" }}>OMNIUM</div>
                <div style={{ fontWeight: "bold", fontSize:"0.6rem" }}>Accidental damage, theft, wear and tear</div>
                <div style={{ fontWeight: "bold", color: "grey", fontSize:"0.6rem" }}>49.99€ per month</div>
            </div>
          </div>
          <a
            className="wallet-card-item"
            href="https://www.axa.fr/"
            target="_blank"
          >
            <div className="mobileW-btn-sec">
              <p style={{ padding: "0px", margin: "Opx", marginTop: "0.6vh" }}>
                Subscribe insurance
              </p>
            </div>
          </a>
          <p style={{ fontWeight: "bold" }}>
            A confirmation email will be sent
          </p>
        </div>
      </div>
    </>
  );
};

export default Insurance;
