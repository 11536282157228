import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';

 export const account = atom({
    key: 'account', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
  });