import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "./SendNft.css";
import axios from "axios";
import { backend, GlobalStateContext } from "../../App";
import QRCode from "react-qr-code";
import emailjs from "@emailjs/browser";
import useFullPageLoader from "../../hooks/UseLoader";

interface SendProps {
  variant: "send" | "mint";
  collection: any;
  popSnack: any;
  setCurrent: any;
  jwt: string;
  vaultId: string;
  currWallet: any;
}

const fetchCollectionImagesOwned = async (
  collection: any,
  setImages: any,
  setFetch: any,
  jwt: string,
  vault: string,
  currWallet: any
) => {
  console.log(collection);

  // axios.get(`${backend}/${collection.address}/${walletaddr}`).then((resp) => {
  axios
    .post(
      `${backend}/nft/collection/wallet`,
      { coladdr: collection.address, wallet: currWallet.address },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API,
          authorization: jwt,
          vault: vault,
        },
      }
    )
    .then((resp) => {
      console.log(resp.data);
      setImages(resp.data);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setFetch(true);
    });
};

export const Send = ({
  popSnack,
  variant,
  collection,
  setCurrent,
  jwt,
  vaultId,
  currWallet,
}: SendProps) => {
  let title = "";
  const [nftSelected, setNftSelected] = useState(-1);
  const [nft, setNft] = useState(-1);
  const [nfts, setNfts] = useState<any[]>([]);
  const [fetch, setFetch] = useState(false);
  const [called, setCalled] = useState(false);
  const { t } = useTranslation("sendNft");
  const frontend = process.env.REACT_APP_FRONT || "http://172.20.10.4:3000";
  const urlRedirect = frontend + "/wallet?collec";
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;
  useEffect(() => {
    if (!called) {
      setCalled(true);
      fetchCollectionImagesOwned(
        collection,
        setNfts,
        setFetch,
        jwt,
        globalVar,
        currWallet
      );
    }
  });

  if (variant === "send") {
    title = t("send");
  } else {
    title = t("mint");
  }

  let tmpN = nfts;

  return (
    <>
      {nftSelected != -1 ? (
        <SendCustomer
          vaultId={vaultId}
          jwt={jwt}
          nft={nftSelected}
          addr={collection.address}
          setNft={setNftSelected}
          popSnack={popSnack}
        />
      ) : (
        <div className="send-background">
          <button
            style={{
              color: "#EF19D1",
              background: "none",
              border: "none",
              cursor: "pointer",
              textDecoration: "none",
              alignSelf: "start",
              marginLeft: "5vw",
              marginTop: "15vh",
            }}
            onClick={() => {
              setCurrent(undefined);
            }}
          >
            {"< " + t("back")}
          </button>
          <h2
            style={{
              color: "#078BF9",
              minWidth: "70vw",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            {title}
          </h2>
          <div className="send-middle">
            <div
              className="send-grid-scroll"
              style={{
                overflowY: "scroll",
                marginBottom: "5vh",
                marginTop: "10vh",
                minHeight: "30vh",
              }}
            >
              {fetch ? (
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={3}
                  alignItems="end"
                >
                  {tmpN
                    .sort(
                      (a, b) =>
                        Number(a.name.split("#")[1]) -
                        Number(b.name.split("#")[1])
                    )
                    .map((elm, idx) => {
                      const id = Number(elm.name.split("#")[1]);
                      return (
                        <Grid item key={idx}>
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              console.log(id, elm.name);
                              if (id == nftSelected) setNft(-1);
                              else {
                                setNftSelected(id);
                              }
                            }}
                          >
                            <ImgNft
                              image={elm.image}
                              idx={id}
                              nftSelected={nftSelected}
                              name={elm.name}
                            />
                          </button>
                        </Grid>
                      );
                    })}
                </Grid>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface ImageProps {
  image: string;
  nftSelected: number;
  idx: number;
  name: string;
}

const ImgNft = ({ image, nftSelected, idx, name }: ImageProps) => {
  const [img, setImg] = useState("");
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (img === "") {
      const imageUrl = axios
        .get(
          `${image}?pinataGatewayToken=aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json", //'x-pinata-gateway-token' : 'aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191'
            },
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          //console.log(res)
          const binaryString = Array.from(new Uint8Array(res.data), (v) =>
            String.fromCharCode(v)
          ).join("");
          const theImage = btoa(binaryString);
          // console.log(theImage)
          setImg(theImage);
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => setUpdate(!update));
    }
  });

  return (
    <div className="send-nft-card-background">
      {img === "" ? (
        <>Loading</>
      ) : (
        <img
          src={`data:image/png;base64,${img}`}
          style={{
            marginTop: "2vh",
            maxWidth: "12vw",
            minWidth: "12vw",
            maxHeight: "20vh",
            minHeight: "20vh",
            borderRadius: "10px",
            border:
              nftSelected === idx ? "3px solid #EF19D1" : "3px solid #04152D",
          }}
        />
      )}
      <span
        style={{
          color: "white",
          fontWeight: "bold",
          minWidth: "12vw",
          marginTop: "1vh",
        }}
      >
        {name}
      </span>
    </div>
  );
};

interface CustomerProps {
  nft: number;
  setNft: any;
  popSnack: any;
  addr: string;
  jwt: string;
  vaultId: string;
}

const SendCustomer = ({
  nft,
  setNft,
  popSnack,
  addr,
  jwt,
  vaultId,
}: CustomerProps) => {
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [acc, setAcc] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { t } = useTranslation("sendNft");
  const frontend = process.env.REACT_APP_FRONT || "http://172.20.10.4:3000";
  const urlRedirect = frontend + "/wallet?collec";
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;

  const sendNft = () => {
    if (fName == "" || lName == "" || email == "") {
      popSnack(t("fillInfos"), "warning");
    } else {
      if (typeof showLoader === "function") {
        showLoader();
      }
      axios
        .post(
          `${backend}/nft/send/mail`,
          {
            wallet: acc,
            name: fName + " " + lName,
            email: email,
            contractAddress: addr,
            tokenId: nft,
            vaultId: vaultId,
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API,
              authorization: jwt,
              vault: globalVar,
            },
          }
        )
        .then((resp) => {
          console.log(resp);
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack(t("nftSent"), "success");
        })
        .catch((err) => {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack(t("nftError"), "error");
          console.log(err);
        });
    }
  };

  const sendEmail = () => {
    if (email == "") {
      popSnack(t("fillEmail"), "warning");
      return;
    }
    var templateParams = {
      to_name: fName + " " + lName,
      from_name: "Hermès Customer Service",
      link_to: `${frontend}/wallet?collec=${addr}&idNFT=${nft}`,
      to_email: email,
    };

    if (typeof showLoader === "function") {
      showLoader();
    }
    emailjs
      .send(
        "NFT_Experience",
        "template_iywots5",
        templateParams,
        "dI1NV4PWOS0ci1eLZ"
      )
      .then(
        function (response: any) {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack(t("emailSent"), "success");
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error: any) {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack(t("emailError"), "error");
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <>
      <div className="send-background">
        <button
          style={{
            color: "#EF19D1",
            background: "none",
            border: "none",
            cursor: "pointer",
            textDecoration: "none",
            alignSelf: "start",
            marginLeft: "5vw",
            marginTop: "15vh",
          }}
          onClick={() => {
            setNft(-1);
          }}
        >
          {"< " + t("back")}
        </button>
        <h2
          style={{
            color: "#078BF9",
            minWidth: "70vw",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {t("customerInfos")}
        </h2>
        <div className="send-user-info-middle">
          <div
            className="send-form-background"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("firstName")}
              style={{ minWidth: "22vw", marginTop: "3vh" }}
              value={fName}
              onChange={(e) => setfName(e.target.value)}
            />
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("lastName")}
              style={{ minWidth: "22vw", marginTop: "1vh" }}
              value={lName}
              onChange={(e) => setlName(e.target.value)}
            />
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("email")}
              style={{ minWidth: "22vw", marginTop: "1vh" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <button
              style={{
                cursor: "pointer",
                minWidth: "22vw",
                minHeight: "5vh",
                backgroundColor: "#EF19D1",
                border: "none",
                color: "white",
                fontWeight: "bold",
                borderRadius: "20px",
                alignSelf: "center",
                marginBottom: "2vh",
              }}
              onClick={sendNft}
            >
              {t("sendWallet")}
            </button>
          </div>
          <div
            className="send-form-background"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                minWidth: "25vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  maxWidth: "60%",
                  width: "60%",
                  marginTop: "5%",
                }}
                value={`${urlRedirect}=${addr}&idNFT=${nft}`}
                viewBox={`0 0 256 256`}
              />
            </div>
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label="Email address"
              style={{ minWidth: "22vw", marginTop: "3vh" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              style={{
                cursor: "pointer",
                minWidth: "22vw",
                minHeight: "5vh",
                backgroundColor: "#EF19D1",
                border: "none",
                color: "white",
                fontWeight: "bold",
                borderRadius: "20px",
                alignSelf: "center",
                marginBottom: "2vh",
              }}
              onClick={sendEmail}
            >
              {t("sendEmail")}
            </button>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default Send;
