import React, { useEffect, useState } from "react";
import Card from "../CollectionCard/ChooseCard";
import Showroom from "../ExposeShowroom/ExposeShowroom";
import CollectionHeader from "../StepTitle/StepTitle";
import Send from "../SendNft/SendNft";
import parentAbi from "../../abis/parentAbi.json";

import "./ChooseCollection.css";
import { useTranslation } from "react-i18next";
import Popup from "../Popup/Popup";

interface ChooseProps {
  nfts: any[];
  redirect: "showroom" | "send";
  popSnack: any;
  jwt: string;
  currWallet: any;
}

export const Choose = ({
  popSnack,
  nfts,
  redirect,
  jwt,
  currWallet,
}: ChooseProps) => {
  const [openPopup, setPopup] = useState(false);
  const [isOpened, setOpened] = useState(false);

  const launchPopup = () => {
    setPopup(true);
  };
  console.log(currWallet);
  useEffect(() => {
    if (!isOpened && currWallet.name == "") {
      launchPopup();
      setOpened(true);
    }
  });
  return (
    <>
      {" "}
      <Popup
        open={openPopup}
        setOpen={setPopup}
        title={"Please select a wallet"}
        content={"Please select a wallet in your side bar to continue"}
      />
      {currWallet.name !== "" ? (
        <div className="choose-background">
          <Slider
            vaultId={currWallet.vault}
            jwt={jwt}
            popSnack={popSnack}
            nfts={nfts}
            redirect={redirect}
            currWallet={currWallet}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

interface SliderProps {
  nfts: any[];
  redirect: "showroom" | "send";
  popSnack: any;
  jwt: string;
  vaultId: string;
  currWallet: string;
}

export const Slider = ({
  popSnack,
  nfts,
  redirect,
  jwt,
  vaultId,
  currWallet,
}: SliderProps) => {
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [current, setCurrent] = useState<any>();
  const { t } = useTranslation("chooseCollection");

  if (nfts.length >= 1 && isLoading) {
    setLoading(false);
  }

  const numberOfPages = Math.ceil(nfts.length / 3);
  let circles = [];

  for (let index = 0; index < numberOfPages; index++) {
    if (index + 1 === currentPage) {
      circles.push(<Circle fill={false} key={index} />);
    } else {
      circles.push(<Circle fill key={index} />);
    }
  }

  return (
    <>
      {current == undefined ? (
        <div className="slider-background">
          {nfts.length < 1 ? (
            <>Loading</>
          ) : (
            <>
              <div className="slider-head-2">
                <CollectionHeader
                  page={1}
                  total={redirect == "showroom" ? 4 : 2}
                  title={redirect == "showroom" ? t("expose") : t("sendNft")}
                  description={t("chooseCollection")}
                />
                <div>
                  <button
                    className="slider-buttons"
                    style={{ marginRight: "1vw" }}
                    onClick={() => {
                      if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1);
                    }}
                  >
                    {"<"}
                  </button>
                  <button
                    className="slider-buttons"
                    onClick={() => {
                      if (currentPage + 1 <= numberOfPages)
                        setCurrentPage(currentPage + 1);
                    }}
                  >
                    {">"}
                  </button>
                </div>
              </div>
              <div className="slider-middle">
                {!isLoading ? (
                  nfts.map((elm, idx) => {
                    return (
                      <CardWindow
                        idx={idx}
                        setCurrent={setCurrent}
                        elm={elm}
                        currentPage={currentPage}
                      />
                    );
                  })
                ) : (
                  <>{t("loading")}</>
                )}
              </div>
              <div className="slider-foot">{circles}</div>
            </>
          )}
        </div>
      ) : redirect == "send" ? (
        <Send
          vaultId={vaultId}
          jwt={jwt}
          popSnack={popSnack}
          variant="send"
          setCurrent={setCurrent}
          collection={current}
          currWallet={currWallet}
        />
      ) : (
        <Showroom popSnack={popSnack} nftsArray={current} />
      )}
    </>
  );
};

interface CardWindowProps {
  idx: number;
  setCurrent: any;
  elm: any;
  currentPage: number;
}

const CardWindow = ({ idx, setCurrent, elm, currentPage }: CardWindowProps) => {
  return (
    <>
      {Math.ceil((idx + 1) / 3) === currentPage ? (
        <button
          onClick={() => {
            setCurrent(elm);
          }}
          style={{ border: "none", background: "none" }}
        >
          {<Card key={idx} collection={elm} idx={idx} isPoa={false} />}
        </button>
      ) : (
        <></>
      )}
    </>
  );
};

interface CircleProps {
  fill: boolean;
}

const Circle = ({ fill }: CircleProps) => {
  return <div className={fill ? "circle-filled" : "circle-not-filled"} />;
};

export default Choose;
