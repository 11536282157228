import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { backend, GlobalStateContext } from "../../App";

import "./Customers.css";
import { useTranslation } from "react-i18next";

interface TableProps {
  selected: number;
  setSelected: any;
  setUser: any;
  jwt: string;
}

export const Table = ({ selected, setSelected, setUser, jwt }: TableProps) => {
  const [isFetched, setFetched] = useState(false);
  //const users = [{name: "Jean Dupont", email: "jean.dupont@gmail.com", wallets: ["0xC7592cb72a8B10A2736062611c050Cbfa6487aB8"]}, {name: "Jean Dupont", email: "jean.dupont@gmail.com", wallets: ["0xC7592cb72a8B10A2736062611c050Cbfa6487aB8"]}]
  const [users, setUsers] = useState<
    { email: string; id: number; name: string; wallets: string[] }[]
  >([]);
  const [search, setSearch] = useState<
    { email: string; id: number; name: string; wallets: string[] }[]
  >([]);
  const [searchVal, setSearchVal] = useState("");
  const { t } = useTranslation("customers");
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;
  const getUsers = () => {
    axios
      .get(`${backend}/client/all`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API,
          authorization: jwt,
          vault: globalVar,
        },
      })
      .then((resp) => {
        setUsers(resp.data);
        setFetched(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!isFetched) {
      getUsers();
    }
  });

  return (
    <div>
      <div
        style={{
          minWidth: "65vw",
          display: "flex",
          justifyContent: "start",
          marginBottom: "3vh",
        }}
      >
        <input
          placeholder={t("search")}
          value={searchVal}
          onChange={(e) => {
            setSearchVal(e.target.value);
            console.log(
              users.filter((elm) => elm.name.includes(e.target.value))
            );
            setSearch(users.filter((elm) => elm.name.includes(e.target.value)));
          }}
        />
      </div>
      <div className="customer-table-v2">
        <div className="customer-table-header" style={{ marginBottom: "3vh" }}>
          <span style={{ minWidth: "21vw", textAlign: "start" }}>
            {t("name")}
          </span>
          <span style={{ minWidth: "22vw", textAlign: "start" }}>
            {t("email")}
          </span>
          <span style={{ minWidth: "22vw", textAlign: "end" }}>
            {t("walletAddress")}
          </span>
        </div>
        {search.map((elm, idx) => {
          return (
            <div
              className="customer-table-line"
              onClick={() => {
                setSelected(elm.id);
                setUser(elm);
              }}
            >
              <span
                style={{
                  minWidth: "21vw",
                  maxWidth: "21vw",
                  textAlign: "start",
                  overflow: "hidden",
                }}
              >
                {elm.name}
              </span>
              <span
                style={{
                  minWidth: "22vw",
                  maxWidth: "22vw",
                  textAlign: "start",
                  overflow: "hidden",
                }}
              >
                {elm.email}
              </span>
              <span
                style={{
                  minWidth: "22vw",
                  maxWidth: "22vw",
                  textAlign: "end",
                  overflow: "hidden",
                }}
              >
                {elm.wallets[0]}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Table;
