import React, { useState } from "react";

import Nft from '../CollectionCard/NftCard'
import Grid from '@mui/material/Grid';


import './CreateCollection.css'
import { useTranslation } from "react-i18next";
import axios from "axios";

interface UploadProps {
    nfts: {name: string, image: string, model: string}[],
    setNfts: any
    handleClick: any
    addFile: any
    fileInput: any
    numsSerie: string[]
    setSeries: any 
    jwt: string
    setCsv: any
}

export const Upload = ({setCsv ,nfts, setNfts, addFile, handleClick, fileInput, numsSerie, setSeries, jwt}: UploadProps) => {
  const [file, setFile] = useState<any>(null);
  const hiddenFileInput = React.useRef(null);
  const handleClickInput = () => {
      if (hiddenFileInput.current !== null)
      // @ts-ignore
      hiddenFileInput.current.click();
  };
  const onFileChange = (e: any) => {
      setFile(e.target.files[0]);
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      setCsv(true)
      axios.post('http://localhost:5002/nft/csv', formData)
          .then(response => {
              console.log('Réponse du serveur :', response.data);
          })
          .catch(error => {
              console.error('Erreur lors de l\'envoi du fichier :', error);
          });
  };

    return <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={3} sm={4} md={4} key={999} display="flex" justifyContent="center" alignItems="center">
        <CreateCard addFile={addFile} handleClick={handleClick} hiddenFileInput={fileInput}/>
        <button style={{cursor: "pointer", border: "none" ,minWidth: '14vw', minHeight: '60%', background: "radial-gradient(#1A223D, #078BF9)", borderRadius: 10}} onClick={handleClickInput}>
            <input ref={hiddenFileInput} type="file" onChange={onFileChange} style={{display: "none"}}/>
        </button>
      </Grid>
    {nfts.map((elm, index) => (
      <Grid item xs={2} sm={4} md={4} key={index}>
        <Nft jwt={jwt} name={elm.name} image={elm.image} series={numsSerie} nfts={nfts} setNfts={setNfts} idx={index} numSerie={numsSerie[index]} setSerie={setSeries}/>
      </Grid>
    ))}
  </Grid>
}

interface CreateCardProps {
  addFile: any
  handleClick: any
  hiddenFileInput: any
}


export const CreateCard = ({addFile, handleClick, hiddenFileInput}: CreateCardProps) => {
  const {t} = useTranslation("createCollection")
    return <button className='upload-card-create-background' onClick={handleClick} style={{marginLeft: "20vw"}}>
        <input ref={hiddenFileInput} type="file" name="file" style={{display: "none"}} onChange={(e) => addFile(e)}/>
        <div className='upload-card-plus'>
            +
        </div>
        <span style={{ color: 'white', fontWeight: 'bold'}}>
            {t('addImage')}
        </span>
    </button>
}


export default Upload