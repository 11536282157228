import axios from "axios";
import { useEffect, useState } from "react";
import "../MobileApp.scss";
import "./MobileWallet.scss";
import lost from "src/assets/mobileIcon/productLost.png";

interface CardWalletProps {
  collection: any;
  image: string;
  toptxt: boolean;
  islost: boolean;
}

const CardWallet = ({
  collection,
  image,
  toptxt,
  islost,
}: CardWalletProps) => {
  const [isLoaded, setLoaded] = useState(true);
  const [imgDisplay, setImg] = useState("");
  const [imgReady, setReady] = useState("");

  // pb avec la propriété uri dans le json product, pas de champs image
  useEffect(() => {
    let img = image;
    if (image == "") {
      img = collection.image;
      if (collection.image == undefined) {
        img = collection.nfts[0].image; // Corriger ca pour plus tard
      }
    }
    setImg(img);
    
    if (imgDisplay != "") {
      axios
        .get(`${imgDisplay}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          responseType: "arraybuffer",
        })
        .then((res) => {
          const binaryString = Array.from(new Uint8Array(res.data), (v) =>
            String.fromCharCode(v)
          ).join("");
          const theImage = btoa(binaryString);
          setReady(theImage);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoaded(true));
    }
  }, [imgDisplay]);

  return (
    <>
      {isLoaded ? (
        <Card
          name={collection.name}
          image={imgReady}
          toptxt={toptxt}
          islost={islost}
        />
      ) : (
        <> </>
      )}
    </>
  );
};

interface CardProps {
  name: string;
  image: string;
  toptxt: boolean;
  islost: boolean;
}

const Card = ({ name, image, toptxt, islost }: CardProps) => {
  return (
    <div className="wallet-card-item">
      {toptxt ? <div className="mobileWallet-card-text" style={{marginBottom: "5px", fontWeight: "bold", fontSize:'larger'}}>{name}</div> : <></>}
      <div className="wallet-img-bg">
        {!islost ? (
          <></>
        ) : (
          <div className="card-sphere-nfts" style={{background: "#ec4a4a"}}>
            <img src={lost} alt="" style={{width: "50%", marginTop: "8px"}}/>
          </div>
        )}
        <img
          src={`data:image/png;base64,${image}`}
          className="wallet-card-image"
        />
      </div>
      {toptxt ? <></> : <div className="mobileWallet-card-text">{name}</div>}
    </div>
  );
};

export default CardWallet;
