import './FullPageLoader.css'
import { FillingBottle } from "react-cssfx-loading";


const FullPageLoader = () => {
    return (
        <div className="fpl-container">
            <FillingBottle color="#EF19D1" className="fpl-loader"/>
        </div>
    );
};

export default FullPageLoader;