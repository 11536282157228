import React, { useContext, useEffect, useState } from "react";
import PageBlockchain from "./Blockchain";
import Details from "./Details";
import Next from "./NextButton";
import Upload from "./Upload";
import Recap from "./Recap";
import { backend, GlobalStateContext } from "../../App";
import CircularProgress from "@mui/material/CircularProgress";
import {AddCsv} from "./Blockchain"

import "./CreateCollection.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Popup from "../Popup/Popup";

interface CreateProps {
  setCollections: any;
  popSnack: any;
  setUpdatCollec: any;
  jwt: string;
  currWallet: any;
}

export const CreateCollection = ({currWallet, popSnack, setCollections, setUpdatCollec, jwt}: CreateProps) => {
    const [blockchain, setBlockchain] = useState('')
    const [page, setPage] = useState(2)
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [symb, setSymb] = useState('')
    const [price, setPrice] = useState('')
    const [nbElms, setElms] = useState('')
    const [nfts, setNfts] = useState<{name: string, image: any, model: any, certif: any | undefined}[]>([])//[{name: "Moonlanderz #4142", image: mlz, model: "" }])
    const [update, setUpdate] = useState(false)
    const [hashs, setHashs] = useState({img: "", mdl: "", metas: ""})
    const [isRecapReady, setRecapReady] = useState(false)
    const [step, setStep] = useState(0)
    const [loader, setLoader] = useState(false)
    const [numsSerie, setSerie] = useState<string[]>([])
    console.log(numsSerie)
    const [openPopup, setPopup] = useState(false)
    const [openPopupSuccess, setPopupSuccess] = useState(false)

    const [isOpened, setOpened] = useState(false)
    const [isCsv, setCsv] = useState(false)
    console.log("PAGE",page)
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error("MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider");
    }
    const { globalVar, setGlobalVar } = context;

  const launchPopup = () => {
    setPopup(true);
  };
  console.log(currWallet);
  useEffect(() => {
    if (!isOpened && currWallet.name == "") {
      launchPopup();
      setOpened(true);
    }
  });

  const getImgHash = async () => {
    axios
      .post(`${backend}/files/pin/imgs`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API,
          authorization: jwt,
          vault: globalVar,
        },
      })
      .then((resp) => {
        const tmp = hashs;
        tmp.img = resp.data.IpfsHash;
        setHashs(tmp);
        setStep(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMdlHash = async () => {
    axios
      .post(`${backend}/files/pin/models`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API,
          authorization: jwt,
          vault: globalVar,
        },
      })
      .then((resp) => {
        const tmp = hashs;
        tmp.mdl = resp.data.IpfsHash;
        setHashs(tmp);
        setStep(2);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMetasHash = async () => {
    axios
      .post(
        `${backend}/files/pin/metas`,
        {
          name: name,
          description: desc,
          nbElm: Number(nbElms),
          imgUri: hashs.img,
          mdlUri: hashs.mdl,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        const tmp = hashs;
        tmp.metas = resp.data.IpfsHash;
        setHashs(tmp);
        setRecapReady(true);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pushAll = async () => {
    setLoader(true);
    axios
      .post(
        `${backend}/files/pin/all`,
        {
          name: name,
          description: desc,
          nbElm: Number(nbElms),
          numSeries: numsSerie,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        const tmp = hashs;
        console.log(resp.data);
        tmp.metas = resp.data.metas.IpfsHash;
        tmp.img = resp.data.images.IpfsHash;
        tmp.mdl = resp.data.images.IpfsHash;
        setHashs(tmp);
        setRecapReady(true);
        setPage(page + 1);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

//   const pushAll = async () => {
//     let uri = isCsv ? "/deploy/mint/csv" : "/pin/all"
//     setLoader(true)
//     axios.post(`${backend}${uri}`, {name: name, description: desc, nbElm: Number(nbElms), numSeries: numsSerie}, {headers: {"x-api-key": process.env.REACT_APP_API, "authorization": jwt, "vault": globalVar}}).then((resp) => {
//             const tmp = hashs
//             console.log(resp.data)
//             tmp.mdl = resp.data.images.IpfsHash
//             tmp.img = resp.data.models.IpfsHash
//             tmp.metas = resp.data.metas.IpfsHash
//             setHashs(tmp)
//             setRecapReady(true)
//             setPage(page + 1)
//             setLoader(false)
//           }).catch((err) => {
//             console.log(err)
//     })
// }
const handleMetas = async () => {
    getImgHash()
      .then((resp) => {
        getMdlHash().then(() => {
          getMetasHash().then(() => {
            setRecapReady(true);
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    if (hiddenFileInput.current !== null)
      // @ts-ignore
      hiddenFileInput.current.click();
  };

  const addFile = async (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let tmp = nfts;
      let data = new FormData();
      data.append("file", file, file.name);
      axios
        .post(`${backend}/files/upload/images/${tmp.length + 1}`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data`,
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
          },
        })
        .then((resp) => {
          tmp.push({
            name: name + ` #${tmp.length + 1}`,
            image: URL.createObjectURL(e.target.files[0]),
            model: "",
            certif: "",
          });
          setNfts(tmp);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setUpdate(!update);
        });
    }
  };

    return <div className={page == 4 ? "create-collection-background-recap" : "create-collection-background"}>
        <Popup open={openPopup} setOpen={setPopup} title={"Please select a wallet"} content={"Please select a wallet in your side bar to continue"}/>
        <Popup open={openPopupSuccess} setOpen={setPopupSuccess} title={"Collections are being created"} content={"The collections you submitted are being created"}/>

        {currWallet.name !== "" ? <><div style={{minWidth: "70vw"}}>
            <CollectionHeader page={page}/>
        </div>
        {isCsv ? <AddCsv /> : <div className={`create-mid-${page}`}>
            {page === 1 ? <PageBlockchain page={page} setPage={setPage} setBlockchain={setBlockchain}/> : page === 2 ?
            <Details name={name} setName={setName} 
            blockchain={blockchain}
            desc={desc} setDesc={setDesc}
            symb={symb} setSymb={setSymb}
            price={price} setPrice={setPrice}
            nbElms={nbElms} setElms={setElms}
            setCsv={setCsv}
            />
            : page === 3 ? <Upload setCsv={setCsv} jwt={jwt} numsSerie={numsSerie} setSeries={setSerie} fileInput={hiddenFileInput} nfts={nfts} setNfts={setNfts} handleClick={handleClick} addFile={addFile}/> : <Recap isCsv={isCsv} nfts={nfts} numSeries={numsSerie} popSnack={popSnack} setCollections={setCollections} collection={{name: name, desc: desc, symb: symb, price: price, nbElms: nbElms, baseUri: hashs.metas, nfts: nfts}} setUpdatCollec={setUpdatCollec} jwt={jwt}/>
            }
        </div>}
        {isCsv ? <></> :<div className={page == 4 ? "" : "create-foot"}>
            {page === 1 ? <></> : page === 4 ? <></> : page == 5 ? <PushButton loader={loader} pushAll={pushAll} /> :  <Next handleMetas={handleMetas} isRecapReady={isRecapReady} page={page} setPage={setPage}/>}
        </div>}</> : <></>}
    </div>
} 

interface CollectionHeaderProps {
  page: number;
}

const CollectionHeader = ({ page }: CollectionHeaderProps) => {
  const { t } = useTranslation("createCollection");
  let title =
    page === 1
      ? t("selectBC")
      : page === 2
      ? t("details")
      : page === 3
      ? t("upload")
      : t("recap");

    return <div className="create-head">
        <h2 style={{color: '#078BF9'}}>
            {t('createNFTCollection')}
        </h2>
        <span style={{color: '#e2e2e2'}}>
            {`${page - 1}/3 - ${title}`}
        </span>
    </div>
  ;
};

interface PushProps {
  pushAll: any;
  loader: boolean;
}

const PushButton = ({ loader, pushAll }: PushProps) => {
  const { t } = useTranslation("createCollection");
  let txt = t("publish");
  const [update, setUpdate] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <button
        style={{
          background: "#078BF9",
          border: "none",
          cursor: "pointer",
          color: "white",
          minHeight: "5vh",
          minWidth: "10vw",
          borderRadius: "50px",
        }}
        onClick={
          !loader
            ? () => {
                pushAll().then(() => {
                  setUpdate(!update);
                });
              }
            : () => {}
        }
      >
        {loader ? (
          <CircularProgress sx={{ color: "white", maxWidth: "3vw" }} />
        ) : (
          txt
        )}
      </button>
    </div>
  );
};

export const CreateCard = () => {
  return (
    <div className="upload-card-create-background">
      <div className="upload-card-plus">+</div>
      <span style={{ color: "white", fontWeight: "bold" }}>Add an image</span>
    </div>
  );
};

export default CreateCollection;
