import React, { useContext, useEffect, useState } from "react";
import "./Role.css";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@mui/material";
import axios from "axios";
import { backend, GlobalStateContext } from "src/App";
import { useTranslation } from "react-i18next";

interface RoleProps {
  jwt: string;
}

export const Role = ({ jwt }: RoleProps) => {
  const [roles, setRoles] = useState<
    { role: string; description: string; permissions: string }[]
  >([]);
  const [isPopup, setPopup] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [permissions, setPermissions] = useState("");
  const [permissionList, setPermList] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const context = useContext(GlobalStateContext);
  const { t } = useTranslation("administration");

  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;

  const addRole = () => {
    axios
      .post(
        isEdit ? `${backend}/admin/role/update` : `${backend}/admin/role`,
        { role: name, description: description, permissions: permissions },
        { headers: { authorization: jwt, vault: globalVar } }
      )
      .then((resp) => {
        setPopup(false);
        setRoles([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPermissions = () => {
    axios
      .get(`${backend}/admin/permissions`, {
        headers: { authorization: jwt, vault: globalVar },
      })
      .then((resp) => {
        setPermList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRoles = () => {
    axios
      .get(`${backend}/admin/roles`, {
        headers: { authorization: jwt, vault: globalVar },
      })
      .then((resp) => {
        setRoles(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (permissionList.length == 0) {
      getPermissions();
    }
    if (roles.length == 0) {
      getRoles();
    }
  });

  return (
    <div className="role-background">
      {isPopup ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: "80vw",
            height: "100vh",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "white",
              opacity: "0.3",
              zIndex: 1,
            }}
          ></div>
          <div style={{ zIndex: 2 }}>
            <PopupRole
              edit={isEdit}
              setEdit={setEdit}
              addRole={addRole}
              permissionList={permissionList}
              setPopup={setPopup}
              permissions={permissions}
              setPermissions={setPermissions}
              name={name}
              setName={setName}
              description={description}
              setDescription={setDescription}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="role-table">
        <div className="role-table-header" style={{ marginBottom: "3vh" }}>
          <span
            style={{
              minWidth: "17vw",
              textAlign: "start",
              marginLeft: "2.5vw",
            }}
          >
            {t("Role")}
          </span>
          <span style={{ minWidth: "22vw", textAlign: "start" }}>
            {t("Description")}
          </span>
          <span style={{ minWidth: "17vw", textAlign: "start" }}>
            {t("Permissions")}
          </span>
          <div
            onClick={() => {
              setPopup(true);
            }}
            style={{
              cursor: "pointer",
              marginLeft: "8vw",
              width: "1.5vw",
              height: "1.5vw",
              textAlign: "end",
              borderRadius: "50%",
              border: "1px solid #EF19D1",
              color: "#EF19D1",
              display: "flex",
              justifyContent: "center",
            }}
          >
            +
          </div>
        </div>
        {roles.map((elm, idx) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                color: "white",
              }}
            >
              <div className="role-table-line">
                <span
                  style={{
                    minWidth: "17vw",
                    maxWidth: "17vw",
                    textAlign: "start",
                    overflow: "hidden",
                  }}
                >
                  {elm.role}
                </span>
                <span
                  style={{
                    minWidth: "22vw",
                    maxWidth: "22vw",
                    textAlign: "start",
                    overflow: "hidden",
                  }}
                >
                  {elm.description}
                </span>
                <span
                  style={{
                    minWidth: "17vw",
                    maxWidth: "17vw",
                    textAlign: "start",
                    overflow: "hidden",
                  }}
                >
                  {elm.permissions}
                </span>
              </div>
              <button
                style={{
                  cursor: "pointer",
                  background: "none",
                  border: "none",
                  color: "white",
                }}
                onClick={() => {
                  setName(elm.role);
                  setDescription(elm.description);
                  setPermissions(elm.permissions);
                  setPopup(true);
                  setEdit(true);
                }}
              >
                <EditIcon />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface PopupProps {
  setPopup: any;
  description: string;
  setDescription: any;
  name: string;
  setName: any;
  permissions: string;
  setPermissions: any;
  permissionList: any[];
  addRole: any;
  setEdit: any;
  edit: boolean;
}

const PopupRole = ({
  edit,
  setEdit,
  addRole,
  setPopup,
  description,
  name,
  permissions,
  setDescription,
  setName,
  setPermissions,
  permissionList,
}: PopupProps) => {
  let permissionsArr = permissions.split(";");
  const { t } = useTranslation("administration");

  const removePerm = (code: string) => {
    let newArr: string[] = [];
    permissionsArr.map((elm) => {
      if (elm !== code) {
        newArr.push(elm);
      }
    });
    setPermissions(newArr.join(";"));
  };

  const addPerm = (code: string) => {
    permissionsArr.push(code);
    setPermissions(permissionsArr.join(";"));
  };

  return (
    <div className="add-role-popup">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          color: "white",
          height: "10vh",
        }}
      >
        <div style={{ flex: 1 }} />
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
            {" "}
            {edit ? t("EditRole") : t("CreateRole")}{" "}
          </span>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CloseIcon
            fontSize="large"
            cursor="pointer"
            onClick={() => {
              setPopup(false);
              setEdit(false);
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          marginTop: "1vh",
          flexDirection: "column",
        }}
      >
        <span
          style={{ color: "white", fontWeight: "bold", marginBottom: "1.5vh" }}
        >
          {t("Code")}
        </span>
        <TextField
          disabled={edit}
          autoComplete="off"
          variant="outlined"
          label=""
          style={{
            minWidth: "25vw",
            border: "green",
            backgroundColor: "#1A223D",
          }}
          className="txtfield"
          value={name}
          onChange={(e: any) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          marginTop: "2vh",
          flexDirection: "column",
        }}
      >
        <span
          style={{ color: "white", fontWeight: "bold", marginBottom: "1.5vh" }}
        >
          {t("Description")}
        </span>
        <TextField
          autoComplete="off"
          variant="outlined"
          inputProps={{ style: { color: "white" } }}
          multiline
          rows={3}
          label=""
          style={{ minWidth: "90%", backgroundColor: "#1A223D" }}
          className="txtfield"
          value={description}
          onChange={(e: any) => {
            setDescription(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          marginTop: "2vh",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              marginBottom: "1.5vh",
            }}
          >
            {t("Permissions")}
          </span>
          <div
            onClick={() => {
              //setPopup(true)
            }}
            style={{
              cursor: "pointer",
              marginLeft: "2vw",
              width: "1.5vw",
              height: "1.5vw",
              textAlign: "end",
              borderRadius: "50%",
              border: "1px solid #EF19D1",
              color: "#EF19D1",
              display: "flex",
              justifyContent: "center",
              marginBottom: "1.5vh",
            }}
          >
            +
          </div>
        </div>
        <div
          className="permissions-tab"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#1A223D",
            width: "90%",
            height: "15vh",
            overflow: "scroll",
            padding: "2vh 0",
          }}
        >
          {typeof permissionList.map == "function" ? (
            permissionList.map((elm, idx) => {
              let isTicked = false;
              permissionsArr.map((perm, idx) => {
                if (elm.code == perm) isTicked = true;
              });
              return (
                <div
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: idx == 0 ? "5vh" : 0,
                  }}
                >
                  <Checkbox
                    checked={isTicked}
                    onChange={() => {
                      if (isTicked) {
                        removePerm(elm.code);
                      } else {
                        addPerm(elm.code);
                      }
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "white",
                      },
                    }}
                  />
                  <span style={{ color: "white" }}>
                    {elm.description} - {elm.code}
                  </span>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>

        <button
          onClick={() => {
            addRole();
          }}
          style={{
            cursor: "pointer",
            width: "100%",
            height: "5vh",
            backgroundColor: "#EF19D1",
            border: "none",
            borderRadius: "50px",
            marginTop: "2vh",
            color: "white",
            fontSize: "1em",
            fontWeight: "bold",
          }}
        >
          {t("Validate")}
        </button>
      </div>
    </div>
  );
};

export default Role;
