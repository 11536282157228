import "../MobileApp.scss";
import CardWallet from "./CardWallet";

interface CollectionsWalletProps {
  setPage: any;
  collections: any;
  setCollec: any;
  setOffers: any;
  setColAddr: any;
  setnft: any;
}

export const CollectionsWallet = ({
  setPage,
  collections,
  setnft,
  setCollec,
  setOffers,
  setColAddr,
}: CollectionsWalletProps) => {

  const setProduct = (elm: any) => {
    setCollec(elm);
    setnft(elm.nfts[0])
    setColAddr(elm.address);
    setOffers(elm.offers);
    setPage(2);
  };

  return (
    <>
      <div className="wallet-card-middle">
        {collections.map((elm: any, idx: number) => {
            return (
              <div key={idx}>
                <button
                  onClick={() => setProduct(elm)}
                  style={{ border: "none", background: "none", margin: "0px", padding: "0px" }}
                >
                  <CardWallet
                    key={idx}
                    collection={elm}
                    image={""}
                    toptxt={false}
                    islost={elm.nfts[0].lost}
                  />
                </button>
              </div>
            );
        })}
      </div>
    </>
  );
};

export default CollectionsWallet;
