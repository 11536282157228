import collector from "src/assets/mobileIcon/collector.png";
import vinted from "src/assets/mobileIcon/vinted.png";
import real from "src/assets/mobileIcon/therealreal.png";
import vestiaire from "src/assets/mobileIcon/vestiaireCol.webp";

export const Resell = () => {

  return (
    <>
      <div className="mobileW-account">
      <div className="mobileW-account">
          <p style={{ fontWeight: "bold" }}>
          Select one platform to upload product selling announce
          </p>
          <div className="mobileW-insurance">
            <a
              className="mobileW-resell-col"
              href="https://www.collectorsquare.com/"
              target="_blank"
            >
                  <img src={collector} alt="" style={{width: "80%"}}/>
            </a>
            <a
              className="mobileW-resell-col"
              href="https://www.vinted.fr/"
              target="_blank"
            >
                <img src={vinted} alt="" style={{width: "70%"}}/>
            </a>
          </div>
          <div className="mobileW-insurance">
          <a
            className="mobileW-resell-col"
            href="https://www.therealreal.com/"
            target="_blank"
          >
              <img src={real} alt="" style={{width: "80%"}}/>
          </a>
          <a
            className="mobileW-resell-col"
            href="https://fr.vestiairecollective.com/"
            target="_blank"
          >
                <img src={vestiaire} alt="" style={{width: "70%"}}/>
            </a>
          </div>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "3vh",
            marginBlock: "2vh",
          }}
        >
          <div
            style={{
              width: "38%",
              border: "0.5px solid black",
              marginRight: "5px",
            }}
          />
          OR
          <div
            style={{
              width: "38%",
              border: "0.5px solid black",
              marginLeft: "5px",
            }}
          />
        </div>
          <a
            className="wallet-card-item"
            href="https://www.bearingpoint.com/en/"
            target="_blank"
          >
            <div className="mobileW-btn-sec">
              <p style={{ padding: "0px", margin: "Opx", marginTop: "0.6vh" }}>
                Ask for Maison Angleys quotation
              </p>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Resell;