import { useEffect, useState } from "react";
import axios from "axios";
import Card from "./DetailCard";
import { backend } from "../../App";
import "./CollectionDetails.css";
import { useSearchParams } from "react-router-dom";
import Passeport from "../PassportProduct/Desktop";
import { useTranslation } from "react-i18next";

const fetchCollectionImagesOwned = async (
  address: string,
  setImages: any,
  setFetch: any,
  setNotOwned: any,
  nbElms: string,
  wallet: any,
  jwt: string
) => {
  console.log("COLLECTION IMG LOG");
  const walletaddr = wallet.address;
  //axios.get(`${backend}/${address}/${walletaddr}`).then((resp) => {
  axios
    .post(
      `${backend}/nft/collection/wallet`,
      { coladdr: address, wallet: walletaddr },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API,
          Authorization: jwt,
          vault: wallet.vault,
        },
      }
    )
    .then((resp) => {
      setImages(resp.data);
      axios
        .post(
          `${backend}/nft/collection/all`,
          { coladdr: address, nbElm: parseInt(nbElms) },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API,
              Authorization: jwt,
              vault: wallet.vault,
            },
          }
        )
        .then((res) => {
          setNotOwned(res.data);
          console.log("res", res);
          console.log("resp", resp);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

interface DetailsProps {
  popSnack: any;
  allClients: any;
  currentWallet: any;
  jwt: string;
  clientXmtp: any;
}

export const Details2 = ({
  popSnack,
  allClients,
  currentWallet,
  jwt,
  clientXmtp
}: DetailsProps) => {
  const [fetch, setFetch] = useState(false);
  const [page, setPage] = useState(true);
  const [nfts, setNfts] = useState<any[]>([]);
  const [queryParameters] = useSearchParams();
  const addr = queryParameters.get("col") || "none";
  const nbElms = queryParameters.get("nbElms") || "none";
  const [isDetail, setDetail] = useState(-1);
  const [notOwned, setNotOwned] = useState<any[]>([]);
  const { t } = useTranslation("collectionDetails");

  useEffect(() => {
    if (!fetch) {
      try {
        setFetch(true);
        fetchCollectionImagesOwned(
          addr,
          setNfts,
          setFetch,
          setNotOwned,
          nbElms,
          currentWallet,
          jwt
        );
      } catch (err) {
        console.log(err);
      }
    }
  });

  return (
    <div className="collection-details-background">
      {isDetail === -1 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              width: "70vw",
            }}
          >
            <button
              style={{
                border: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: page ? "#1A223D" : "#078BF9",
                width: "34vw",
                height: "7vh",
                borderRadius: "10px",
                marginBottom: "5vh",
                cursor: "pointer",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() => {
                setPage(!page);
              }}
            >
              {t("nftDistributed")}
            </button>
            <button
              style={{
                border: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: !page ? "#1A223D" : "#078BF9",
                width: "34vw",
                height: "7vh",
                borderRadius: "10px",
                marginBottom: "5vh",
                cursor: "pointer",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() => {
                setPage(!page);
              }}
            >
              {t("nftStock")}
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#1A223D",
              width: "70vw",
              height: "70vh",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                width: "90%",
                height: "90%",
              }}
            >
              {fetch && page ? (
                nfts.map((elm, idx) => {
                  return (
                    <Card
                      key={elm.name}
                      nft={elm}
                      setDetails={setDetail}
                      idx={idx}
                    />
                  );
                })
              ) : fetch && !page ? (
                notOwned.map((elm, idx) => {
                  let flag = false;
                  nfts.map((owned) => {
                    if (owned.name === elm.name) {
                      flag = true;
                    }
                  });
                  if (flag) return <></>;
                  return (
                    <Card
                      key={elm.name}
                      nft={elm}
                      setDetails={setDetail}
                      idx={idx}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>{" "}
        </>
      ) : (
        <Passeport
          popSnack={popSnack}
          nft={page ? nfts[isDetail] : notOwned[isDetail]}
          allClients={allClients}
          page={page}
          setisDetail={setDetail}
          jwt={jwt}
          currWallet={currentWallet}
          clientXmtp={clientXmtp}
        />
      )}
    </div>
  );
};

export default Details2;
