import React, { useContext, useState } from "react";

import "./Expose.css"
import Showroom from "../Showroom/Showroom"
import ShowroomWindow from "./ShowroomWindow"
import axios from "axios";
import { useNavigate } from "react-router-dom"
import { backend, GlobalStateContext } from "../../App"
import CircularProgress from "@mui/material/CircularProgress";
import useFullPageLoader from "../../hooks/UseLoader";
import { useTranslation } from "react-i18next";

interface ShowroomRecapProps {
    nfts: any[]
    positions: any[]
    selectedShowroom: number
    popSnack: any
}

export const ShowroomRecap = ({ popSnack, positions, nfts, selectedShowroom }: ShowroomRecapProps) => {
    const navigate = useNavigate()
    console.log(nfts, positions)
    const [update, setUpdate] = useState(false)
    const [loader, setLoader] = useState(false)
    const [load, showLoader, hideLoader] = useFullPageLoader();
    const {t} = useTranslation("exposeShowroom");

    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error("MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider");
    }
    const { globalVar, setGlobalVar } = context;

    const postPositions = () => {
        setLoader(true)
        let pos: any[] = []
        let tokens: any[] = []
        let mdls: any[] = []
        let addr: any[] = []
        positions.map((elm) => {
            if (elm.nft !== -1) {
                pos.push(elm.pos.toString())
                if (elm.name.split(' ')[0] == 'Vortex') tokens.push((elm.nft + 6).toString())
                else tokens.push((elm.nft).toString())
                const idx = nfts.findIndex((element) => element.name == elm.name)
                mdls.push(nfts[idx].model)
                addr.push("0x328B27d19B8B26bf022261234F9C9bB7A90deAfc")
            }
        })
        if (typeof showLoader === 'function') {
            showLoader()
        }
        console.log(tokens, pos, addr, mdls)
        axios.post(`${backend}/showroom/modify`, { sessionId: 0, nfts: tokens, positions: pos, address: addr, models: mdls }, {headers: {"x-api-key": process.env.REACT_APP_API, "vault": globalVar}}).then((resp) => {
            console.log(resp)
            if (typeof hideLoader === 'function') {
                hideLoader()
            }
            navigate('/collections')
            popSnack(t('placed'), 'success')
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            popSnack(t('errorAdding'), 'error')
            if (typeof hideLoader === 'function') {
                hideLoader()
            }
        })
    }
    return (
        <>
            <div className='showroom-recap'>
                <div style={{ border: "1px solid", minHeight: 550, minWidth: 1000, maxHeight: 550, maxWidth: 1000, marginTop: "15vh" }} className="showroom-window" onClick={loader ? () => { } : () => { setUpdate(!update) }}>
                    <ShowroomWindow positions={positions} nfts={nfts} selectedShowroom={selectedShowroom} />
                </div>
                <button className="post-positions" onClick={() => { postPositions() }}>
                    {loader ? <CircularProgress sx={{ color: "white", maxWidth: "3vw" }} /> : "Confirm positioning"}
                </button>
            </div>
            {load}
        </>
    )
}

export default ShowroomRecap