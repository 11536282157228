// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collection-details-background {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 80vw;
    max-width: 80vw;
    min-height: 100vh;
    max-height: 100vh;
}

.details-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 10vw;
    max-width: 10vw;
    min-height: 10vw;
    max-height: 10vw;
    background-color: #04152D;
    border-radius: 10px;
    cursor: pointer

}

.collection-details-mid { 
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70vw;
    max-width: 70vw;
    min-height: 50vh;
    max-height: 50vh;
}`, "",{"version":3,"sources":["webpack://./src/components/CollectionDetails/CollectionDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB;;AAEJ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".collection-details-background {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    min-width: 80vw;\n    max-width: 80vw;\n    min-height: 100vh;\n    max-height: 100vh;\n}\n\n.details-card {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    min-width: 10vw;\n    max-width: 10vw;\n    min-height: 10vw;\n    max-height: 10vw;\n    background-color: #04152D;\n    border-radius: 10px;\n    cursor: pointer\n\n}\n\n.collection-details-mid { \n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-width: 70vw;\n    max-width: 70vw;\n    min-height: 50vh;\n    max-height: 50vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
