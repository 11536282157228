import "./Pass.scss";
import CreatePoap from "./CreatePass";
import RecapPoap from "./RecapPass";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Popup from "../Popup/Popup";

interface PoapProps {
  popSnack: any;
  setUpdatCollec: any;
  permissions: any;
  jwt: any;
  currWallet: any;
}

export const Poap = ({
  popSnack,
  setUpdatCollec,
  permissions,
  jwt,
  currWallet,
}: PoapProps) => {
  const [page, setPage] = useState(1);
  const blockchain = "Ethereum";
  const [addrPoap, setAddrPoap] = useState("");
  const { t } = useTranslation("poap");

  let titles: string[] = [t("poaInfos"), t("qrCode")];
  let nb_page = titles.length;
  let current_title = titles[page - 1];
  const [openPopup, setPopup] = useState(false);
  const [isOpened, setOpened] = useState(false);

  const launchPopup = () => {
    setPopup(true);
  };

  useEffect(() => {
    if (!isOpened && currWallet.name == "") {
      launchPopup();
      setOpened(true);
    }
  });

  return (
    <>
      {" "}
      <Popup
        open={openPopup}
        setOpen={setPopup}
        title={"Please select a wallet"}
        content={"Please select a wallet in your side bar to continue"}
      />
      {currWallet.name !== "" ? (
        <div className="background-pass">
          <div className="pass-head">
            <div>
              <h2>{t("passToken")}</h2>
              <h4 className="pass-head-subtitle">
                {`${page}/${nb_page} - ${current_title}`}
              </h4>
            </div>
            {/*page == nb_page ? <ViewPoapButton/> : <></>*/}
          </div>
          {page === 1 ? (
            <CreatePoap
              jwt={jwt}
              page={page}
              setPage={setPage}
              popSnack={popSnack}
              setUpdatCollec={setUpdatCollec}
              setAddrPoap={setAddrPoap}
            />
          ) : (
            <RecapPoap addrPoap={addrPoap} />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const ViewPoapButton = () => {
  const { t } = useTranslation("poap");
  return (
    <div className="view-col-item">
      <Link to="/collections" className="view-col-button">
        {t("viewCollection")}
      </Link>
    </div>
  );
};

export default Poap;
