// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-head-2 {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;

    min-height: 20vh;
    max-height: 20vh; 
}`, "",{"version":3,"sources":["webpack://./src/components/StepTitle/StepTitle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,sBAAsB;;IAEtB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".create-head-2 {\n    display: flex;\n    justify-content: center;\n    align-items: start;\n    flex-direction: column;\n\n    min-height: 20vh;\n    max-height: 20vh; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
