import { TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import useFullPageLoader from "src/hooks/UseLoader";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { backend, GlobalStateContext } from "src/App";

interface ConnectAbstractAccProps {
  popSnack: any;
  setPage: any;
  setAcc: any;
  setAccConnected: any;
}

export const ConnectAbstractAcc = ({
  popSnack,
  setAcc,
  setAccConnected,
  setPage,
}: ConnectAbstractAccProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { t } = useTranslation("mobileConnection");
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;

  const urlProd = process.env.REACT_APP_PROD_URL || "http://localhost";
  const portAbstract = process.env.REACT_APP_PORT_ABSTRACT || "3005";
  const urlwallet = urlProd + ":" + portAbstract + "/address";

  const ConnectWallet = async () => {
    // access new acc
    if (email == "" || password == "") {
      popSnack(t("fillInfos"), "warning");
    } else {
      if (typeof showLoader === "function") {
        showLoader();
      }
      let acc = "";
      await axios
        .post(
          urlwallet,
          { email: email, password: password },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (resp) => {
          acc = resp.data.address;
          localStorage.setItem("token", resp.data.token);
          setAcc(resp.data.address);
          await axios
            .get(`${backend}/client/${acc}`, {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                authorization: localStorage.getItem("token"),
              },
            })
            .then((resp) => {
              console.log("client exist");
              if (resp.data != "") {
                localStorage.setItem("privatekeyXmtp", resp.data);
                setAccConnected(true);
                popSnack(t("connected"), "success");
              } else {
                popSnack(t("notExist"), "warning");
              }
              if (typeof hideLoader === "function") {
                hideLoader();
              }
            })
            .catch(() => {
              if (typeof hideLoader === "function") {
                hideLoader();
              }
              popSnack(t("errorConnect"), "error");
            });
        })
        .catch((err) => {
          console.log(err);
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack(t("errorConnect"), "error");
        });
    }
  };

  const createAccount = () => {
    setPage(4);
  };

  return (
    <>
      <div className="wallet-user">
        <div className="wallet-back">
          <button
            className="wallet-back-btn"
            onClick={() => {
              setPage(1);
            }}
          >
            <span className="wallet-back-txt">&#60;&#32;{t("back")}</span>
          </button>
        </div>
        <div className="wallet-user-txt">{t("connect")}</div>
        <div className="wallet-user-form">
          <TextField
            className="send-txtfield"
            autoComplete="off"
            variant="outlined"
            label={t("emailAddress")}
            style={{ minWidth: "22vw", marginTop: "3vh", marginInline: "3vw" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            className="send-txtfield"
            autoComplete="off"
            variant="outlined"
            type="password" // Ajoutez cette ligne
            label={t("password")}
            style={{ minWidth: "22vw", marginTop: "3vh", marginInline: "3vw" }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="wallet-user-btn-crea"
            onClick={() => {
              createAccount();
            }}
          >
            {t("createWallet")}
          </button>
          <div style={{ alignItems: "center", justifyContent: "center" }}>
            <button
              className="wallet-user-btn"
              onClick={() => {
                ConnectWallet();
              }}
            >
              {t("connection")}
            </button>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default ConnectAbstractAcc;
