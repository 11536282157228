import React, { useState } from "react";

import eth from "../../assets/eth-white2.png"
import avax from "../../assets/avax-png.png"
import polygon from "../../assets/polygon-png.png"
import tezos from "../../assets/tezos.png"
import upload from "../../assets/upload.png"

import axios from "axios";
import uploadD from "../../assets/uploadD2.png"
import manualD from "../../assets/manualD2.png"

import './CreateCollection.css'
import useFullPageLoader from "src/hooks/UseLoader";
import {backend} from "../../App"

interface BlockchainProps {
    img: string,
    name: string,
    setPage: any,
    page: number,
    setBlockchain: any
}

interface PageProps {
    setPage: any,
    page: number,
    setBlockchain: any
}

export const PageBlockchain = ({setPage, page, setBlockchain}: PageProps) => {
    return <>        
        <Blockchain setBlockchain={setBlockchain} page={page} setPage={setPage} img={eth} name="Ethereum" />
        <Blockchain setBlockchain={setBlockchain} page={page} setPage={setPage} img={avax} name="Avalanche" />
        <Blockchain setBlockchain={setBlockchain} page={page} setPage={setPage} img={polygon} name="Polygon" />
        <Blockchain setBlockchain={setBlockchain} page={page} setPage={setPage} img={tezos} name="Tezos" />

    </>
}

const Blockchain = ({img, name, page, setPage, setBlockchain} : BlockchainProps) => {
    return <div className="selected-bc" onClick={() => {setBlockchain(name); setPage(page + 1)}} style={{marginLeft: "3vw",boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",cursor: "pointer" ,display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",  borderRadius: "20px"}}>
        <img src={img} style={{minWidth: "8vw", maxWidth: "8vw"}}/>
        <span  style={{ fontSize: "1.5em", fontWeight: "bold", marginTop: "5vh", color: '#e2e2e2'}}>
            {name}
        </span>
    </div>
}

const Select = ({page, setPage} : any) => {
    console.log("PAGE", page)
    const [isCsv, setCsv] = useState(false)
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {!isCsv ? <><div onClick={() => setPage(page+1)} style={{marginTop: '20vh', cursor: 'pointer', fontWeight: "bold", color: "white", width: "20vw", height: '20vw', backgroundColor: "#1A223D", display: 'flex', justifyContent: 'end', alignItems: 'center', borderRadius: '10%', flexDirection: 'column',backgroundImage: `url(${manualD})`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }}>
            {/* <img src={manualD} style={{maxWidth: '5vw'}}/> */}
            <span style={{color: '#04152D', marginBottom: '3vh'}}>
            Create manually
            </span>
        </div>
        <div onClick={() => {setCsv(true)}} style={{marginTop: '20vh', cursor: 'pointer', fontWeight: "bold", color: "white",  height: '20vw', backgroundColor: "#1A223D", display: 'flex', justifyContent: 'end', alignItems: 'center', borderRadius: '10%', marginLeft: '5vw', flexDirection: 'column', backgroundImage: `url(${uploadD})`,backgroundRepeat: 'no-repeat', width:'20vw',backgroundSize: 'cover' }}>
        {/* <img src={uploadD} style={{maxWidth: '5vw'}}/> */}
        <span style={{color: '#04152D', marginBottom: '3vh'}}>
        Upload CSV       
        </span>
        </div></> : <AddCsv/>}
    </div>
}

export const AddCsv = () => {
    const [isUploaded, setuploaded] = useState(false)
    const [isCsv, setCsv] = useState(false)
    const [file, setFile] = useState<any>(null);
    const [collections, setCollections] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const hiddenFileInput = React.useRef(null);
    const handleClickInput = () => {
        if (hiddenFileInput.current !== null)
        // @ts-ignore
        hiddenFileInput.current.click();
    };

    const onFileChange = () => {
        if (typeof showLoader === "function") {
            showLoader();
          }
        const formData = new FormData();
        formData.append('file', file);
        setCsv(true)
        axios.post(`${backend}/nft/csv`, formData)
            .then(response => {
                if (typeof hideLoader === "function") {
                    hideLoader();
                  }
                console.log('Réponse du serveur :', response.data);
            })
            .catch(error => {
                console.error('Erreur lors de l\'envoi du fichier :', error);
            });
    };

    const checkFile = (e: any) => {
        setFile(e.target.files[0]);
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setCsv(true)
        axios.post(`${backend}/nft/csv/check`, formData)
        .then(response => {
            setCollections(response.data)
            console.log('Réponse du serveur :', response.data);
        })
        .catch(error => {
            console.error('Erreur lors de l\'envoi du fichier :', error);
        });
    }

    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '80vw', height: '100vh'}}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'end', flexDirection: 'column'}}>
    <div style={{fontWeight: "bold", color: "white", width: "60vw", height: '50vh', backgroundColor: "#1A223D", display: 'flex', justifyContent: 'center', alignItems: 'start', borderRadius: 20, marginTop: '5vh', flexDirection: 'row' }}>
        {isCsv ? <CollectionTab collections={collections}/> : <button style={{cursor: "pointer", border: "none" ,minWidth: '14vw', minHeight: '60%', background: "radial-gradient(#1A223D, #078BF9)", borderRadius: 10, marginTop: '10vh'}} onClick={handleClickInput}>
            <input ref={hiddenFileInput} type="file" onChange={checkFile} style={{display: "none"}}/>
            <img src={upload} style={{maxWidth: '8vw'}}/>
        </button>}
    </div>
    {isCsv ? <button onClick={() => {onFileChange()}} style={{ minWidth: "20vw",
        minHeight: "5vh",
        "border": "none",
        "background": "#EF19D1",
        "cursor": "pointer",
        "color": "#ffffff",
        "marginTop": "5vh",
        "fontWeight": "bold",
        "borderRadius": "50px"}}>
            Create collections
        </button> : <></>}
        </div>
    </div>
}

const CollectionTab = ({collections}: any) => {

    return <div style={{width: '100%'}}>
        <div style={{borderBottom: "1px solid white", height: "7vh", display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <span style={{ width: "16vw", textAlign: 'start', marginLeft: '2vw'}}>
            Name
            </span>
            <span style={{width: "22vw", textAlign: 'start',}}>
            Description
            </span>
            <span style={{width: "18vw", textAlign: 'end', marginRight: '2vw'}}>
            Number of Elements
            </span>

        </div>
        {collections.map((elm: any) => {

            return <div style={{borderBottom: "0.5px solid white", height: "7vh", display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'normal'}}>
            <span style={{ width: "16vw", textAlign: 'start', marginLeft: '2vw'}}>
            {elm.name}
            </span>
            <span style={{width: "22vw", textAlign: 'start',}}>
            {elm.description}
            </span>
            <span style={{width: "18vw", textAlign: 'end', marginRight: '2vw'}}>
            {elm.nbElm}
            </span>

        </div>
        })}
    </div>
}

export default Select