import React, { useState } from "react";

import './CreateCollection.css'
import TextField from '@mui/material/TextField';
import Next from './NextButton'
import { useTranslation } from "react-i18next";
import { AddCsv } from "./Blockchain"


interface DescriptionProps {
    blockchain: string,
    name: string,
    setName: any,
    desc: string,
    setDesc: any,
    price: string,
    setPrice: any,
    nbElms: string,
    setElms: any,
    symb: string,
    setSymb: any,
    setCsv: any
}

const Description = ({ setCsv, name, setName, desc, setDesc, price, setPrice, nbElms, setElms, symb, setSymb, blockchain }: DescriptionProps) => {

    const { t } = useTranslation("createCollection");

    return <div className="div-details">

        <div className="div-details-1">
            <TextField autoComplete='off' variant="outlined" label={t('name')} style={{ minWidth: "25vw", border: "green" }} className="txtfield" value={name} onChange={(e: any) => {
                setName(e.target.value)
            }} />
            <TextField autoComplete='off' variant="outlined" label={t('symbol')} style={{ marginLeft: "5vw" }} className="txtfield" value={symb} onChange={(e: any) => {
                setSymb(e.target.value)
            }} />
        </div>

        <TextField autoComplete='off' variant="outlined" label={t('price')} style={{ marginTop: "5vh", width: '15vw' }} className="txtfield" onChange={(e: any) => {
            setPrice(e.target.value)
        }} />
        <TextField autoComplete='off' variant="outlined" label={t('description')} style={{ minWidth: "100%", marginTop: "5vh" }} className="txtfield" value={desc} onChange={(e: any) => {
            setDesc(e.target.value)
        }} />
        <TextField autoComplete='off' variant="outlined" label={t('total')} style={{ marginTop: "5vh", width: '15vw' }} className="txtfield" onChange={(e: any) => {
            setElms(e.target.value)
        }} />
        <div style={{ marginTop: '3vh', width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'row' }}>
            <div style={{ height: '1px', width: '45%', borderBottom: '1px solid white' }} />
            <span>
                {t('OR')}
            </span>
            <div style={{ height: '1px', width: '45%', borderBottom: '1px solid white' }} />

        </div>
        <div style={{ marginBottom: '2vh', marginTop: '3vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button onClick={() => { setCsv(true) }} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#EF19D1', border: 'none', color: 'white', width: '90%', height: "5vh", borderRadius: '100px', fontWeight: 'bold' }}>
                Upload a file
            </button>
        </div>
    </div>
}

export default Description