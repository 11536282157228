import { useTranslation } from 'react-i18next'
import './Offer.css'

interface DetailProps {
    offer: any
    page: number
    setPage: any
}

export const Detail = ({page, setPage, offer}: DetailProps) => {
    const {t} = useTranslation("offer");
    
    return <div className="offer-details">
        <div className="offer-card-details">
            <div className="offer-head">
                <h3 style={{color: "white"}}>
                    {offer.name}
                </h3>  
                <span style={{color: "#EF19D1"}}>
                    {offer.code}
                </span>
            </div>
            
            <span style={{color: "#e2e2e2", marginLeft: "2vw", marginRight: "2vw", textAlign: 'start', minWidth: '26vw'}}>
                {offer.description}
            </span>
            <div style={{display: "flex", justifyContent: "start" , alignItems: "start", minWidth: "26vw", maxWidth: '26vw', marginTop: "7vh", flexDirection: "column"}}>
                <span style={{color: "white", fontWeight: "bold"}}>
                    {t('promotionUrl')}
                </span>
                <span style={{color: "#078BF9", marginTop: "2vh"}}>
                    {offer.url}
                </span>
            </div>
            <button style={{background: "#EF19D1", border: "none", minWidth: "15vw", minHeight: "4vh", color: "white", borderRadius: "50px", marginTop: "7vh", cursor: "pointer"}} onClick={() => {
                setPage(page+1)
                }}>
                {t('addOffer')}
            </button>
        </div>
    </div>
}