import { useLocation } from "react-router-dom";
import axios from "axios";
import { backend } from "src/App";
import sac_YSL from "../../assets/passport/Sac_YSL.png";
import "./Passport.scss";
import header from "../../assets/passport/header.png";
import hermesheader2 from "../../assets/passport/header2.png";
import bag from "../../assets/passport/bag.png";
import footer1 from "../../assets/passport/footer.png";
import france from "../../assets/passport/france.png";
import recycle from "../../assets/passport/recycle.png";
import bootle from "../../assets/passport/bootle.png";
import chimique from "../../assets/passport/chimique.png";
import ecoscore from "../../assets/passport/ecoscore.png";
import ecoscore_product from "../../assets/passport/ecoscore_product.png";
import minus from "../../assets/passport/minusbuton.png";
import plus from "../../assets/passport/plusbuton.png";
import { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function PassportHermesMobile() {
  const [ecodetail, setEcodetail] = useState(true);

  const changeEco = () => {
    setEcodetail(!ecodetail);
  };

  //--font-title: "Gill Sans", "Gill Sans MT", calibri, serif;
  //--font-body: "Helvetica W01", "Helvetica Neue", sans-serif;
  return (
    <div className="passport-page">
      <img src={header} alt="hermesheader" className="hermes-header" />
      <div className="passport-content">
        <div className="product">
          <div className="pass-watch-img">
            <img src={bag} className="watch-img" />
          </div>
          <div className="product-right">
            <span className="pass-title">PETIT SAC A MAIN BANDOULIERE </span>
            <span className="pass-subtitle">Numero de serie : 63728902</span>
            <span className="pass-subtitle" style={{ marginBottom: "8px" }}>
              Reference du produit :&nbsp;W055330W
            </span>
            <span className="description">
              Sac en cuir de veau, fond amovible en toile. Modèle avec fine
              bandoulière amovible, poignée en haut et rabat muni d'un fermoir
              en acier plaqué or.
            </span>
            <div className="madein">
              <img src={france} alt="france" className="suisse-flag" />
              <span className="description">Frabriquée en France</span>
            </div>
          </div>
        </div>
        <div className="compo-matiere">
          <span className="pass-title">MATIERES PREMIERES ET COMPOSITION</span>
          <div className="matiere-col">
            <div className="matiere-row" style={{ marginBottom: "15px" }}>
              <div>
                <div className="pass-title2" style={{ marginBottom: "8px" }}>
                  CUIR DE VEAU
                </div>
                <span className="description">Italie</span>
              </div>
              <div>
                <div className="pass-title2" style={{ marginBottom: "8px" }}>
                  TOILE
                </div>
                <span className="description">Japon</span>
              </div>
            </div>
            <div className="matiere-row">
              <div>
                <div className="pass-title2" style={{ marginBottom: "8px" }}>
                  ACIER PLAQUE OR
                </div>
                <span className="description">Inde</span>
              </div>
              <div>
                <div className="pass-title2" style={{ marginBottom: "8px" }}>
                  PALADIUM
                </div>
                <span className="description">Afrique du Sud</span>
              </div>
            </div>
          </div>
          <div className="composition">
            <div className="compo-elem">
              <img src={recycle} alt="recycle" className="compo-img" />
              <span className="pass-title2" style={{ marginBottom: "6px" }}>
                8%
              </span>
              <span className="description">de matières recyclées</span>
            </div>
            <div className="compo-elem">
              <img src={bootle} alt="bootle" className="compo-img" />
              <span className="pass-title2" style={{ marginBottom: "6px" }}>
                NE REJETE PAS
              </span>
              <span className="description">
                de microfibres de plastiques lors du lavage
              </span>
            </div>
            <div className="compo-elem">
              <img src={chimique} alt="chimique" className="compo-img" />
              <span className="pass-title2" style={{ marginBottom: "6px" }}>
                NE CONTIENT PAS
              </span>
              <span className="description">
                de substance extrêmement préoccupante (SVHC)
              </span>
            </div>
          </div>
        </div>
        <div className="fabrication" style={{ width: "100vw" }}>
          <span className="pass-title" style={{ marginLeft: "28px" }}>
            ETAPES DE FABRICATION
          </span>
          <Timeline>
            <TimelineItem>
              <TimelineOppositeContent sx={{ flex: 0.1 }} />
              <TimelineSeparator>
                <TimelineDot sx={{ background: "rgb(96, 83, 110)" }} />
                <TimelineConnector sx={{ background: "rgb(108, 94, 123)" }} />
              </TimelineSeparator>
              <TimelineContent>
                <span
                  className="pass-subtitle"
                  style={{ fontSize: "0.8rem", color: "rgb(68, 68, 68)" }}
                >
                  DECOUPE ET TRAITEMENT DU CUIR
                </span>
                <br />
                <span className="fab-flag">Italie</span>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent sx={{ flex: 0.1 }} />
              <TimelineSeparator>
                <TimelineDot sx={{ background: "rgb(96, 83, 110)" }} />
                <TimelineConnector sx={{ background: "rgb(108, 94, 123)" }} />
              </TimelineSeparator>
              <TimelineContent>
                <span
                  className="pass-subtitle"
                  style={{ fontSize: "0.8rem", color: "rgb(68, 68, 68)" }}
                >
                  CONFECTION
                </span>
                <br />
                <span className="fab-flag">France</span>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent sx={{ flex: 0.1 }} />
              <TimelineSeparator>
                <TimelineDot sx={{ background: "rgb(96, 83, 110)" }} />
              </TimelineSeparator>
              <TimelineContent>
                <span
                  className="pass-subtitle"
                  style={{ fontSize: "0.8rem", color: "rgb(68, 68, 68)" }}
                >
                  ASSEMBLAGE PETITS COMPOSANTS
                </span>
                <br />
                <span className="fab-flag">France</span>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
        <div className="ecoscore">
          <span className="pass-title">ECOSCORE</span>
          <div className="eco-content">
            <img src={ecoscore} alt="ecoscore" className="eco-img" />
            {ecodetail ? (
              <img src={plus} className="displaybutton" onClick={changeEco} />
            ) : (
              <>
                <img
                  src={minus}
                  className="displaybutton"
                  onClick={changeEco}
                />
                <img
                  src={ecoscore_product}
                  alt="ecoscore_product"
                  className={`eco-product-img ${ecodetail ? "" : "slide-in"}`}
                />
              </>
            )}
          </div>
        </div>
        <div className="care">
          <span className="pass-title">CARE & MAINTENANCE</span>
          <span className="description">
            Lisez les instructions complètes de maintenance des montres. Voir
            toutes les questions fréquentes
          </span>
        </div>
        <div className="garantie">
          <span className="pass-title">GARANTIE</span>
          <span className="description">Produit garanti deux ans</span>
        </div>
      </div>
      <img src={footer1} className="hermes-header" />
    </div>
    // <div className="passport-page">
    //   <div className="passport-content">
    //     <div className="title">Hermes</div>
    //     <img src={watch_hermes} className="watch-img" />
    //     <div className="description">
    //       <div className="subtitle">Montre Hermes inspiraiton la lune</div>
    //       <div className="made-in">Made in France</div>
    //     </div>

    //     <div className="composition">
    //       <div className="compo-title">---------&ensp;Composition&ensp;---------</div>
    //       <div className="compo-elem">
    //         <div className="compo-txt">Recycled materials</div>
    //         <img src={recycle_logo} className="compo-img" />
    //         <div className="compo-txt">0.00%</div>
    //       </div>
    //       <div className="compo-elem">
    //         <div className="compo-txt">Plastic microfibers</div>
    //         <img src={plastic_waste_logo} className="compo-img" />
    //         <div className="compo-txt">
    //           Does not rejet any plastic microfibers during washing
    //         </div>
    //       </div>
    //       <div className="compo-elem">
    //         <div className="compo-txt">Substance of very high concern</div>
    //         <img src={bottle_logo} className="compo-img" />
    //         <div className="compo-txt">
    //           Contains Substance of very High Concern
    //         </div>
    //       </div>
    //     </div>
    //     <div className="transparency">
    //       <div className="trans-title">Transparency</div>
    //       <div className="trans-elem">
    //         <div className="trans-elem-title">Materials</div>
    //         <div className="trans-elem-txts">
    //           <div className="trans-elem-txt">Main fabric</div>
    //           <div className="trans-elem-txt">Main fabric</div>
    //           <div className="trans-elem-txt">Main fabric</div>
    //         </div>
    //       </div>
    //       <div className="trans-elem">
    //         <div className="trans-elem-title">Confection</div>
    //         <div className="trans-elem-txts">
    //           <div className="trans-elem-txt">Italy</div>
    //         </div>
    //       </div>
    //       <div className="trans-elem">
    //         <div className="trans-elem-title">Raw Material</div>
    //         <div className="trans-elem-txts">
    //           <div className="trans-elem-txt">SLK: swiss</div>
    //           <div className="trans-elem-txt">Coton: Egypt</div>
    //         </div>
    //       </div>
    //     </div>
    //     <img src={eco_logo} className="eco-img" />
    //   </div>
    //   <img src={footer} className="footer-img" />
    // </div>
  );
  // const query = useQuery();
  // const [nft, setNft] = useState<{name: string, description: string, image: string, model: string, ar: string, serial: string}>();

  // const collection = query.get('collection') || '';
  // const id = query.get('id') || '';

  // const fetchCollections = async () => {
  //   console.log(process.env.REACT_APP_API)
  //   axios.post(`${backend}/nft/collection/id`, {id: id, collection: collection}, {headers: {"x-api-key": process.env.REACT_APP_API}}).then((resp) => {
  //     setNft(resp.data)
  //   }).catch((err) => {
  //     console.log(err)
  //   })
  // }
  // useEffect(() => {
  //   if (nft == undefined)
  //   {
  //     fetchCollections()
  //   }
  // })

  // return (
  //   <div
  //     style={{
  //       position: "absolute",
  //       width: "100vw",
  //       overflow: "hidden",
  //       margin: 0,
  //       padding: 0,
  //       display: "flex",
  //       flexDirection: "column",
  //       alignItems: "start",
  //       justifyContent: "start",
  //     }}
  //   >
  //     {/* <div style={{ position: "relative", width: "100vw", overflow: "hidden", margin: 0, padding: 0 }}>
  //       <img src={MobileHermes} alt="middle" style={{ width: "100%", height: "100%", display: "block" }} />
  //       <div style={{ position: "absolute", top: "9%", left: "8%" }}>
  //         <span style={{ margin: 0, fontSize: '6vw', color: '#696969' }}>{nft?.serial}</span>
  //       </div>
  //       <div style={{ position: "absolute", top: "25.86%", left: "51%" }}>
  //         <span style={{ margin: 0, fontSize: '3vw', color: '#696969' }}>{nft?.serial}</span>
  //       </div>
  //       <div style={{ position: "absolute", top: "22%", left: "10%" }}>
  //         <span style={{ margin: 0, fontSize: '6vw', color: '#696969' }}>{nft?.name}</span>
  //       </div>
  //       <div style={{ position: "absolute", top: "28%", left: "11%", width: "70vw", height: "70vw" }}>
  //         <img src={nft?.image} alt="product" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
  //       </div>
  //     </div> */}
  //     <img src={passeportH} style={{ width: "100vw", height: "200vh" }} />
  //   </div>
  // );
}

export default PassportHermesMobile;
