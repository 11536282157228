import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";

interface PopupPropsChoice {
  open: boolean;
  setOpen: any;
  value: string;
}

export const PopupQrCode = ({ open, setOpen, value }: PopupPropsChoice) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogContent
          sx={{
            backgroundColor: "#04152D",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QRCode value={value} size={200} />
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "#04152D",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            fontWeight: "bold",
            fontSize: "1.4em",
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              backgroundColor: "#04152D",
              color: "white",
              fontWeight: "bold",
              fontSize: "0.8em",
              "&:hover": { backgroundColor: "#051e41" },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupQrCode;
