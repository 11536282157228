import { useState } from "react";

import Card from "./OfferCard";
import CreateCard from "./Create-offer";
import { useTranslation } from "react-i18next";

interface SliderProps {
    offers: any[]
    setSelected: any
    setCreate: any
    page: number
    setPage: any
}

export const Slider = ({ page, setPage, offers, setSelected, setCreate }: SliderProps) => {
    
    const [isLoading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)

    const numberOfPages = Math.ceil(offers.length / 2)
    let circles = []

    if (offers.length > 0 && isLoading) {
        setLoading(false)
    }

    for (let index = 0; index < numberOfPages; index++) {
        if (index + 1 === currentPage) {
            circles.push(<Circle fill={false} />)
        }
        else {
            circles.push(<Circle fill />)
        }
    }

    return <div className="offer-slider-background">
        {isLoading ? <>                <button style={{ background: "none", border: "none" }} onClick={() => {
            setCreate(true)
            setPage(page + 1)
        }}>
            <CreateCard />
        </button>   </>
            : <>
                <div className="offer-slider-head">
                    <button className="slider-buttons" style={{ marginRight: '1vw' }} onClick={() => { if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1) }}>
                        {'<'}
                    </button>
                    <button className="slider-buttons" onClick={() => { if (currentPage + 1 <= numberOfPages) setCurrentPage(currentPage + 1) }}>
                        {'>'}
                    </button>
                </div>
                <div className="offer-slider-middle">
                    <button style={{ background: "none", border: "none" }} onClick={() => {
                        setCreate(true)
                        setPage(page + 1)

                    }}>
                        <CreateCard />
                    </button>

                    {offers.map((elm, idx) => {
                        if (Math.ceil((idx + 1) / 2) === currentPage) return <Card offer={elm} setSelected={setSelected} page={page} setPage={setPage} />
                    })}
                </div>
                <div className="slider-foot">
                    {circles}
                </div>
            </>}

    </div>
}

interface CircleProps {
    fill: boolean
}

const Circle = ({ fill }: CircleProps) => {
    const {t} = useTranslation("offer");
    return <div className={fill ? t('filled') : t('notFilled')} />
}

export default Slider