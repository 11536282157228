import { useEffect, useState } from "react";
import "../MobileApp.scss";
import { backend } from "src/App";
import axios from "axios";
import { CreateUser } from "./CreateUser";
import { AccountConnect } from "./AccountConnect";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { account } from "src/atoms/account";
import useFullPageLoader from "src/hooks/UseLoader";
import CreateAbstractAcc from "./CreateAbstractAcc";
import ConnectAbstractAcc from "./ConnectAbstractAcc";
import ByBearing from "src/assets/mobileIcon/bearingBlack.png";
import { useTranslation } from "react-i18next";
import SignaturePopup from "./Signature";
import useEffectOnce from "src/hooks/UseEffectOnce";
import Payment from "./Payment";
import { send } from "@emailjs/browser";
import { Languages } from "src/components/Language/Language";

interface MobileConnectionProps {
  popSnack: any;
}

export const MobileConnection = ({ popSnack }: MobileConnectionProps) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [acc, setAcc] = useRecoilState(account);
  const [isUserExist, setisUserExist] = useState(true);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [accConnected, setAccConnected] = useState(false);
  const [sign, setsign] = useState(false);
  const { t } = useTranslation("mobileConnection");
  const [price, setPrice] = useState(0);
  const [navigatorType, setNavigatorType] = useState("none");

  const [queryParameters] = useSearchParams();
  const collec = queryParameters.get("collec") || "none";
  const type = queryParameters.get("type") || "none";
  const idNFT = queryParameters.get("idNFT") || "-1";
  const frontend = process.env.REACT_APP_FRONT || "http://172.20.10.4:3000";

  let urlRedirect = frontend + "/wallet";

  if (collec != "none") {
    urlRedirect += "?collec=" + collec;
    if (idNFT != "-1") {
      urlRedirect += "&idNFT=" + idNFT;
    }
  }

  interface Position {
    latitude: number;
    longitude: number;
  }

  // Fonction pour obtenir la position de l'utilisateur
  function getPosition(): Promise<Position> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          (error) => reject(error)
        );
      } else {
        reject(new Error(t("notSupportedGeolocation")));
      }
    });
  }

  /* test url :
    http://localhost:3000/wallet?collec=0xC47183b05C8EA2b479F4316f73ba1194b93C4662
    */
  const mintpoap = async () => {
    // const userPos = await getPosition();
    // const latitude = userPos.latitude;
    // const longitude = userPos.longitude;
    const latitude = 48.905220;
    const longitude = 2.240420;
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/poap/mint`,
        {
          address: collec,
          minter: acc,
          latitude: latitude,
          longitude: longitude,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        popSnack(t("poapMinted"), "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        navigate(
          `/wallet/addr?token=${localStorage.getItem("token")}&wallet=${acc}`
        );
      })
      .catch((err) => {
        setAccConnected(false);

        console.log(err);
        if (err.response.data.includes("event")) {
          popSnack(t("notInEvent"), "error");
        } else {
          popSnack(t("errorMintingPoap"), "error");
        }
        if (typeof hideLoader === "function") {
          hideLoader();
        }
      });
  };

  /* test url :
    http://localhost:3000/wallet?collec=0x6B3f10a080551FD407F17d0ee1FF30FF8A7f479D&idNFT=3
    */
  const sendnft = () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/nft/send`,
        { contractAddress: collec, wallet: acc, tokenId: idNFT },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        console.log("nft sent to", acc);
        popSnack(t("nftMinted"), "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        navigate(
          `/wallet/addr?token=${localStorage.getItem("token")}&wallet=${acc}`
        );
      }).catch((err) => {
          popSnack("An error occured", "error");
          console.log(err)
      });
  };

  const checkuser = () => {
    axios
      .get(`${backend}/client/${acc}`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API,
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((resp) => {
        if (resp.data != "") {
          localStorage.setItem("privatekeyXmtp", resp.data);
          actionConnect();
        } else {
          setPage(2);
          setisUserExist(false);
          popSnack(t("registerInfos"), "success");
        }
      })
      .catch((err) => {
        console.log(err);
        popSnack(t("errorCheckingUser"), "error");
      });
  };

  const onsign = (signature: string): void => {
    console.log("signature", signature);
    axios
      .post(
        `${backend}/auth/connectionMeta`,
        {
          addr: acc,
          signature: signature,
        },
        { headers: { "x-api-key": process.env.REACT_APP_API } }
      )
      .then((resp) => {
        setsign(false);
        if (resp.data != "") {
          localStorage.setItem("token", resp.data);
          popSnack(t("connectionSuccess"), "success");
          console.log("onsign success");
          checkuser();
        } else {
          popSnack(t("errorSigner"), "error");
        }
      })
      .catch((err) => {
        popSnack(t("errorSigner"), "error");
        console.log(err);
      });
  };

  const sendRdmNft = () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/nft/sendRdm`,
        { contractAddress: collec, wallet: acc },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        console.log("nft sent to", acc);
        popSnack(t("nftMinted"), "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        navigate(
          `/wallet/addr?token=${localStorage.getItem("token")}&wallet=${acc}`
        );
      });
  };

  useEffectOnce(() => {
    localStorage.clear();
  });

  useEffect(() => {
    console.log("platform is: ", window?.navigator?.platform);
    setNavigatorType(window?.navigator?.platform);

    // Redirection to app mobile when the device is an iphone
    // if (window?.navigator?.platform == "iPhone") {
    //   if (idNFT == "-1") {
    //     window.location.assign("deeplink://poap?collection=" + collec);
    //   } else {
    //     window.location.assign(
    //       "deeplink://account?collection=" + collec + "&idNFT=" + idNFT
    //     );
    //   }
    // }

    // Connect with abstract account method
    if (accConnected) {
      if (collec == "none") {
        navigate(
          `/wallet/addr?token=${localStorage.getItem("token")}&wallet=${acc}`
        );
      } else {
        // check payment status)
        axios
          .post(
            `${backend}/poap/payment`,
            {
              collec: collec,
              type: type,
            },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((resp) => {
            if (resp.data !== "none") {
              setPrice(resp.data);
              setPage(5);
            } else if (idNFT === "-1") {
              if (type === "poap") {
                mintpoap();
              } else {
                sendRdmNft();
              }
            } else {
              sendnft();
            }
          })
          .catch((err) => {
            console.log(err);
            popSnack(t("errorPayment"), "error");
          });
      }
    }
  }, [page, accConnected, isUserExist]);

  const actionConnect = () => {
    if (collec == "none") {
      navigate(
        `/wallet/addr?token=${localStorage.getItem("token")}&wallet=${acc}`
      );
    } else if (idNFT == "-1") {
      if (type == "poap") {
        mintpoap();
      } else {
        sendRdmNft();
      }
    } else {
      sendnft();
    }
  };

  const emulator = { height: "95vh" };
  const phone = { height: "-webkit-fill-available" };

  return (
    <>
      <Languages />
      <div className="wallet-background" style={navigatorType == "Win32"|| "MacIntel" ? emulator : phone}>
        <div style={{ height: "78vh" }}>
          {page === 1 && sign ? <SignaturePopup onSign={onsign} /> : <></>}
          {page === 1 ? (
            <AccountConnect
             setAccConnected={setAccConnected}
              popSnack={popSnack}
              setAcc={setAcc}
              setPage={setPage}
              urlRedirect={urlRedirect}
              setsign={setsign}
              collec={collec}
              idNFT={idNFT}
            />
          ) : page === 2 && !isUserExist ? (
            <CreateUser
              popSnack={popSnack}
              wallet={acc}
              actionConnect={actionConnect}
            />
          ) : page === 3 ? (
            <ConnectAbstractAcc
              popSnack={popSnack}
              setAccConnected={setAccConnected}
              setAcc={setAcc}
              setPage={setPage}
            />
          ) : page === 4 ? (
            <CreateAbstractAcc
              popSnack={popSnack}
              setAccConnected={setAccConnected}
              setAcc={setAcc}
              setPage={setPage}
            />
          ) : page === 5 ? (
            <Payment money={price} actionConnect={actionConnect} />
          ) : (
            <></>
          )}
        </div>
        <div className="footer">
          <img src={ByBearing} className="wallet-img-bearing" />
        </div>
      </div>
      {loader}
    </>
  );
};

export default MobileConnection;
