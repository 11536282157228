import React from "react";

import './Offer.css'

interface CardProps {
    offer : any
    setSelected: any
    page: number
    setPage: any
}

export const Card = ({page, setPage, offer, setSelected}: CardProps) => {

    return <div className="offer-card-background" onClick={() => {
        setSelected(offer)
        setPage(page+1)
    }}>
        <h3 style={{color: "white", marginLeft: "1vw", minHeight: '9vh', maxHeight: '9vh',overflow: 'hidden', marginBottom: "0"}}>
            {offer.name}
        </h3>
        <span style={{color: "#e2e2e2", marginLeft: "1vw", minHeight: '8vh'}}>
            {offer.description}
        </span>
        <span style={{color: "#EF19D1", marginLeft: "1vw", minHeight: '8vh', marginTop: "3vh"}}>
            {offer.code}
        </span>
    </div>
} 

export default Card