import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { backend, GlobalStateContext } from "../../App";
import icon from "../../assets/iconeCustomer.png";
import eth from "../../assets/eth-white2.png";
import ape from "../../assets/ape.png";
import sand from "../../assets/sand.png";
import quit from "../../assets/quit.png";
import { useTranslation } from "react-i18next";

interface DetailsProps {
  selected: number;
  setSelected: any;
  customer: any;
  jwt: any;
}

export const Customer360 = ({
  selected,
  setSelected,
  customer,
  jwt,
}: DetailsProps) => {
  const [isLoading, setLoading] = useState(false);
  const [customerProd, setCustomerProd] = useState<any[]>([]);
  const [customerPoap, setCustomerPoap] = useState<any[]>([]);
  const [customerExtData, setCustomerExtData] = useState<any>();
  const [page, setPage] = useState<"internal" | "external" | "main">("main");
  const { t } = useTranslation("customers");
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;

  let collections: [boolean, any][] = [];
  for (let index = 0; index < customerProd.length; index++) {
    collections.push([false, customerProd[index]]);
  }
  for (let index = 0; index < customerPoap.length; index++) {
    let elmnt = {
      name: customerPoap[index].name,
      image: customerPoap[index].image,
    };
    customerPoap[index].tmp = elmnt;
    collections.push([true, customerPoap[index]]);
  }
  console.log(collections);

  console.log(page);

  const fetchAll = () => {
    if (customer.wallet == undefined) {
      axios
        .post(
          `${backend}/alldatas/client`,
          { addr: customer.wallets[0] },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API,
              authorization: jwt,
              vault: globalVar,
            },
          }
        )
        .then((resp) => {
          setCustomerProd(resp.data.product);
          setCustomerPoap(resp.data.poap);
          setLoading(true);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          axios
            .post(
              `${backend}/scan/externaldata`,
              { addr: customer.wallets[0] },
              {
                headers: {
                  "x-api-key": process.env.REACT_APP_API,
                  authorization: jwt,
                  vault: globalVar,
                },
              }
            )
            .then((resp) => {
              console.log(resp);
              setCustomerExtData(resp.data);
              setLoading(true);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };

  useEffect(() => {
    if (!isLoading) {
      fetchAll();
    }
  });

  return (
    <div className="customer-360-container">
      <div className="customer-360-infos">
        <div className="customer-360-infos-left">
          <span
            style={{ fontWeight: "bold", color: "#078BF9", fontSize: "1.3em" }}
          >
            {t("personalInfo")}
          </span>
          <span
            style={{
              fontWeight: "bold",
              color: "white",
              fontSize: "1em",
              marginTop: "2vh",
            }}
          >
            {customer.name}
          </span>
          <span style={{ color: "white", fontSize: "1em", marginTop: "0.5vh" }}>
            {customer.email}
          </span>
          <span style={{ color: "white", fontSize: "1em", marginTop: "1vh" }}>
            {customer.wallets[0]}
          </span>
        </div>
        <div className="customer-360-infos-right">
          <img src={icon} />
        </div>
      </div>
      {page == "main" ? (
        <>
          {" "}
          <div className="customer-360-collections">
            <div
              className="customer-360-collection-int"
              onClick={() => {
                setPage("internal");
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: "#078BF9",
                  fontSize: "1.1em",
                  textAlign: "start",
                  width: "30vw",
                  marginTop: "2vh",
                }}
              >
                Internal collections
              </span>
              <div className="customer-360-collection-int-content">
                {collections.length > 0 ? (
                  <DisplayCollections
                    name={collections[0][1].name}
                    address={collections[0][1].address}
                    symbole={collections[0][1].symbol}
                    Nfts={collections[0][1].nfts}
                  />
                ) : (
                  <></>
                )}
                <div style={{ minWidth: "1vw" }} />
                {collections.length > 1 ? (
                  <DisplayCollections
                    name={collections[1][1].name}
                    address={collections[1][1].address}
                    symbole={collections[1][1].symbol}
                    Nfts={collections[1][1].nfts}
                  />
                ) : (
                  <></>
                )}
                <div style={{ minWidth: "1vw" }} />
                {collections.length > 2 ? (
                  <DisplayCollections
                    name={collections[2][1].name}
                    address={collections[2][1].address}
                    symbole={collections[2][1].symbol}
                    Nfts={collections[2][1].nfts}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              className="customer-360-collection-ext"
              onClick={() => {
                setPage("external");
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: "#8007F9",
                  fontSize: "1.1em",
                  textAlign: "start",
                  width: "28vw",
                  marginTop: "2vh",
                }}
              >
                External collections
              </span>
              <div className="customer-360-collection-ext-content">
                {customerExtData?.collections.length > 0 ? (
                  <DisplayCollections
                    name={customerExtData.collections[0].name}
                    address={customerExtData.collections[0].contractAddress}
                    symbole={customerExtData.collections[0].symbol}
                    Nfts={customerExtData.collections[0].nfts}
                  />
                ) : (
                  <></>
                )}
                <div style={{ minWidth: "3vw" }} />
                {customerExtData?.collections.length > 1 ? (
                  <DisplayCollections
                    name={customerExtData.collections[1].name}
                    address={customerExtData.collections[1].contractAddress}
                    symbole={customerExtData.collections[1].symbol}
                    Nfts={customerExtData.collections[1].nfts}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="customer-360-cryptos">
            <div className="customer-360-cryptos-content">
              <span
                style={{
                  fontWeight: "bold",
                  color: "#078BF9",
                  fontSize: "1.2em",
                }}
              >
                Cryptocurrencies in wallet
              </span>
              {customerExtData ? (
                <div
                  className="customer-360-cryptos-content-cryptos"
                  onClick={() => {
                    setPage("external");
                  }}
                >
                  <DisplayCrypto
                    name="Ethereum"
                    balance={customerExtData.eth.slice(0, 5)}
                  />
                  <DisplayCrypto name="The Sandbox" balance={"2.05"} />
                  <DisplayCrypto name="ApeCoin" balance={"4.8"} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>{" "}
        </>
      ) : page == "internal" ? (
        <InternalCollections setView={setPage} collections={collections} />
      ) : (
        <ExternalCollections
          setView={setPage}
          customerExtData={customerExtData}
        />
      )}
    </div>
  );
};

interface DisplayCollectionProps {
  name: string;
  address: string;
  symbole: string;
  Nfts: any;
}

const DisplayCollections = ({
  name,
  address,
  symbole,
  Nfts,
}: DisplayCollectionProps) => {
  const begin = address.slice(0, -35);
  const end = address.slice(-4);

  return (
    <div className="display-collection-container">
      <span style={{ fontWeight: "bold", color: "white" }}>{name}</span>

      <span style={{ color: "white" }}>
        {Nfts != undefined ? `${Nfts.length} NFT own` : "1 Poa own"}
      </span>
    </div>
  );
};

interface DisplayCryptoProps {
  name: "Ethereum" | "ApeCoin" | "The Sandbox";
  balance: string;
}

export const DisplayCrypto = ({ name, balance }: DisplayCryptoProps) => {
  const symb = name == "Ethereum" ? "ETH" : name == "ApeCoin" ? "APE" : "SAND";
  const cryptoImg = name == "Ethereum" ? eth : name == "ApeCoin" ? ape : sand;

  return (
    <div className="display-crypto-container">
      <img src={cryptoImg} style={{ maxWidth: "2.5vw" }} />
      <span style={{ color: "white", fontStyle: "italic", marginTop: "0.5vh" }}>
        {name}
      </span>
      <span style={{ color: "white" }}>{symb}</span>
      <span style={{ color: "white" }}>{`${balance} ${symb}`}</span>
    </div>
  );
};

interface InternalProps {
  setView: any;
  collections: any;
}

export const InternalCollections = ({
  setView,
  collections,
}: InternalProps) => {
  const [page, setPage] = useState(0);

  const begin = collections[page][1]?.address.slice(0, -35);
  const end = collections[page][1]?.address.slice(-4);
  const [update, setUpdate] = useState(false);
  const { t } = useTranslation("customers");

  console.log(collections);

  return (
    <div className="customer-internal-collections">
      {collections.length !== 0 ? (
        <div
          style={{ marginTop: "2vh", display: "flex", flexDirection: "row" }}
        >
          <div className="customer-internal-collections-info">
            <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
              Internal collections
            </span>
            <span style={{ fontWeight: "bold", marginTop: "3vh" }}>
              {collections[page][1].name}
            </span>
            {/* <span style={{ marginTop: "0.5vh" }}>
                {`${begin}...${end}`}
            </span> */}
            <span style={{ marginTop: "0.5vh" }}>
              {!collections[page][0] ? "Type : NFT" : "Type : POA"}
            </span>
            {!collections[page][0] ? (
              <>
                <span style={{ marginTop: "0.5vh" }}>
                  {" "}
                  {collections[page][1].symbol}{" "}
                </span>
                <span style={{ marginTop: "0.5vh" }}>
                  {" "}
                  {`${collections[page][1].nfts.length} NFT collected`}{" "}
                </span>
              </>
            ) : (
              <></>
            )}
            <span style={{ fontWeight: "lighter", fontSize: "small" }}>
              {collections[page][1].description}
            </span>
          </div>
          <div className="customer-internal-collections-content">
            {!collections[page][0] ? (
              collections[page][1].nfts.map((elm: any, idx: number) => {
                if (idx < 3) {
                  return <DisplayNft nft={elm} />;
                }
              })
            ) : (
              <DisplayNft nft={collections[page][1].tmp} />
            )}
          </div>
          <div className="customer-internal-collections-quit">
            <button
              style={{ border: "none", background: "none", cursor: "pointer" }}
              onClick={() => {
                setView("main");
              }}
            >
              <img src={quit} style={{ maxWidth: "5vw" }} />
            </button>
          </div>
        </div>
      ) : (
        <>{t("loading")}</>
      )}
      <div
        style={{
          minWidth: "60vw",
          maxWidth: "65vw",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => {
            if (page !== 0) setPage(page - 1);
            setUpdate(!update);
          }}
          style={{
            color: "white",
            background: "none",
            border: "none",
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          {"< " + t("previousCollection")}
        </button>
        <button
          onClick={() => {
            if (page < collections.length - 1) setPage(page + 1);
            setUpdate(!update);
          }}
          style={{
            color: "white",
            background: "none",
            border: "none",
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          {t("nextCollection") + " >"}
        </button>
      </div>
    </div>
  );
};

interface ExternalProps {
  customerExtData: any;
  setView: any;
}

export const ExternalCollections = ({
  customerExtData,
  setView,
}: ExternalProps) => {
  const [page, setPage] = useState(0);
  const [update, setUpdate] = useState(false);
  const { t } = useTranslation("customers");

  const begin = customerExtData.collections[page]?.contractAddress.slice(
    0,
    -35
  );
  const end = customerExtData.collections[page]?.contractAddress.slice(-4);
  console.log(customerExtData.collections[page]);

  return (
    <div className="customer-external-collections">
      {customerExtData.collections.length !== 0 ? (
        <div
          style={{ marginTop: "2vh", display: "flex", flexDirection: "row" }}
        >
          <div className="customer-external-collections-info">
            <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
              {t("external")}
            </span>
            <span style={{ fontWeight: "bold", marginTop: "3vh" }}>
              {customerExtData.collections[page].name}
            </span>
            <span style={{ marginTop: "0.5vh" }}>{`${begin}...${end}`}</span>
            <span style={{ marginTop: "0.5vh", maxWidth: "18vw" }}>
              {customerExtData.collections[page].description?.slice(0, 50)}...
            </span>
            <span style={{ marginTop: "0.5vh" }}>
              {customerExtData.collections[page]?.symbol}
            </span>
            <span style={{ marginTop: "0.5vh" }}>
              {`${customerExtData.collections[page]?.nfts.length} ${t(
                "collected"
              )}`}
            </span>
          </div>
          <div className="customer-external-collections-content">
            {customerExtData.collections[page].nfts.map(
              (elm: any, idx: number) => {
                if (idx > 3) return;
                return <DisplayNft nft={elm} />;
              }
            )}
          </div>
          <div className="customer-external-collections-quit">
            <button
              style={{ border: "none", background: "none", cursor: "pointer" }}
              onClick={() => {
                setView("main");
              }}
            >
              <img src={quit} style={{ maxWidth: "5vw" }} />
            </button>
          </div>
        </div>
      ) : (
        <>{t("loading")}</>
      )}
      <div
        style={{
          minWidth: "60vw",
          maxWidth: "65vw",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => {
            if (page !== 0) setPage(page - 1);
            setUpdate(!update);
          }}
          style={{
            color: "white",
            background: "none",
            border: "none",
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          {"< " + t("previousCollection")}
        </button>
        <button
          onClick={() => {
            if (page !== customerExtData.collections.length - 1)
              setPage(page + 1);
            setUpdate(!update);
          }}
          style={{
            color: "white",
            background: "none",
            border: "none",
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          {t("nextCollection") + " >"}
        </button>
      </div>
    </div>
  );
};

interface DIsplayNftProps {
  nft: any;
}

const DisplayNft = ({ nft }: DIsplayNftProps) => {
  const [isLoaded, setLoaded] = useState(false);
  const [imgDisplay, setImg] = useState("");
  const [imgReady, setReady] = useState("");

  useEffect(() => {
    setImg(nft.image);
    axios
      .get(`${imgDisplay}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          //'x-pinata-gateway-token' : ''
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        console.log(res);
        const binaryString = Array.from(new Uint8Array(res.data), (v) =>
          String.fromCharCode(v)
        ).join("");
        const theImage = btoa(binaryString);
        setReady(theImage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoaded(true));
  });

  return (
    <>
      {isLoaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            marginRight: "2vw",
          }}
        >
          <img
            src={`data:image/png;base64,${imgReady}`}
            style={{
              maxWidth: "10vw",
              minWidth: "10vw",
              marginTop: "5vh",
              borderRadius: "10px",
            }}
          />
          <span style={{ color: "white", maxWidth: "10vw" }}>{nft.name}</span>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Customer360;
