import QRCode from "react-qr-code";
import accImg from "../../../../assets/mobileIcon/accHamza.png";

interface MobileAccountProps {
  nbNFT: number,
  walletAddr: string
}

export const MobileAccount = ({ walletAddr, nbNFT }: MobileAccountProps) => {

  return (
    <>
      <div className="mobileW-account">
        <div style={{marginBlock: "10px"}}>Welcome back, Hamza El Kacimi</div>
        <img src={accImg} alt="" style={{width: "25%", marginBlock: "20px"}} />
        <div style={{fontSize: "x-large", fontWeight: "bolder", marginBottom: "30px"}}>PREMIUM MEMBER</div>
        <QRCode value={walletAddr} size={100} />
        <div style={{fontSize: "large", fontWeight: "bolder"}}>Statistics</div>
        <div className="mobileW-account-stat">
          <div className="mobileW-account-stat-col">
            <div style={{fontSize: "xx-large", fontWeight: "bolder"}}>{nbNFT}</div>
            <div style={{fontWeight: "bold"}}>Products in wardrobe</div>
          </div>
          <div className="mobileW-account-stat-col">
            <div style={{fontSize: "xx-large", fontWeight: "bolder"}}>23</div>
            <div style={{fontWeight: "bold"}}>Resales on platform</div>
          </div>
          <div className="mobileW-account-stat-col">
            <div style={{fontSize: "xx-large", fontWeight: "bolder"}}>6</div>
            <div style={{fontWeight: "bold"}}>2nd hand purchases</div>
          </div>
        </div>
        <div className="mobileW-account-money">
          <div style={{fontSize: "xx-large", fontWeight: "bolder"}}>€ 6,760</div>
          <div style={{fontWeight: "bold"}}>Wardrobe actual value</div>
        </div>
      </div>
    </>
  );
};

export default MobileAccount;