import { useState, useEffect, useContext } from "react";
import "./Poap.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { backend, GlobalStateContext } from "../../App";
import QRCode from "react-qr-code";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import trash from "../../assets/poubelle.png";
import useFullPageLoader from "src/hooks/UseLoader";
import useEffectOnce from "src/hooks/UseEffectOnce";

interface DetailsPoaProps {
  poas: any[];
  popSnack: any;
  jwt: string;
  promos: any[];
}

export const DetailsPoa = ({
  poas,
  popSnack,
  jwt,
  promos,
}: DetailsPoaProps) => {
  const [poa, setPoa] = useState<any>(null);
  const { t } = useTranslation("poap");
  const addr = window.location.href.split("/")[5];
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [offers, setOffers] = useState<any>(null);

  const get_poa = (poas: any[], addr: String, promos: any): any => {
    let tmp: any = null;
    poas.map((elm, idx) => {
      if (elm.address === addr) {
        tmp = elm;
      }
    });
    let offers = getOffers(promos, tmp.address);
    tmp.offers = offers;
    return tmp;
  };

  const getOffers = (promos: any[], addrPoap: String): any => {
    let offers: any[] = [];
    promos.map((elm, idx1) => {
      elm.addresses.map((addr: any, idx2: any) => {
        if (addr === addrPoap) {
          offers.push(elm);
        }
      });
    });
    return offers;
  };

  console.log(poa);
  useEffectOnce(() => {
    setPoa(get_poa(poas, addr, promos));
  });

  return (
    <>
      <div className="background-content">
        <Link
          to={"/collections"}
          style={{
            color: "#EF19D1",
            textDecoration: "none",
            alignSelf: "start",
            marginLeft: "5vw",
            fontSize: "1.5em",
          }}
          onClick={() => {}}
        >
          {"< " + t("goBack")}
        </Link>
        {poa !== null ? (
          <PoapInfo
            jwt={jwt}
            popSnack={popSnack}
            poa={poa}
            showLoader={showLoader}
            hideLoader={hideLoader}
          />
        ) : (
          <div style={{ color: "white" }}>Loading</div>
        )}
      </div>
      {loader}
    </>
  );
};

interface PaopInfoProps {
  poa: any;
  popSnack: any;
  jwt: string;
  showLoader: any;
  hideLoader: any;
}

const PoapInfo = ({
  poa,
  popSnack,
  jwt,
  showLoader,
  hideLoader,
}: PaopInfoProps) => {
  const [imgDisplay, setImg] = useState("");
  const [nbmint, setNbMint] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation("poap");
  const frontend = process.env.REACT_APP_FRONT || "http://172.20.10.4:3000";
  const urlRedirect = frontend + "/wallet?collec";
  const context = useContext(GlobalStateContext);
  const [refresh, setRefresh] = useState(true);
  console.log("offers", poa.offers);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;
  useEffect(() => {
    axios
      .get(
        `${poa.imgs[0]}?pinataGatewayToken=aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          responseType: "arraybuffer",
        }
      )
      .then((res) => {
        const binaryString = Array.from(new Uint8Array(res.data), (v) =>
          String.fromCharCode(v)
        ).join("");
        const theImage = btoa(binaryString);
        setImg(theImage);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post(
        `${backend}/poap/minted`,
        { address: poa.address },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        setNbMint(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const removePromotion = (code: string) => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/promo/removepromo`,
        { code: code, addr: poa.address },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        console.log(resp);
        popSnack(
          "The promo has been successfully removed from the collection",
          "success"
        );
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        if (poa.offers != undefined) {
          for (let i = 0; i < poa.offers.length; i++) {
            if (poa.offers[i].code === code) {
              poa.offers[i] = poa.offers[poa.offers.length - 1];
              poa.offers.pop();
              break;
            }
          }
        }
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack("An error occured while removing the promo", "error");
      });
  };

  return (
    <div className="mid-poap">
      <div className="mid-detail-poap">
        <div className="poap-info">
          <div className="poap-info-image">
            <img
              src={`data:image/png;base64,${imgDisplay}`}
              className="poap-img"
            />
          </div>
          <div className="poap-info-txt">
            <span style={{ color: "white" }}>{poa.eventName}</span>
            <span style={{ fontWeight: "initial", fontSize: "0.5em" }}>
              {poa.date}
            </span>
            <span
              style={{
                fontWeight: "initial",
                fontSize: "0.6em",
                marginTop: "3vh",
              }}
            >
              {poa.description}
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                flexDirection: "row",
              }}
            >
              <span
                style={{
                  marginRight: "1vw",
                  color: "#EF19D1",
                  fontSize: "2em",
                }}
              >{`${nbmint} `}</span>
              <span
                style={{
                  color: "white",
                  fontWeight: "initial",
                  marginBottom: "0.7vh",
                }}
              >
                {t("minted")}
              </span>
            </div>
          </div>
        </div>
        <div className="poap-QRcode">
          <QRCode
            size={256}
            style={{ width: "90%" }}
            value={`${urlRedirect}=${poa.address}&type=poap`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
      <div className="mid-offers-poap">
        <div className="mid-offer-privilege">{t("ListPrivilege")}</div>
        {poa.offers.length > 0 ? (
          poa.offers.map((elm: any, idx: any) => {
            return (
              <div className="mid-offer-poap">
                <div className="mid-offer-txt">
                  <div className="mid-offer-title">{elm.name}</div>
                  <div className="mid-offer-desc">{elm.description}</div>
                </div>
                <div
                  className="mid-offer-delete"
                  onClick={() => {
                    removePromotion(elm.code);
                  }}
                >
                  <img src={trash} style={{ width: "35px" }} />
                </div>
              </div>
            );
          })
        ) : (
          <div className="mid-offer-poap" style={{ background: "unset" }}>
            <div className="mid-offer-txt">
              <div className="mid-offer-desc">{t("noPrivilege")}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailsPoa;
