import { useState } from "react";
import "../MobileApp.scss";
import "./MobileWallet.scss";
import { backend } from "src/App";
import axios from "axios";
import useFullPageLoader from "src/hooks/UseLoader";
import useEffectOnce from "src/hooks/UseEffectOnce";
import CollectionsWallet from "./CollectionsWallet";
import SignOut from "src/assets/sign-out.png";
import DeleteIcon from "src/assets/delete.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import DetailNFT from "./DetailNFT/DetailNFT";
import CertificateNFT from "./DetailNFT/CertificateNFT";
import TransferNFT from "./DetailNFT/TransferNFT";
import Chat from "./DetailNFT/Chat";
import HistoryNFT from "./DetailNFT/HistoryNFT";
import Privilege from "./DetailNFT/Privilege";
import Lost from "./DetailNFT/Lost";
import { useTranslation } from "react-i18next";
import { PopupQrCode } from "src/components/Popup/PopupQrcode";
import { Client } from "@xmtp/xmtp-js";
import { ethers } from "ethers";

import ByBearing from "src/assets/mobileIcon/bearingBlack.png";
import menuBurgerBlack from "src/assets/mobileIcon/menuBurgerBlack.png";
import angleys from "src/assets/mobileIcon/logoAngleysBack.png";

import wardrobe from "src/assets/mobileIcon/wardrobe.png";
import priv from "src/assets/mobileIcon/privilege.png";
import chat from "src/assets/mobileIcon/chat.png";
import account from "src/assets/mobileIcon/account.png";
import Quit from "src/assets/quit.png";
import Qrcode from "src/assets/passport/qrcode.png";
import Account from "./DetailNFT/Account";
import Insurance from "./DetailNFT/Insurance";
import Resell from "./DetailNFT/Resell";

interface MobileWalletProps {
  allClients: any;
  popSnack: any;
  jwt: string;
}
export const MobileWallet = ({
  allClients,
  popSnack,
  jwt,
}: MobileWalletProps) => {
  const [page, setPage] = useState(1);
  const [collec, setCollec] = useState(undefined);
  const [offers, setOffers] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [openQrcode, setOpenQrcode] = useState(false);

  const [customerProd, setCustomerProd] = useState<any[]>([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [coladdr, setColAddr] = useState("");
  const [nft, setnft] = useState<{ name?: string } | undefined>(undefined);
  const [queryParameters] = useSearchParams();
  const addr = queryParameters.get("wallet") || "none";
  const { t } = useTranslation("mobileWallet");
  const [clientXmtp, setXmtp] = useState<Client>();
  const [file, setfile] = useState("");
  const [nbNft, setNbNft] = useState(0);

  const navigate = useNavigate();

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const calculateNbNft = (collections: any) => {
    if (collections === undefined) {
      return;
    }
    let nftnb = 0;
    collections.map((collection: any) => {
        nftnb += collection.nfts.length;
    })
    console.log(nftnb)
    setNbNft(nftnb);
  }

  const fetchAll = async () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    if (addr !== undefined) {
      await timeout(2000);
      axios
        .post(
          `${backend}/alldatas/client`,
          { addr: addr },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API,
              Authorization: localStorage.getItem("token") || "",
            },
          }
        )
        .then((resp) => {
          console.log("dpp fetched : ", resp.data.product);
          setCustomerProd(resp.data.product);
          calculateNbNft(resp.data.product);
          setLoading(true);
          if (typeof hideLoader === "function") {
            hideLoader();
          }
        })
        .catch((err) => {
          console.log(err);
          if (typeof hideLoader === "function") {
            hideLoader();
          }
        });
    }
    //const wallet = undefined;
    const wallet = new ethers.Wallet(
      localStorage.getItem("privatekeyXmtp") || ""
    );
    if (wallet !== undefined) {
      //const xmtp = undefined;
      console.log("wallet", wallet);
      const xmtp = await Client.create(wallet, { env: "production" });
      console.log("xmtpppp", xmtp);
      setXmtp(xmtp);
    }
  };

  const sendnft = () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/nft/send`,
        {
          contractAddress: localStorage.getItem("collec"),
          wallet: addr,
          tokenId: localStorage.getItem("idNft"),
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        console.log("nft sent to", addr);
        popSnack("The digital passeport is now available", "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        fetchAll();
      }).catch(() => {
        popSnack("This digital passeport is already claimed", "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
      });
  };

  useEffectOnce(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const privateXmtp = queryParameters.get("pK");
    if (privateXmtp != undefined && privateXmtp != "") {
      localStorage.setItem("privatekeyXmtp", privateXmtp);
    }
    const tok = queryParameters.get("token");
    localStorage.setItem("token", tok || "");

    // if (window?.navigator?.platform == "iPhone") {
    //   window.location.assign("deeplink://connected?jwt=" + jwt);
    // }
    if (localStorage.getItem("idNft") != undefined) {
      console.log("helloooooo", localStorage.getItem("idNft"));
      sendnft();
    } else {
      fetchAll();
    }
  });

  let titles: string[] = [
    "MY WARDROBE",
    "MY PRODUCT",
    "PRIVILEGES",
    "CHAT",
    "ACCOUNT",
    "CERTIFICATE",
    "HISTORY",
    "TRANSFER",
    "DOCUMENTS",
    "LOSS & THEFT",
    "INSURANCE",
    "RESELL"
  ];
  let current_title = titles[page - 1];

  const deleteClient = async () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    if (addr !== undefined) {
      await timeout(2000);
      axios
        .post(
          `${backend}/client/delete`,
          { address: addr },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API,
              Authorization: localStorage.getItem("token") || "",
              // 'Content-Type': 'application/json' // au besoin
            },
          }
        )
        .then((resp) => {
          // Do something with the response if needed
          if (typeof hideLoader === "function") {
            hideLoader();
          }
        })
        .catch((err) => {
          console.log(err);
          if (typeof hideLoader === "function") {
            hideLoader();
          }
        });
    }
  };

  const Slider = ({
    isOpen,
    onClose,
  }: {
    isOpen: boolean;
    onClose: () => void;
  }) => {
    const handleLogout = () => {
      // perform logout and account deletion here
      navigate("/wallet");
    };

    const handledeleteAccount = async () => {
      await deleteClient();
      navigate("/wallet");
    };

    return (
      <div
        style={{
          position: "fixed",
          right: 0,
          top: 0,
          width: "35vw", // change this as needed
          height: "100vh",
          background: "#1C223B", // change this as needed
          transform: isOpen ? "translateX(0)" : "translateX(100%)",
          transition: "transform 0.3s ease-in-out",
          zIndex: 1000,
          color: "#ffffff", // Assuming you want white color for the text
          padding: "10px", // Providing some space for the contents
        }}
      >
        <button
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            background: "transparent",
            border: "none",
          }}
          onClick={onClose}
        >
          <img src={Quit} alt="Close" style={{ width: "20px" }} />
        </button>
        <div style={{ marginTop: "50%" }}>
          {" "}
          {/* Move content to the center */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <p style={{ marginRight: "10px", fontSize: "14px" }}>
                {t("Qrcode")}
              </p>
              <button
                style={{ background: "transparent", border: "none" }}
                onClick={() => {
                  setOpenQrcode(true);
                }}
              >
                <img src={Qrcode} alt="Sign Out" style={{ width: "20px" }} />
              </button>
              <PopupQrCode
                open={openQrcode}
                setOpen={setOpenQrcode}
                value={addr}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <p style={{ marginRight: "0px", fontSize: "14px" }}>
                {t("signout")}
              </p>
              <button
                style={{ background: "transparent", border: "none" }}
                onClick={handleLogout}
              >
                <img src={SignOut} alt="Sign Out" style={{ width: "20px" }} />
              </button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ marginRight: "10px", fontSize: "14px" }}>
                {t("deleteAcc")}
              </p>
              <button
                style={{ background: "transparent", border: "none" }}
                onClick={handledeleteAccount}
              >
                <img
                  src={DeleteIcon}
                  alt="Delete Account"
                  style={{ width: "20px" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const noscroll = { overflow: "unset" };
  const scroll = { overflow: "scroll" };


  return (
    <>
      <div className="mobileWallet-background">
        <div>
          <div className="mobileWallet-company">
            <img src={angleys} alt="" style={{width: "100%"}}/>
          </div>
          <div className="wallet-header">
            <div className="wallet-back">
              {page <= 1 ? (
                <></>
              ) : (
                <button
                  className="wallet-back-btn"
                  onClick={() => {
                    if (page > 2) {
                      setPage(2);
                    } else {
                      setPage(page - 1);
                    }
                  }}
                >
                  <span className="wallet-back-txt" style={{ color: "black" }}>
                    &#60;&#32;{t("Back")}
                  </span>
                </button>
              )}
            </div>
            <div
              className="mobileWallet-title"
            >
              {current_title}
            </div>
            <div>
              <button
                className="wallet-btn-signout"
                onClick={() => setSliderOpen(true)}
              >
                <img
                  src={menuBurgerBlack}
                  alt="Menu"
                  className="wallet-img-signout"
                  style={{ width: "20px" }}
                />
              </button>
            </div>
          </div>
          <div className="mobileW-separator" />
          <div className="wallet-content" style={page == 4 ? noscroll : scroll}>
            {!isLoading ? (
              <></>
            ) : page === 1 ? (
              <CollectionsWallet
                setPage={setPage}
                collections={customerProd}
                setCollec={setCollec}
                setOffers={setOffers}
                setColAddr={setColAddr}
                setnft={setnft}
              />
            ) : page === 2 ? (
              <DetailNFT
                jwt={jwt}
                collec={collec}
                nft={nft}
                setPage={setPage}
                popSnack={popSnack}
                coladdr={coladdr}
                setfile={setfile}
              />
            ) : page === 3 ? (
              <Privilege collec={collec} offers={offers} />
            ) : page === 4 ? (
              <Chat clientXmtp={clientXmtp} />
            ) : page === 5 ? (
              <Account nbNFT={nbNft} walletAddr={addr}/>
            ) : page === 6 ? (
              <CertificateNFT file={file} page={page} setpage={setPage} direct={true} />
            ) : page === 7 ? (
              <HistoryNFT
                jwt={jwt}
                nft={nft}
                coladdr={coladdr}
                allClients={allClients}
              />
            ) : page === 8 ? (
              <TransferNFT
                nft={nft}
                coladdr={coladdr}
                popSnack={popSnack}
                jwt={jwt}
              />
            ) : page === 9 ? (
              <CertificateNFT file={file} page={page} setpage={setPage} direct={false}/>
            ) : page === 10 ? (
              <Lost popSnack={popSnack} nft={nft} coladdr={coladdr}/>
            ) : page === 11 ? (
              <Insurance />
            ) : page === 12 ? (
              <Resell />
            ) : (
              <></>
            )}
          </div>
          <div className="mobile-footer">
            {page === 1 ? (
              <img src={ByBearing} className="mobileWwallet-img-bpt" />
            ) : (
              <div className="mobileWallet-footer">
                <div
                  onClick={() => setPage(2)}
                  className="mobileWallet-footer-elem"
                >
                  <img src={wardrobe} className="mobileWallet-footer-img" />
                  <p className="mobileWallet-footer-txt">Wardrobe</p>
                </div>
                <div
                  onClick={() => setPage(3)}
                  className="mobileWallet-footer-elem"
                >
                  <img src={priv} className="mobileWallet-footer-img" />
                  <p className="mobileWallet-footer-txt">Privileges</p>
                </div>
                <div
                  onClick={() => setPage(4)}
                  className="mobileWallet-footer-elem"
                >
                  <img src={chat} className="mobileWallet-footer-img" />
                  <p className="mobileWallet-footer-txt">Chat</p>
                </div>
                <div
                  onClick={() => setPage(5)}
                  className="mobileWallet-footer-elem"
                >
                  <img src={account} className="mobileWallet-footer-img" />
                  <p className="mobileWallet-footer-txt">Account</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Slider isOpen={isSliderOpen} onClose={() => setSliderOpen(false)} />
      {loader}
    </>
  );
};

export default MobileWallet;
