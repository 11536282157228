import { TextField } from "@mui/material";
import "../../MobileApp.scss";
import { useContext, useState } from "react";
import { PopupChoice as Popup } from "src/components/Popup/Popup";
import useFullPageLoader from "src/hooks/UseLoader";
import axios from "axios";
import { backend, GlobalStateContext } from "src/App";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

interface TransferNFTProps {
  nft: any;
  coladdr: string;
  popSnack: any;
  jwt: string;
}

export const TransferNFT = ({
  nft,
  coladdr,
  popSnack,
  jwt,
}: TransferNFTProps) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [queryParameters] = useSearchParams();
  const ownerAddr = queryParameters.get("wallet") || "none";
  const { t } = useTranslation("mobileWallet");
  const context = useContext(GlobalStateContext);
  const frontend = process.env.REACT_APP_FRONT || "http://172.20.10.4:3000";

  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar } = context;

  const titlepopup = "Transfer NFT";
  const descriptionpopup =
    "Are you sure you want to transfer this NFT to " + email + "?";

  const handleClick = () => {
    setOpen(true);
  };

  // transfer autorized by the company
  const sendNft = () => {
    setOpen(false);
    if (typeof showLoader === "function") {
      showLoader();
    }
    console.log("nftid", nft.id);

    axios
      .post(
        `${backend}/transferauth/add`,
        {
          collAddr: coladdr,
          idToken: nft.name.split("#")[1],
          walletFrom: ownerAddr,
          emailTo: email,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: jwt,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        console.log(resp);
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack("Transfer Request successfully registered", "success");
      })
      .catch((err) => {
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack(
          "Error occured while registering the request transfer of the nft",
          "error"
        );
        console.log(err);
      });
  };

  const transferMethod = () => {
    if (email != "") {
      sendEmail()
    } else if (address != "") {
      sendToAddress()
    } else {
      popSnack("fill email or account Id information", "warning")
    }
  }

  const sendToAddress = () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(`${backend}/nft/adminWallet`, {
        contractAddress: coladdr,
      })
      .then((resp) => {
        axios
          .post(`${backend}/client/clientTransfer`, {
            wallet: address,
            from: ownerAddr,
            contractAddr: coladdr,
            tokenId: nft.id + 1,
            vault: resp.data.vault,
          })
          .then(() => {
            if (typeof hideLoader === "function") {
              hideLoader();
            }
            popSnack("Transfer successfull", "success");
          })
          .catch((err) => {
            if (typeof hideLoader === "function") {
              hideLoader();
            }
            popSnack(t("emailError"), "error");
          });
      })
      .catch((err) => {
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack(t("emailError"), "error");
      });
  };

  const sendEmail = () => {
    if (email == "") {
      popSnack(t("fillEmail"), "warning");
      return;
    }
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(`${backend}/nft/adminWallet`, {
        contractAddress: coladdr,
      })
      .then((resp) => {
        axios
          .post(`${backend}/client/clientTransfer`, {
            wallet: resp.data.address,
            from: ownerAddr,
            contractAddr: coladdr,
            tokenId: nft.id + 1,
            vault: resp.data.vault,
          })
          .then(() => {
            var templateParams = {
              to_name: "John Doe",
              from_name: "Hermès Customer Service",
              link_to: `${frontend}/wallet?collec=${coladdr}&idNFT=${
                nft.id + 1
              }`,
              to_email: email,
            };
            emailjs
              .send(
                "NFT_Experience",
                "template_iywots5",
                templateParams,
                "dI1NV4PWOS0ci1eLZ"
              )
              .then(
                function (response: any) {
                  if (typeof hideLoader === "function") {
                    hideLoader();
                  }
                  popSnack("the email was successfully sent", "success");
                  console.log("SUCCESS!", response.status, response.text);
                },
                function (error: any) {
                  if (typeof hideLoader === "function") {
                    hideLoader();
                  }
                  popSnack(t("emailError"), "error");
                }
              );
          })
          .catch((err) => {
            if (typeof hideLoader === "function") {
              hideLoader();
            }
            popSnack(t("emailError"), "error");
          });
      })
      .catch((err) => {
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack(t("emailError"), "error");
      });
  };

  return (
    <>
      <div className="transfer">
        <p style={{ fontWeight: "bold" }}>
          Please indicate hereafter the email address of the recipient
        </p>
        <TextField
          autoComplete="off"
          variant="outlined"
          label="Email"
          style={{
            minWidth: "80vw",
            marginInline: "3vw",
            background: "#f2f2f2",
            borderRadius: "10px",
            fontSize: "30px",
            marginBlock: "15px",
          }}
          sx={{ input: { color: "black" } }}
          className="wallet-txtfield"
          value={email}
          onChange={(e) => (setEmail(e.target.value), setAddress(""))}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "3vh",
            marginBlock: "2vh",
          }}
        >
          <div
            style={{
              width: "38%",
              border: "0.5px solid black",
              marginRight: "5px",
            }}
          />
          OR
          <div
            style={{
              width: "38%",
              border: "0.5px solid black",
              marginLeft: "5px",
            }}
          />
        </div>
        <p style={{ fontWeight: "bold" }}>
          Please indicate hereafter the account Id of the recipient (scan and paste the QR code of the recipient's account id)
        </p>
        <div className="transfer-addr">
          <TextField
            autoComplete="off"
            variant="outlined"
            label="Account Id"
            style={{
              minWidth: "80%",
              marginInline: "3vw",
              background: "#f2f2f2",
              borderRadius: "10px",
              fontSize: "30px",
              marginBlock: "15px",
              height: "fit-content"
            }}
            sx={{ input: { color: "black" } }}
            className="wallet-txtfield"
            value={address}
            onChange={(e) => (setAddress(e.target.value), setEmail(""))}
          />
          <div className="transfer-btn-comp">
            <div
              onClick={async () => {
                setAddress(await navigator.clipboard.readText());
                console.log(address);
              }}
              className="transfer-btn-paste"
            >
              <span style={{fontSize: "smaller"}}>Paste</span>
            </div>
          </div>
        </div>
        <div className="nft-btn" style={{ marginTop: "30px" }}>
          <div onClick={() => transferMethod()} className="mobileW-btn-sec">
            <p style={{ padding: "0px", margin: "Opx", marginTop: "0.45vh" }}>
              Validate transfer
            </p>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default TransferNFT;
