import React from "react";
import i18n from "../../i18n";
import chinese from "../../assets/flag/chinese.png";
import france from "../../assets/flag/france.jpeg";
import england from "../../assets/flag/england.jpeg";
import italy from "../../assets/flag/italy.png";
import "./Language.css";
import { Collapse, List, ListItemButton, ListItemIcon } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export const Languages = () => {
  const lngs = {
    fr: "1",
    it: "2",
    ch: "3",
    en: "4",
  };

  const diclngs = {
    "1": france,
    "2": italy,
    "3": chinese,
    "4": england,
  };

  const currentLanguageKey = i18n.language as keyof typeof lngs;
  const initialLanguageKey = lngs[currentLanguageKey] ? currentLanguageKey : "en"; // Utilisation de la langue par défaut si la langue actuelle n'est pas trouvée

  const [selectLanguage, setSelectLanguage] = React.useState(lngs[initialLanguageKey]);

  console.log("selectedLanguage", selectLanguage);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className="img-language">
      <List
        sx={{ width: "100%", maxWidth: 50 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={handleClick}>
          <ListItemIcon sx={{ width: "3vw", minWidth: "fit-content" }}>
            <img
              src={diclngs[selectLanguage as keyof typeof diclngs]}
              alt="flag"
              className="img-flag"
            />
          </ListItemIcon>
          {open ? (
            <ExpandLess sx={{ color: "white", marginRight: "-10px" }} />
          ) : (
            <ExpandMore sx={{ color: "white", marginRight: "-10px" }} />
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.entries(lngs)
              .filter(([key]) => key !== i18n.language)
              .map(([key, value]) => (
                <ListItemButton key={key}>
                  <ListItemIcon sx={{ width: "3vw", minWidth: "fit-content", zIndex: 100 }}>
                    <img
                      src={diclngs[value as keyof typeof diclngs]}
                      onClick={() => {
                        setSelectLanguage(lngs[key as keyof typeof lngs]);
                        i18n.changeLanguage(key);
                        handleClick();
                      }}
                      alt="flag"
                      className="img-flag"
                    />
                  </ListItemIcon>
                </ListItemButton>
              ))}
          </List>
        </Collapse>
      </List>
    </div>
  );
};
