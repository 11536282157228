import React, { useEffect, useState } from "react";

import "./Role.css";
import eth from "../../assets/eth-white2.png";
import avax from "../../assets/avax-png.png";
import polygon from "../../assets/polygon-png.png";
import tezos from "../../assets/tezos.png";
import Popup from "../Popup/PopupChoice";
import { useTranslation } from "react-i18next";

interface NetworkProps {
  setConfig: any;
  network: string;
}

export const NetworkSelection = ({ setConfig, network }: NetworkProps) => {
  const [open, setOpen] = useState(false);
  const [isShown, setShown] = useState(false);
  const { t } = useTranslation("administration");

  useEffect(() => {
    if (!isShown && network !== "") {
      setShown(true);
    }
  });
  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <PageBlockchain setBlockchain={setConfig} network={network} />
      </div>
      {network !== "" ? (
        <div
          style={{
            position: "absolute",
            bottom: "200px",
            color: "white",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          {t("Network")}
        </div>
      ) : null}
    </div>
  );
};

interface PageProps {
  setBlockchain: any;
  network: string;
}

export const PageBlockchain = ({ setBlockchain, network }: PageProps) => {
  let greyed: number[] = [];
  if (network == "") {
    greyed = [4];
  } else if (network == "Ethereum") {
    greyed = [2, 3, 4];
  } else if (network == "Avalanche") {
    greyed = [1, 3, 4];
  } else if (network == "Polygon") {
    greyed = [1, 2, 4];
  }
  console.log(greyed, network);

  return (
    <>
      <Blockchain
        network={network}
        setBlockchain={setBlockchain}
        img={eth}
        name="Ethereum"
        grey={greyed.includes(1)}
      />
      <Blockchain
        network={network}
        setBlockchain={setBlockchain}
        img={avax}
        name="Avalanche"
        grey={greyed.includes(2)}
      />
      <Blockchain
        network={network}
        setBlockchain={setBlockchain}
        img={polygon}
        name="Polygon"
        grey={greyed.includes(3)}
      />
      <Blockchain
        network={network}
        setBlockchain={setBlockchain}
        img={tezos}
        name="Tezos"
        grey={greyed.includes(4)}
      />
    </>
  );
};

interface BlockchainProps {
  img: string;
  name: string;
  setBlockchain: any;
  grey: boolean;
  network: string;
}

const Blockchain = ({
  img,
  name,
  setBlockchain,
  grey,
  network,
}: BlockchainProps) => {
  const [open, setOpen] = useState(false);

  const setPermanentBlockchain = () => {
    setBlockchain(name);
  };

  return (
    <div
      onClick={() => (network === "" ? setOpen(true) : {})}
      className="selected-bc"
      style={{
        marginLeft: "3vw",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        cursor: grey ? "" : "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        borderRadius: "20px",
        opacity: grey ? 0.3 : 1,
      }}
    >
      <Popup
        open={open}
        setOpen={setOpen}
        title="This action is irreversible"
        content={`Do you want to select ${network} as your blockchain ?`}
        callFunction={setPermanentBlockchain}
      />
      <img src={img} style={{ minWidth: "8vw", maxWidth: "8vw" }} />
      <span
        style={{
          fontSize: "1.5em",
          fontWeight: "bold",
          marginTop: "5vh",
          color: "#e2e2e2",
        }}
      >
        {name}
      </span>
    </div>
  );
};

export default NetworkSelection;
