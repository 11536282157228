import React, {useState} from "react"
import CustomerTable from "./TableV2"
import StepTitle from "../StepTitle/StepTitle"
import CustomerDetails from "./CustomersDetails"

import "./Customers.css"
import { useTranslation } from "react-i18next"

interface CustomerProps {
    jwt: string
}

export const Customers = ({jwt}: CustomerProps) => {

    const [selected, setSelected] = useState(-1)
    const [customer, setCustomer] = useState<{email: string, id: number, name: string, wallets: string[]}>()
    const {t} = useTranslation("customers");
    
    return <div className="customers-background">
        <div style={{marginTop: "25vh"}}></div>
        <div className="create-head-3">
            <h2 style={{color: '#078BF9'}}>
                {t('relationship')}
            </h2>
            <span style={{color: '#e2e2e2'}}>
                {t('customerActivities')}
            </span>
        </div>
        <div className="customer-table">
        {customer == undefined ? <CustomerTable jwt={jwt} setUser={setCustomer} selected={selected} setSelected={setSelected} /> : <CustomerDetails jwt={jwt} customer={customer} selected={selected} setSelected={setSelected}/>}           
        </div>

    </div>
}

export default Customers


