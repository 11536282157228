import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import { backend } from "src/App";
import axios from "axios";

function truncate(str: string, n: number) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

interface ChatDirectProps {
  owner: string;
  clientXmtp: any;
  allClients: any[];
  setChat: any;
}

export const ChatDirect = ({
  owner,
  clientXmtp,
  allClients,
  setChat,
}: ChatDirectProps) => {
  const [msgs, setMsgs] = useState<
    {
      name: string;
      message: string;
      conv: any[];
      addr: string;
      conversation: any;
      isNotif: boolean;
    }[]
  >([]);
  const [msgSend, setMsgSend] = useState("");
  const { t } = useTranslation("passportProduct");

  const [fetch, setFetch] = useState(false);

  const fetchTime = 1000;

  // get wallet xmtp from owner avec une requete back end

  const fetchConvos = async () => {
    console.log("in convo");
    if (clientXmtp !== undefined) {
      const allConversations = await clientXmtp.conversations.list();

      let convos = [];
      for (const conversation of allConversations) {
        const tmpMsgs = [];
        let push = false;
        for await (const page of conversation.messagesPaginated({
          pageSize: 100,
        })) {
          for (const msg of page) {
            if (msg.senderAddress !== clientXmtp.address) {
              push = true;
            }
            tmpMsgs.push({ content: msg.content, sender: msg.senderAddress });
          }
        }
        if (push) {
          let tmpObj = {
            name: conversation.peerAddress,
            message: tmpMsgs.length > 0 ? tmpMsgs[0].content : "",
            conv: tmpMsgs,
            addr: conversation.peerAddress,
            conversation: conversation,
            isNotif: false,
          };
          convos.push(tmpObj);
        }
      }

      setMsgs(convos.reverse());
      setFetch(true);
      console.log("Get convs", convos.reverse());
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchConvos();
    }, fetchTime);

    return () => clearInterval(interval);
  }, []);

  const [ownerXmtp, setOwnerXmtp] = useState("");

  const fetchXmtpOwner = async () => {
    axios.post(`${backend}/nft/addrXmtp`, { addr: owner }).then((res) => {
      setOwnerXmtp(res.data);
      console.log("owner :", owner);
      console.log("xmtp :", res.data);
    })
  }

  useEffect(() => {
    fetchXmtpOwner();
  }, []);

  const sendMessage = async () => {
    let send = msgSend;
    setMsgSend("");
    console.log("msg sent");
    let conversation;
    msgs.map((elm) => {
      if (elm.addr === ownerXmtp) {
        conversation = elm.conversation;
      }
    });
    conversation = await clientXmtp.conversations.newConversation(ownerXmtp);

    await conversation.send(send);
  };

  const matchAddr = (addr: string) => {
    let resAddr = "";
    allClients?.map((elm: any) => {
      if (elm.xmtp === "") {
        if (elm.wallets[0] === addr) {
          resAddr = elm.name;
        }
      } else {
        if (elm.xmtp === addr) {
          resAddr = elm.name;
        }
      }
    });
    if (resAddr === "") {
      return truncate(addr, 20)
    };

    return resAddr;
  };

  return (
    <div className="send-background">
      <button
        style={{
          color: "#EF19D1",
          background: "none",
          border: "none",
          cursor: "pointer",
          textDecoration: "none",
          alignSelf: "start",
          marginLeft: "5vw",
          marginTop: "15vh",
        }}
        onClick={() => {
          setChat(false);
        }}
      >
        {t("goBack")}
      </button>
      <div className="chat-messages">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            minWidth: "90%",
            maxWidth: "90%",
            alignItems: "center",
            flexDirection: "row",
            marginTop: "4%",
            height: "5vh",
          }}
        >
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "1.5em",
            }}
          >
            {matchAddr(owner)}
          </span>
        </div>
        <div
          className="chat-content"
          style={{
            minWidth: "90%",
            maxWidth: "90%",
            height: "47vh",
            marginTop: "5vh",
            overflow: "scroll",
            display: "flex",
            flexDirection: "column-reverse",
            marginBottom: "3%",
          }}
        >
          {fetch && msgs
            .filter((elm) => elm.name === ownerXmtp)[0]
            ?.conv.map((elm: any, idx: number) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent:
                      elm.sender === clientXmtp.address ? "end" : "start",
                    alignItems: "center",
                    width: "90%",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      backgroundColor:
                        elm.sender === clientXmtp.address
                          ? "#8007F9"
                          : "#078BF9",
                      paddingTop: "0.5vw",
                      paddingBottom: "0.5vw",
                      paddingRight: "1vw",
                      paddingLeft: "1vw",
                      borderRadius: "100px",
                      marginTop: "1vh",
                      textAlign:
                        elm.sender === clientXmtp.address ? "right" : "left",
                    }}
                  >
                    {elm.content}
                  </span>
                </div>
              );
            })}
        </div>
        <div
          style={{
            minWidth: "90%",
            maxWidth: "90%",
            height: "5vh",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            value={msgSend}
            onChange={(e) => {
              setMsgSend(e.target.value);
            }}
            className="chat-input"
            style={{
              backgroundColor: "white",
              width: "85%",
              borderRadius: "100px",
              border: "none",
              paddingLeft: "3%",
            }}
          />
          <button
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              sendMessage();
            }}
          >
            <SendIcon style={{ color: "#EF19D1" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatDirect;
