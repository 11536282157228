import "../../MobileApp.scss";
import CardWallet from "../CardWallet";
import useEffectOnce from "src/hooks/UseEffectOnce";

import tool from "src/assets/mobileIcon/tool.png";
import bag from "src/assets/mobileIcon/bag.png";
import productLost from "src/assets/mobileIcon/productLost.png";
import insurance from "src/assets/mobileIcon/Insurance.png";
import paper from "src/assets/mobileIcon/paper.png";
import change from "src/assets/mobileIcon/change.png";
import history from "src/assets/mobileIcon/history.png";

import traca from "src/assets/mobileIcon/tracability.png";
import ecoscore from "src/assets/mobileIcon/ecoscore.png";
import certifComp from "src/assets/mobileIcon/certicationComp.png";

interface DetailNFTProps {
  collec: any;
  nft: any;
  setPage: any;
  popSnack: any;
  coladdr: string;
  jwt: string;
  setfile: any;
}

export const DetailNFT = ({
  collec,
  nft,
  setPage,
  popSnack,
  coladdr,
  jwt,
  setfile,
}: DetailNFTProps) => {
  console.log("nft", nft);

  useEffectOnce(() => {
    const urlsplit = nft.certif.split("/");
    const docName =
      urlsplit[urlsplit.length - 1] ||
      "7a7634bf-cd5c-4ba2-97c4-9daca4116ef9.pdf";
    setfile([docName]);
  });

  return (
    <>
      {/* {nft.lost ? <div className="mobileWwallet-lost">
        Product lost or stolen
      </div> : <></>} */}
      <CardWallet
        collection={collec}
        image={nft.image}
        toptxt={true}
        islost={nft.lost}
      />
      <div className="wallet-card-item">
        <div
          className="mobileW-btn"
          onClick={() => {
            setPage(6);
          }}
        >
          <p style={{ padding: "0px", margin: "Opx", marginTop: "1vh" }}>
            Get my Digital Certificate
          </p>
        </div>
      </div>
      <div>
        <p className="mobileW-txt-title-sec">Services</p>
        <div className="mobileW-redirect" onClick={() => setPage(7)}>
          <img src={history} alt="" style={{ width: "7%" }} />
          <div className="mobileW-txt-title-red">My Product History</div>
          <p>&gt;</p>
        </div>
        {nft.lost ? (
          <></>
        ) : (
          <div className="mobileW-redirect" onClick={() => setPage(8)}>
            <img src={change} alt="" style={{ width: "7%" }} />
            <p className="mobileW-txt-title-red">Transfer my product</p>
            <p>&gt;</p>
          </div>
        )}
        <div className="mobileW-redirect" onClick={() => setPage(9)}>
          <img src={paper} alt="" style={{ width: "7%" }} />
          <p className="mobileW-txt-title-red">Access my documents</p>
          <p>&gt;</p>
        </div>

        <div className="mobileW-redirect" onClick={() => setPage(10)}>
          <img src={productLost} alt="" style={{ width: "7%" }} />
          <p className="mobileW-txt-title-red">
            {nft.lost ? <>Unlock my product</> : <>Declare my product lost or stolen</>}
          </p>
          <p>&gt;</p>
        </div>
        {nft.lost ? (
          <></>
        ) : (
          <>
            <div className="mobileW-redirect" onClick={() => setPage(11)}>
              <img src={insurance} alt="" style={{ width: "7%" }} />
              <p className="mobileW-txt-title-red">
                Subscribe to product insurance
              </p>
              <p>&gt;</p>
            </div>
            <div className="mobileW-redirect" onClick={() => setPage(12)}>
              <img src={bag} alt="" style={{ width: "7%" }} />
              <p className="mobileW-txt-title-red">Instant Resell</p>
              <p>&gt;</p>
            </div>
            <div className="mobileW-redirect">
              <img src={tool} alt="" style={{ width: "7%" }} />
              <p className="mobileW-txt-title-red" style={{color: "grey"}}>Request Care services</p>
              <p style={{color: "grey"}}>&gt;</p>
            </div>
          </>
        )}
      </div>
      <div>
        <p className="mobileW-txt-title-sec">Our Commitments</p>
        <p className="mobileW-txt-content">
          At Maison Angleys we believe in looking good and feeling good about
          the impact we make. That's why we're committed to sustainable
          practices throughout our entire fashion journey. From the materials we
          source to the way we produce our garments, we're constantly innovating
          to minimize our environmental footprint and empower a more conscious
          way of dressing.
        </p>
        <a
          className="wallet-card-item"
          href="https://www.bearingpoint.com/en/"
          target="_blank"
        >
          <div className="mobileW-btn-sec">
            <p style={{ padding: "0px", margin: "Opx", marginTop: "0.45vh" }}>
              Read more about Maison Angleys
            </p>
          </div>
        </a>
      </div>
      <div>
        <p className="mobileW-txt-title-sec">Certifications & Labels</p>
        <p className="mobileW-txt-content">
          Unveiling our dedication: We hold certifications for responsible
          sourcing & production
        </p>
        <img
          src={certifComp}
          alt=""
          style={{ width: "100%", marginTop: "10px" }}
        />
      </div>
      <div>
        <p className="mobileW-txt-title-sec">Traceability & Transparency</p>
        <img src={traca} alt="" style={{ width: "100%" }} />
        <a
          className="wallet-card-item"
          href="https://www.bearingpoint.com/en/"
          target="_blank"
        >
          <div className="mobileW-btn-sec">
            <p style={{ padding: "0px", margin: "Opx", marginTop: "0.45vh" }}>
              Learn more on product steps
            </p>
          </div>
        </a>
      </div>
      <div>
        <p className="mobileW-txt-title-sec">Environnement impact</p>
        <img src={ecoscore} alt="" style={{ width: "100%" }} />
        <a
          className="wallet-card-item"
          href="https://www.bearingpoint.com/en/"
          target="_blank"
        >
          <div className="mobileW-btn-sec">
            <p style={{ padding: "0px", margin: "Opx", marginTop: "0.45vh" }}>
              More information on Footprint
            </p>
          </div>
        </a>
      </div>
      <div>
        <p className="mobileW-txt-title-sec">Care & Maintenance</p>
        <p className="mobileW-txt-content">
          <strong>Clean gently:</strong> Dust with a soft cloth, use only water
          for minor spills, and skip harsh chemicals.
        </p>
        <p className="mobileW-txt-content">
          <strong>Keep it supple:</strong> Use leather conditioner regularly to
          prevent cracks.
        </p>
        <p className="mobileW-txt-content">
          <strong>Store it wisely:</strong> Choose a cool, dry spot out of
          direct sunlight. Use dust bags for extra protection.
        </p>
      </div>
    </>
  );
};

export default DetailNFT;
