import React, { useContext, useEffect, useState } from "react";
import Header from "../../assets/header.png"
import BlankMiddle from "../../assets/blankMiddle.png"
import Footer from "../../assets/footer.png"
import HermesPassport from "../../assets/HermesPassport.png"
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { backend, GlobalStateContext } from "src/App";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface PassportHermesProps {
  productNumber: string;
  name: string;
  imageUrl: string;
}


export function PassportHermes() {

  const query = useQuery();

  const [nft, setNft] = useState<{name: string, description: string, image: string, model: string, ar: string, serial: string}>();
  const context = useContext(GlobalStateContext);
  if (!context) {
      throw new Error("MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider");
  }
  const { globalVar, setGlobalVar } = context;
  const collection = query.get('collection') || '';
  const id = query.get('id') || '';

  const fetchCollections = async () => {

    console.log(process.env.REACT_APP_API)
    axios.post(`${backend}/nft/collection/id`, {id: id, collection: collection}, {headers: {"x-api-key": process.env.REACT_APP_API, "vault": globalVar}}).then((resp) => {
    
      setNft(resp.data)

    }).catch((err) => {

      console.log(err)

    })
  }
  useEffect(() => {
    if (nft == undefined)
    {
      fetchCollections()
    }
  })

 

  return (
    <div style={{ position: "absolute", width: "100vw", overflow: "hidden", margin: 0, padding: 0, display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start"}}>
      
      <div style={{ position: "relative", width: "100vw", overflow: "hidden", margin: 0, padding: 0 }}>
        <img src={HermesPassport} alt="middle" style={{ width: "100%", height: "100%", display: "block" }} />
        <div style={{ position: "absolute", top: "13.5%", left: "32%" }}>
          <span style={{ margin: 0, fontSize: '1.5vw', color: '#696969' }}>{nft?.serial}</span>
        </div>
        <div style={{ position: "absolute", top: "33.45%", left: "27%" }}>
          <span style={{ margin: 0, fontSize: '1.05vw', color: '#696969' }}>{nft?.serial}</span>
        </div>
        <div style={{ position: "absolute", top: "29%", left: "16%" }}>
          <span style={{ margin: 0, fontSize: '2.5vw', color: '#696969' }}>{nft?.name}</span>
        </div>
        <div style={{ position: "absolute", top: "36%", left: "15%", width: "20vw", height: "18vw" }}>
          <img src={nft?.image} alt="product" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
        </div>
      </div>
      
    </div>
  );
}





export default PassportHermes;
