import "./Pass.scss";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState, useRef, useContext } from "react";
import { backend, GlobalStateContext } from "../../App";
import axios from "axios";
import useFullPageLoader from "../../hooks/UseLoader";
import { Checkbox, setRef } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";

interface CreatePoap {
  page: number;
  setPage: any;
  popSnack: any;
  setUpdatCollec: any;
  setAddrPoap: any;
  jwt: string;
}

export const CreatePoap = ({
  page,
  setPage,
  popSnack,
  setUpdatCollec,
  setAddrPoap,
  jwt,
}: CreatePoap) => {
  const [event, setEvent] = useState("");
  const [address, setaddress] = useState("");
  const [radius, setRadius] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [finishDate, setFinishDate] = useState<Date | null>(null);
  const [isLimit, setIsLimit] = useState(false);
  const [limit, setLimit] = useState(0);
  const [isPrice, setIsPrice] = useState(false);
  const [price, setPrice] = useState(0);
  const [isDate, setIsDate] = useState(false);
  const [open, setOpen] = useState(false);
  const [openErr, setOpenErr] = useState(false);
  const [nft, setNft] = useState<{ name: string; image: any; model: any }>({
    name: "",
    image: "",
    model: "",
  });
  const [update, setUpdate] = useState(false);
  const [hashs, setHashs] = useState({ img: "", mdl: "", metas: "" });
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [doc, setdoc] = useState<string[]>([]);
  const [imgCollec, setImgCollec] = useState(false);
  const [model, setmodel] = useState(false);
  const { t } = useTranslation("poap");

  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;
  let str_date: String = "15/05/1999";

  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    if (hiddenFileInput.current !== null)
      // @ts-ignore
      hiddenFileInput.current.click();
  };

  const date_to_string = (date: Date | null): string => {
    let date_String: string = "";

    if (date) {
      date_String = date?.toString();
    }
    return date_String;
  };

  function convertToTimestamp(date: Date | null): number | null {
    if (date != null) {
      const str_date = date_to_string(date);
      return Date.parse(str_date) / 1000;
    } else {
      return null;
    }
  }

  const addFile = async (e: any) => {
    console.log(convertToTimestamp(startDate));
    console.log(convertToTimestamp(finishDate));
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let tmp = nft;
      let data = new FormData();
      data.append("file", file, file.name);
      axios
        .post(`${backend}/files/upload/images/${1}`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data`,
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: globalVar,
          },
        })
        .then((resp) => {
          tmp = {
            name: event,
            image: URL.createObjectURL(e.target.files[0]),
            model: "",
          };
          setNft(tmp);
          setImgCollec(true);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setUpdate(!update);
        });
    }
  };

  const hiddenFileInputMod = useRef(null);
  const handleClickMod = () => {
    if (hiddenFileInputMod.current !== null)
      // @ts-ignore
      hiddenFileInputMod.current.click();
  };
  const add3D = (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let tmp = nft;
      let data = new FormData();
      data.append("file", file, file.name);
      axios
        .post(`${backend}/files/upload/models/${1}`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data`,
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: globalVar,
          },
        })
        .then((resp) => {
          tmp.model = URL.createObjectURL(e.target.files[0]);
          setNft(tmp);
          setmodel(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const hiddenFileInput2 = useRef(null);

  const handleClick2 = () => {
    if (hiddenFileInput2.current !== null)
      // @ts-ignore
      hiddenFileInput2.current.click();
  };

  const addCertif = (e: any) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let data = new FormData();
      data.append("file", file, file.name);
      axios
        .post(`${backend}/files/upload/certif/1`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data`,
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
          },
        })
        .then((resp) => {
          let tmpdoc = doc;
          tmpdoc.push(`${backend}/files/download/certif/${resp.data.folder}`);
          setdoc(tmpdoc);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setUpdate(!update);
        });
    }
    console.log(doc);
  };

  const deployContract = async () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    const tmp = hashs;
    axios
      .post(
        `${backend}/files/pin/all`,
        {
          name: event,
          description: description,
          nbElm: 1,
          doc: doc,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        console.log("pined url", resp.data);
        tmp.metas = resp.data.metas.IpfsHash;
        tmp.img = resp.data.images.IpfsHash;
        tmp.img = resp.data.images.IpfsHash;
        setHashs(tmp);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        str_date = date_to_string(startDate);
        console.log("hashs", hashs);
        axios
          .post(
            `${backend}/poap/deploy`,
            {
              eventName: event,
              date: str_date,
              description: description,
              address: address,
              radius: radius,
              startDate: date_to_string(startDate),
              endDate: date_to_string(finishDate),
              limit: limit,
              price: price,
              baseURI: `https://lavender-payable-zebra-843.mypinata.cloud/ipfs/${hashs.metas}/`,
            },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                authorization: jwt,
                vault: globalVar,
              },
            }
          )
          .then((resp) => {
            setAddrPoap(resp.data.events.OwnershipTransferred.address);
            if (typeof hideLoader === "function") {
              hideLoader();
            }
            setOpen(true);
            popSnack(t("collectionCreated"), "success");
            setUpdatCollec(false);
            setPage(page + 1);
          })
          .catch((err) => {
            console.log(err);
            setOpenErr(true);
            popSnack(t("errorMsg"), "error");
            if (typeof hideLoader === "function") {
              hideLoader();
            }
          });
      });
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrice(event.target.checked);
  };

  const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLimit(event.target.checked);
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDate(event.target.checked);
  };

  return (
    <>
      <div className="create-pass-mid">
        <div className="form">
          <div className="form-txt">{t("details")}</div>
          <TextField
            autoComplete="off"
            variant="outlined"
            label={t("event")}
            style={{ margin: "10px", marginInline: "30px", marginTop: "25px" }}
            className="txtfield"
            id="fullWidth"
            size="small"
            onChange={(e: any) => {
              setEvent(e.target.value);
            }}
          />
          <TextField
            autoComplete="off"
            variant="outlined"
            label={t("description")}
            style={{ margin: "10px", marginInline: "30px", marginTop: "15px" }}
            className="txtfield"
            id="fullWidth"
            size="small"
            onChange={(e: any) => {
              setDescription(e.target.value);
            }}
          />
          <div className="pass-mint-limit">
            <div>
              <div
                onClick={handleClick2}
                style={{
                  cursor: "pointer",
                  marginRight: "1vw",
                  width: "1.5vw",
                  height: "1.5vw",
                  textAlign: "end",
                  borderRadius: "50%",
                  border: "1px solid #EF19D1",
                  color: "#EF19D1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                +
              </div>
              <input
                ref={hiddenFileInput2}
                type="file"
                name="file"
                style={{ display: "none" }}
                onChange={(e) => addCertif(e)}
              />
            </div>
            <span className="pass-mint-limit-txt" style={{ marginTop: "2px" }}>
              {t("uploadDocument")}
            </span>
            {doc.map((elm: any, idx: number) => {
              return (
                <div style={{ marginLeft: "7px" }}>
                  <button className="nft-doc">
                    <DoneIcon />
                  </button>
                </div>
              );
            })}
          </div>
          <div className="pass-mint-limit">
            <div className="pass-mint-limit-check">
              <div>
                <Checkbox
                  sx={{
                    color: "white",
                  }}
                  checked={isPrice}
                  onChange={handleChangePrice}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span className="pass-mint-limit-txt">{t("price")}</span>
              </div>
              <div>
                {isPrice ? (
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label="Price"
                    style={{}}
                    className="txtfield"
                    id="fullWidth"
                    size="small"
                    onChange={(e: any) => {
                      setPrice(e.target.value);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="pass-mint-limit" style={{ marginTop: "10px" }}>
            <div className="pass-mint-limit-check">
              <div>
                <Checkbox
                  sx={{
                    color: "white",
                  }}
                  checked={isLimit}
                  onChange={handleChangeLimit}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span className="pass-mint-limit-txt">{t("addLimit")}</span>
              </div>
              <div>
                {isLimit ? (
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    label="Limit"
                    style={{}}
                    className="txtfield"
                    id="fullWidth"
                    size="small"
                    onChange={(e: any) => {
                      setLimit(e.target.value);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="pass-mint-date">
            <div className="pass-mint-date-check">
              <Checkbox
                sx={{
                  color: "white",
                }}
                checked={isDate}
                onChange={handleChangeDate}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span className="pass-mint-date-txt">{t("addDuration")}</span>
            </div>
            <div>
              {!isDate ? (
                <></>
              ) : (
                <div className="pass-mint-datepicker">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker
                      sx={{
                        bgcolor: "rgba(255, 255, 255, 0.9)",
                        borderRadius: "5px",
                        width: "14.5vw",
                      }}
                      value={startDate}
                      onChange={(newvalue) => {
                        setStartDate(newvalue);
                      }}
                    />
                  </LocalizationProvider>
                  <span className="pass-mint-date-txt">To</span>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker
                      sx={{
                        bgcolor: "rgba(255, 255, 255, 0.9)",
                        borderRadius: "5px",
                        marginInlineStart: "12px",
                        width: "14.5vw",
                      }}
                      value={finishDate}
                      onChange={(newvalue) => {
                        setFinishDate(newvalue);
                      }}
                    />
                  </LocalizationProvider>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="upload-image">
          <img src={nft.image} className="uploaded-image" />
          <div className="button-display">
            <div>
              <button className="nft-3d" onClick={handleClick}>
                {!imgCollec ? t("uploadImg") : <EditIcon />}
              </button>
              <input
                ref={hiddenFileInput}
                type="file"
                name="file"
                style={{ display: "none" }}
                onChange={(e) => addFile(e)}
              />
            </div>
            <div>
              <button className="nft-3d" onClick={handleClickMod}>
                {!model ? t("model") : <EditIcon />}
              </button>
              <input
                ref={hiddenFileInputMod}
                type="file"
                name="file"
                style={{ display: "none" }}
                onChange={(e) => add3D(e)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="create-pass-footer">
        <button className="footer-button" onClick={() => deployContract()}>
          {t("createCollection")}
        </button>
      </div>
      {loader}
    </>
  );
};

export default CreatePoap;
