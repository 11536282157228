import { createContext, useContext, useEffect, useState, memo } from "react";
import "./App.css";
import { RecoilRoot } from "recoil";
import Dashboard from "./components/Dashboard/Dashboard";
import HeaderSide from "./components/Header/HeaderSide";
import Collections from "./components/Collections/Collections";
import CreateCollection from "./components/CreateCollection/CreateCollection";
import Poap from "./components/Poap/Poap";
import Pass from "./components/Pass/Pass";
import DetailsPoa from "./components/Poap/PoapDetails";
import Choose from "./components/ChooseCollection/ChooseCollection";
import ChooseColBroadcastProps from "./components/Broadcast/ChooseColbroadcast";
import axios from "axios";
import Offers from "./components/Offer/Offer";
import Details from "./components/CollectionDetails/CollectionDetails";
import Claim from "./components/Claim/Claim";
import MobileConnection from "./components/MobileApp/MobileConnection/MobileConnection";
import MobileWallet from "./components/MobileApp/MobileWallet/MobileWallet";
import MobileQrcode from "./components/MobileApp/MobileQrcode/MobileQrcode";
import PassportHermes from "./components/PassportProduct/Hermes";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Snack from "./components/Snackbar/Snackbar";
import Customers from "./components/Customers/Customers";
import Login from "./components/Login/Login";
import PassportHermesMobile from "./components/PassportProduct/HermesMobile";
import { Buffer } from "buffer";
import { Client } from "@xmtp/xmtp-js";
import Chat from "./components/Chat/Chat";
import Roles from "./components/Admin/Role";
import Permissions from "./components/Admin/Permission";
import Wallet from "./components/Admin/Wallet";
import { Popup } from "./components/Popup/Popup";
import NetworkSelection from "./components/Admin/Network";
import WalletSelect from "./components/WalletSelect/WalletSelect";
import HomeWalletSelect from "./components/WalletSelect/HomeWalletSelect";
import { Languages } from "./components/Language/Language";
import * as ethers from "ethers";
import TransferRequest from "./components/TransferRequest/TransferRequest";
import {AddCsv} from "./components/CreateCollection/Blockchain";

export const backend = process.env.REACT_APP_BACKEND || "http://localhost:5001";
window.Buffer = window.Buffer ?? Buffer;

interface GStateProps {
  children: JSX.Element;
}

export const GlobalStateContext = createContext<GlobalStateType | undefined>(
  undefined
);

type GlobalStateType = {
  globalVar: string;
  setGlobalVar: React.Dispatch<React.SetStateAction<string>>;
};

const GlobalStateProvider = ({ children }: GStateProps) => {
  const [globalVar, setGlobalVar] = useState("");

  return (
    <GlobalStateContext.Provider value={{ globalVar, setGlobalVar }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

function App() {
  return (
    <RecoilRoot>
      <GlobalStateProvider>
        <Connected />
      </GlobalStateProvider>
    </RecoilRoot>
  );
}

const Connected = () => {
  const [jwtToken, setJwt] = useState(getToken());

  function getToken() {
    const tokenString = localStorage.getItem("token");
    const userToken = tokenString ? tokenString : "";
    return userToken;
  }

  useEffect(() => {
    axios.get(`${backend}/auth/login`).then((resp) => {
      setJwt(resp.data.token);
      localStorage.setItem("token", resp.data.token);
      localStorage.setItem("name", resp.data.name);
    })
  });

  return (
    <div>
      {
        jwtToken === "" ? <>Please Login</> :
        <GetWallets jwtToken={jwtToken} />
      }
    </div>
  );
};

interface GetWalletProps {
  jwtToken: string;
}

const GetWallets = memo(({ jwtToken }: GetWalletProps) => {
  const [allWallets, setWallets] = useState<
    { name: string; address: string; id: number; vault: string }[]
  >([]);

  const isMobile = window.innerWidth <= 768;

  const getWallets = () => {
    axios
      .get(`${backend}/admin/wallets`, {
        headers: {
          authorization: jwtToken,
          "x-api-key": process.env.REACT_APP_API,
        },
      })
      .then((resp) => {
        setWallets(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!isMobile) getWallets();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwtToken]);

  return (
    <div>
      {allWallets.length === 0 && !isMobile ? (
        "Aucun compte administrateur n'a été trouvé."
      ) : (
        <Home allWallets={allWallets} jwtToken={jwtToken} />
      )}
    </div>
  );
});

interface HomeProps {
  jwtToken: string;
  allWallets: any[];
}

const Home = ({ jwtToken, allWallets }: HomeProps) => {
  const [promos, setPromos] = useState<any[]>([]);
  const [nftCollections, setCollections] = useState<any[]>([]);
  const [poaCollection, setCollectionPoa] = useState<any[]>([]);
  const [updated, setUpdatCollec] = useState(false);
  const [openSnack, setOpen] = useState(false);
  const [snack, setSnack] = useState<{
    status: "success" | "warning" | "info" | "error";
    message: string;
  }>({ status: "info", message: "" });
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [login, setLogin] = useState(true);
  const [acc, setAcc] = useState("");
  const [vp, setVP] = useState(0);
  const [poaps, setPoaps] = useState(0);
  const [nbOwner, setOwner] = useState(0);
  const [signer, setSigner] = useState<any>();
  const [isClient, setClient] = useState(false);
  const [clientXmtp, setXmtp] = useState<Client>();
  const [allClients, setAllClients] = useState<any[]>([]);
  const [permissions, setPermissions] = useState([]);
  const [currentNetwork, setCurrentNetwork] = useState("");
  const [currentWallet, setCurrentWallet] = useState<{
    name: string;
    address: string;
    id: number;
    vault: string;
  }>(getWallet());
  const [fetchConfig, setFetchConfig] = useState(false);
  const [isSigner, setIsSigner] = useState(false);

  const [openPopup, setPopup] = useState(false);
  const [popupInfos, setPopupInfos] = useState({ title: "", content: "" });
  const context = useContext(GlobalStateContext);
  // console.log(currentNetwork)
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;

  const launchPopup = (title: string, content: string) => {
    setPopupInfos({ title: title, content: content });
    setPopup(true);
  };

  const updateWallet = (wallet: any) => {
    setCurrentWallet(wallet);
    setUpdatCollec(false);
  };

  function getWallet() {
    let walletRes = { name: "", address: "", id: -1, vault: "" };
    const tokenString = localStorage.getItem("wallet");
    if (tokenString) {
      allWallets.map((elm) => {
        if (elm.vault == tokenString) walletRes = elm;
      });
    }
    return walletRes;
  }

  const getConfig = async () => {
    axios
      .get(`${backend}/admin/config`, { headers: { authorization: jwtToken } })
      .then((resp) => {
        setCurrentNetwork(resp.data[0].network);
      });
  };

  const setConfig = async (network: string) => {
    axios
      .post(
        `${backend}/admin/config/update`,
        { network: network },
        { headers: { authorization: jwtToken, vault: globalVar } }
      )
      .then((resp) => {
        setCurrentNetwork(resp.data.network);
        launchPopup(
          "You have changed your blockchain",
          "Your blockchain is now " + network
        );
        setFetchConfig(false);
      });
  };

  const createClient = async () => {
    if (signer !== undefined) {
      const xmtp = await Client.create(signer, { env: "production" });
      setXmtp(xmtp);
    }
  };

  const checkRole = (role: string) => {
    let flag = false;
    permissions.map((elm) => {
      if (elm == role || elm == "root") {
        flag = true;
      }
    });
    return flag;
  };

  const getRole = async () => {
    axios
      .get(`${backend}/admin/role`, {
        headers: { authorization: jwtToken, vault: globalVar },
      })
      .then((resp) => {
        console.log("RETURN PERM", resp.data)
        setPermissions(resp.data.split(";"));
      });
  };

  const fetchCollections = async () => {
    axios
      .post(
        `${backend}/alldatas/collec`,
        { test: "test" },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwtToken,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        setOwner(resp.data.owners);

        if (resp.data.products == undefined) {
          setCollections([]);
        } else {
          setCollections(resp.data.products);
          setVP(resp.data.supplyVP);
        }

        if (resp.data.poap == undefined) {
          setCollectionPoa([]);
        } else {
          setCollectionPoa(resp.data.poap);
          setPoaps(resp.data.supplyPoap);
        }

        if (resp.data.promos == undefined) {
          setPromos([]);
        } else {
          setPromos(resp.data.promos);
        }
      })
  };

  const fetchClients = () => {
    axios
      .get(`${backend}/client/all`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API,
          authorization: jwtToken,
          vault: globalVar,
        },
      })
      .then((resp) => {
        setAllClients(resp.data);
      });
  };

  const createSigner = async () => {
    if (signer == undefined) {
      axios
        .get(`${backend}/auth/signerXmtp`, {
          headers: { Authorization: jwtToken },
        })
        .then((resp) => {
          const wallet = new ethers.Wallet(resp.data.signer);
          setSigner(wallet);
          setAcc(resp.data.account);
        });
    }
  };

  useEffect(() => {
    const walletStorage = localStorage.getItem("wallet");

    if (globalVar == "" && walletStorage) {
      setGlobalVar(walletStorage);
    }
    if (!fetchConfig) {
      getConfig();
    }
    if (!isSigner) {
      setIsSigner(true);
      createSigner();
    }
    if (globalVar !== "") {
      console.log("get config");
      if (fetchConfig && allWallets.length == 0 && !checkRole("adm01")) {
        launchPopup(
          "You don't have any wallet",
          "Contact your admin to create a wallet and start using it"
        );
      }

      if (permissions.length === 0 && jwtToken !== "") {
        console.log("get role");
        getRole();
      }

      if (acc == process.env.REACT_APP_WALLETADDR && jwtToken !== "") {
        setLogin(true);
      }
      if (!isClient && signer !== undefined && jwtToken !== "") {
        setClient(true);
        createClient();
      }

      if (!updated && login && jwtToken !== "") {
        setUpdatCollec(true);
        fetchCollections();
        fetchClients();
      }
    }
  });

  const isMobile = width <= 768;

  const popSnackbar = (
    message: string,
    status: "success" | "warning" | "info" | "error"
  ) => {
    setSnack({ message: message, status: status });
    setOpen(true);
  };

  return (
    <div className="App">
      {isMobile ? (
        <></>
      ) : (
        <>
          <HeaderSide
            wallets={allWallets}
            setCurrWallet={setCurrentWallet}
            currWallet={currentWallet}
            checkRole={checkRole}
          />
          <Languages />
        </>
      )}
      {!login && !isMobile ? (
        <Login
          setSigner={setSigner}
          acc={acc}
          setAcc={setAcc}
          setLogin={setLogin}
          login={login}
        />
      ) : (
        <>
          <Snack
            open={openSnack}
            setOpen={setOpen}
            message={snack.message}
            status={snack.status}
          />
          <Popup
            open={openPopup}
            setOpen={setPopup}
            title={popupInfos.title}
            content={popupInfos.content}
          />
          <Routes>
            <Route path="/" element={<Navigate to="/walletSelect" />} />
            <Route
              path="/home"
              element={
                <Dashboard
                  nbOwners={nbOwner}
                  nfts={nftCollections}
                  poap={poaCollection}
                  promos={promos}
                  supplyVP={vp}
                  supplyPoaps={poaps}
                />
              }
            />
            <Route
              path="/collections"
              element={
                checkRole("col00") ? (
                  <Collections
                    currWallet={currentWallet}
                    nfts={nftCollections}
                    poas={poaCollection}
                    jwt={jwtToken}
                  />
                ) : (
                  <>YOU DONT HAVE ACCESS</>
                )
              }
            />
            <Route
              path="/newcollection"
              element={
                checkRole("col01") ? (
                  <CreateCollection
                    currWallet={currentWallet}
                    jwt={jwtToken}
                    setCollections={setCollections}
                    popSnack={popSnackbar}
                    setUpdatCollec={setUpdatCollec}
                  />
                ) : (
                  <></>
                )
              }
            />
            <Route
              path="/poap"
              element={
                checkRole("poa00") ? (
                  <Poap
                    currWallet={currentWallet}
                    jwt={jwtToken}
                    permissions={permissions}
                    popSnack={popSnackbar}
                    setUpdatCollec={setUpdatCollec}
                  />
                ) : (
                  <></>
                )
              }
            />
            <Route
              path="/pass"
              element={
                checkRole("poa00") ? (
                  <Pass
                    currWallet={currentWallet}
                    jwt={jwtToken}
                    permissions={permissions}
                    popSnack={popSnackbar}
                    setUpdatCollec={setUpdatCollec}
                  />
                ) : (
                  <></>
                )
              }
            />
            <Route
              path="/showroom"
              element={
                checkRole("sho01") ? (
                  <Choose
                    currWallet={currentWallet}
                    jwt={jwtToken}
                    popSnack={popSnackbar}
                    nfts={nftCollections}
                    redirect="showroom"
                  />
                ) : (
                  <></>
                )
              }
            />
            <Route
              path="/nft"
              element={
                checkRole("col01") ? (
                  <Choose
                    currWallet={currentWallet}
                    jwt={jwtToken}
                    popSnack={popSnackbar}
                    nfts={nftCollections}
                    redirect="send"
                  />
                ) : (
                  <></>
                )
              }
            />
            <Route
              path="/community"
              element={
                checkRole("com01") ? (
                  <Offers
                    currWallet={currentWallet}
                    jwt={jwtToken}
                    popSnack={popSnackbar}
                    nfts={nftCollections}
                    poas={poaCollection}
                    offers={promos}
                    setUpdatCollec={setUpdatCollec}
                  />
                ) : (
                  <></>
                )
              }
            />
            <Route
              path="/collection/:address"
              element={
                checkRole("col00") ? (
                  <Details
                    currentWallet={currentWallet}
                    jwt={jwtToken}
                    popSnack={popSnackbar}
                    allClients={allClients}
                    clientXmtp={clientXmtp}
                  />
                ) : (
                  <></>
                )
              }
            />
            <Route
              path="/collection/poap/:address"
              element={
                checkRole("poa01") ? (
                  <DetailsPoa
                    jwt={jwtToken}
                    poas={poaCollection}
                    promos={promos}
                    popSnack={popSnackbar}
                  />
                ) : (
                  <></>
                )
              }
            />
            <Route
              path="/customers"
              element={
                checkRole("cus00") ? <Customers jwt={jwtToken} /> : <></>
              }
            />
            <Route
              path="/claim/:address/:id"
              element={
                !isMobile ? (
                  <>Not available on desktop</>
                ) : (
                  <Claim vaultId={currentWallet.vault} popSnack={popSnackbar} />
                )
              }
            />
            <Route
              path="/passport/product"
              element={isMobile ? <PassportHermesMobile /> : <PassportHermes />}
            />
            <Route path="/passeport/:collection/:id" element={<></>} />
            <Route
              path="/collectibles"
              element={
                checkRole("col01") ? (
                  <CreateCollection
                    currWallet={currentWallet}
                    jwt={jwtToken}
                    setCollections={setCollections}
                    popSnack={popSnackbar}
                    setUpdatCollec={setUpdatCollec}
                  />
                ) : (
                  <></>
                )
              }
            />
            <Route
              path="/broadcast"
              element={
                <ChooseColBroadcastProps
                  currWallet={currentWallet}
                  jwt={jwtToken}
                  popSnack={popSnackbar}
                  nfts={nftCollections}
                  poap={poaCollection}
                  clientXmtp={clientXmtp}
                />
              }
            />
            <Route
              path="/chat"
              element={
                <Chat
                  currWallet={currentWallet}
                  jwt={jwtToken}
                  allClients={allClients}
                  signer={{ signer }}
                  clientXmtp={clientXmtp}
                />
              }
            />
            <Route
              path="/administration/role"
              element={
                checkRole("adm01") ? <Roles jwt={jwtToken} /> : <>NO ACCESS</>
              }
            />
            <Route
              path="/administration/permission"
              element={
                checkRole("adm01") ? (
                  <Permissions jwt={jwtToken} />
                ) : (
                  <>NO ACCESS</>
                )
              }
            />
            <Route
              path="/administration/wallet"
              element={
                checkRole("adm01") ? (
                  <Wallet
                    jwt={jwtToken}
                    allWallets={allWallets}
                    popSnack={popSnackbar}
                  />
                ) : (
                  <>NO ACCESS</>
                )
              }
            />
            <Route
              path="/administration/network"
              element={
                checkRole("adm01") ? (
                  <NetworkSelection
                    network={currentNetwork}
                    setConfig={setConfig}
                  />
                ) : (
                  <>NO ACCESS</>
                )
              }
            />
            {/* Mobile App */}
            <Route
              path="/wallet"
              element={<MobileConnection popSnack={popSnackbar} />}
            />
            <Route path="/wallet/qrcode" element={<MobileQrcode />} />
            <Route
              path="/wallet/addr"
              element={
                <MobileWallet
                  popSnack={popSnackbar}
                  allClients={allClients}
                  jwt={jwtToken}
                />
              }
            />
            <Route
              path="/walletSelect"
              element={
                <WalletSelect
                  setCurrentWallet={updateWallet}
                  allWallets={allWallets}
                  popSnack={popSnackbar}
                  jwt={jwtToken}
                />
              }
            />
            <Route
              path="/transferRequest"
              element={
                <TransferRequest jwt={jwtToken} currentWallet={currentWallet} popSnack={popSnackbar}/>
              }
            />
              <Route
              path="/mass"
              element={
                <AddCsv />
              }
            />
            </Routes>
          
        </>
      )}
    </div>
  );
};

export default App;
