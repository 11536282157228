import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import "./Chat.css";

function truncate(str: string, n: number) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

interface ChatProps {
  signer: any;
  clientXmtp: any;
  allClients: any[];
  jwt: string;
  currWallet: any;
}

export const Chat = ({
  signer,
  clientXmtp,
  allClients,
  jwt,
  currWallet,
}: ChatProps) => {
  const [msgs, setMsgs] = useState<
    {
      name: string;
      message: string;
      conv: any[];
      addr: string;
      conversation: any;
      isNotif: boolean;
    }[]
  >([]);
  const [current, setCurrent] = useState<any>();
  const [msgSend, setMsgSend] = useState("");

  const [customerAddr, setCustomerAddr] = useState("")

  const fetchTime = 1000

  const fetchConvos = async () => {
    console.log("in convo");
    if (clientXmtp !== undefined) {
      const allConversations = await clientXmtp.conversations.list();

      let convos = [];
      for (const conversation of allConversations) {
        const tmpMsgs = [];
        let push = false;
        for await (const page of conversation.messagesPaginated({
          pageSize: 100,
        })) {
          for (const msg of page) {
            if (msg.senderAddress != clientXmtp.address) {
              push = true;
            }
            tmpMsgs.push({ content: msg.content, sender: msg.senderAddress });
          }
        }
        if (push) {
          let tmpObj = {
            name: conversation.peerAddress,
            message: tmpMsgs.length > 0 ? tmpMsgs[0].content : "",
            conv: tmpMsgs,
            addr: conversation.peerAddress,
            conversation: conversation,
            isNotif: false,
          };
          convos.push(tmpObj);
        }
      }

      setMsgs(convos.reverse());
      console.log("Get convs", convos.reverse());
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchConvos();
      console.log("customer addr", customerAddr)
    }, fetchTime);


    return () => clearInterval(interval);
  }, [customerAddr]);

  const sendMessage = async () => {
    let send = msgSend
    setMsgSend("")
    console.log("msg sent");
    let conversation;
    msgs.map((elm) => {
      if (elm.addr === current.addr) {
        conversation = elm.conversation;
      }
    });
    conversation = await clientXmtp.conversations.newConversation(current.addr);

    await conversation.send(send);
  };

  const matchAddr = (addr: string) => {
    let resAddr = "";
    allClients?.map((elm: any) => {
      if (elm.xmtp === "") {
        if (elm.wallets[0] === addr) {
          resAddr = elm.name;
        }
      } else {
        if (elm.xmtp === addr) {
          resAddr = elm.name;
        }
      }
    });
    if (resAddr === "") return addr;
    return resAddr;
  };

  return (
    <>
      {currWallet.name !== "" ? (
        <div className="chat-background">
          <div className="chat-list">
            {msgs.length === 0 ? (
              <div
                style={{
                  fontSize: "1em",
                  color: "white",
                  marginTop: "3vh",
                  fontWeight: "bold",
                }}
              >
                No conversation Found
              </div>
            ) : (
              msgs.map((elm, idx) => {
                return (
                  <div
                    onClick={() => {
                      console.log("elem", elm.addr)
                      setCustomerAddr(elm.addr)
                      setCurrent(msgs[idx]);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      flexDirection: "column",
                      minWidth: "85%",
                      maxWidth: "85%",
                      marginTop: "5%",
                      borderBottom: "solid 1px white",
                      height: "8vh",
                      cursor: "pointer",
                      paddingBottom: "2%",
                    }}
                  >
                    <span
                      style={{
                        color: msgs[idx].isNotif
                          ? "#EF19D1"
                          : current?.name == msgs[idx].name
                            ? "#078BF9"
                            : "white",
                        fontWeight: "bold",
                        fontSize: "1em",
                      }}
                    >
                      {matchAddr(elm.name)[0] == "0"
                        ? truncate(elm.name, 12)
                        : matchAddr(elm.name)}
                    </span>
                    <span
                      style={{
                        fontSize: "0.9em",
                        color: "rgba(255, 255, 255, 0.59)",
                        marginTop: "1vh",
                      }}
                    >
                      {matchAddr(elm.message)[0] == "0"
                        ? truncate(elm.message, 24)
                        : matchAddr(elm.message)}
                    </span>
                  </div>
                );
              })
            )}
          </div>
          <div className="chat-messages">
            {current == undefined ? (
              <div
                style={{
                  fontSize: "1em",
                  color: "white",
                  marginTop: "3vh",
                  fontWeight: "bold",
                }}
              >
                {" "}
                No chat Selected{" "}
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    minWidth: "90%",
                    maxWidth: "90%",
                    alignItems: "center",
                    flexDirection: "row",
                    marginTop: "4%",
                    height: "5vh",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "1.5em",
                    }}
                  >
                    {matchAddr(current.name)[0] == "0"
                      ? truncate(current.name, 20)
                      : matchAddr(current.name)}
                  </span>
                </div>
                <div
                  className="chat-content"
                  style={{
                    minWidth: "90%",
                    maxWidth: "90%",
                    height: "47vh",
                    marginTop: "5vh",
                    overflow: "scroll",
                    display: "flex",
                    flexDirection: "column-reverse",
                    marginBottom: "3%",
                  }}
                >
                  {msgs.filter(elm => elm.name === customerAddr)[0].conv.map((elm: any, idx: number) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent:
                            elm.sender == clientXmtp.address ? "end" : "start",
                          alignItems: "center",
                          width: "90%",
                        }}
                      >
                        <span
                          style={{
                            color: "white",
                            backgroundColor:
                              elm.sender == clientXmtp.address
                                ? "#8007F9"
                                : "#078BF9",
                            paddingTop: "0.5vw",
                            paddingBottom: "0.5vw",
                            paddingRight: "1vw",
                            paddingLeft: "1vw",
                            borderRadius: "100px",
                            marginTop: "1vh",
                            textAlign:
                              elm.sender == clientXmtp.address
                                ? "right"
                                : "left",
                          }}
                        >
                          {elm.content}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    minWidth: "90%",
                    maxWidth: "90%",
                    height: "5vh",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <input
                    value={msgSend}
                    onChange={(e) => {
                      setMsgSend(e.target.value);
                    }}
                    className="chat-input"
                    style={{
                      backgroundColor: "white",
                      width: "85%",
                      borderRadius: "100px",
                      border: "none",
                      paddingLeft: "3%",
                    }}
                  />
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      sendMessage();
                    }}
                  >
                    <SendIcon style={{ color: "#EF19D1" }} />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Chat;
